import React, { useState, useRef } from "react";
import { Button, Col, Container, Form, Image, Row, Tab, Tabs } from "react-bootstrap";
import { Await, useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import {
  addOfferData,
  adminEdit,
  offers_discount,
  offers_discountEdit,
  singleOffer,
} from "../../../services/authServices";
import './offersForm.css'
import CustomButton from "../../../components/button/button";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineCloudDownload } from "react-icons/md";

const OfferForm = () => {
  const { id } = useParams();
  const initialvalue = {
    description: "",
    image: "",
    title: "",
    promoType: "FLAT",
    isDefault: "",
    know_more: {
      klink: "",
      kname: "",
    },
    promocode: {
      amountoff: 0,
      code: "",
      created: "",
      expiry_date: "",
      max_redemptions: 0,
      total_redeemed: 0,
      percentageoff: "",
      uptoamountoff: "",
    },
    t_c: {
      tlink: "",
      tname: "",
    },
    terms_condition: {
      terms_title: "",
      terms_description: "",
      terms_link: "",
    },
    more_about_offer: {
      terms_title: "",
      more_description: "",
      more_link: "",
    },
    get_the_offer: {
      get_title: "",
      get_description: "",
      get_link: "",
    },
  };

  const more_about_offer = {
    more_title: "",
    more_description: "",
    more_link: "",
  };

  const promocode = {
    amountoff: 0,
    code: "",
    created: "",
    expiry_date: "",
    max_redemptions: 0,
    total_redeemed: 0,
    percentageoff: "",
    uptoamountoff: "",
  };

  const t_c = {
    tname: "",
    tlink: "",
  };

  const know_more = {
    kname: "",
    klink: "",
  };

  const get_the_offer = {
    get_title: "",
    get_description: "",
    get_link: "",
  };

  const terms_condition = {
    terms_title: "",
    terms_description: "",
    terms_link: "",
  };

  const {acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const [getTheOffer, setGetTheOffer] = useState(get_the_offer);
  const [moreAboutOffer, setMoreAboutOffer] = useState(more_about_offer);
  const [termsCondition, setTermsCondition] = useState(terms_condition);
  const [promo, setPromo] = useState(promocode);
  const [tc, setTc] = useState(t_c);
  const [more, setMore] = useState(know_more);
  const [offerDiscount, setofferDiscount] = useState(initialvalue);
  const getDateFormate = (data) => {
    const d = new Date(data);
    let Data = d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear();
    return Data;
  };
  const navigate = useNavigate();
  const [offerDataApi, setPostDestApi] = useState([]);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  // const [offerDiscount.promoType, setofferDiscount.promoType] = useState('');
  const handleDataDetails = (e) => {
    const { value } = e.target;
    setofferDiscount.promoType(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id !== undefined && id !== null) {
      //edit
      const formData = new FormData();
      formData.append("title", offerDiscount.title);
      formData.append("description", offerDiscount.description);
      formData.append("image", offerDiscount.image);
      formData.append("code", promo.code);
      offerDiscount.promoType === "FLAT" &&
        formData.append("amountoff", promo.amountoff);
      offerDiscount.promoType !== "FLAT" &&
        formData.append("percentOff", promo.percentageoff);
      offerDiscount.promoType !== "FLAT" &&
        formData.append("uptoAmountOff", promo.uptoamountoff);
      formData.append("expiry_date", promo.expiry_date);
      formData.append("max_redemptions", promo.max_redemptions);
      // formData.append("tname", tc.tname);
      // formData.append("tlink", tc.tlink);
      // formData.append("kname", more.kname);
      // formData.append("klink", more.klink);
      formData.append("more_description", moreAboutOffer.more_description);
      formData.append("more_title", moreAboutOffer.more_title);
      // formData.append("more_link", moreAboutOffer.more_link);
      formData.append("get_title", getTheOffer.get_title);
      formData.append("get_description", getTheOffer.get_description);
      // formData.append("get_link", getTheOffer.get_link);
      formData.append("terms_title", termsCondition.terms_title);
      formData.append("terms_description", termsCondition.terms_description);
      // formData.append("terms_link", termsCondition.terms_link);
      // const params = {
      //   ...offerDiscount,
      //   image:offerDiscount?.image,
      //   promocode: promo,
      //   t_c: tc,
      //   know_more: more,
      // };
      try {
        const res = await offers_discountEdit(id, formData);
        setofferDiscount(res.data.Data);
        navigate(`/offersDiscount`);
      } catch (e) {
        console.error(e);
      }
    } else {
      //for add
      getOfferData(offerDiscount);
    }
  };
  const offerDataAPI = async () => {
    if (id !== undefined) {
      const params = {
        id: id,
      };
      try {
        const res = await singleOffer(params);
        setofferDiscount(res?.data?.Data);
        setPromo(res?.data?.Data?.promocode);
        setTc(res?.data?.Data?.t_c);
        setMore(res?.data?.Data?.know_more);
        setTermsCondition(res?.data?.Data?.terms_condition);
        setMoreAboutOffer(res?.data?.Data?.more_about_offer);
        setGetTheOffer(res?.data?.Data?.get_the_offer);
      } catch (e) {
        console.error(e);
      }
    }
  };
  const goBack = () => {
    navigate(`/offersDiscount`);
  };

  const getOfferData = async () => {
    const formData = new FormData();
    formData.append("title", offerDiscount.title);
    formData.append("description", offerDiscount.description);
    formData.append("image", offerDiscount.image);
    formData.append("code", promo.code);
    offerDiscount.promoType === "FLAT" &&
      formData.append("amountoff", promo.amountoff);
    offerDiscount.promoType !== "FLAT" &&
      formData.append("percentOff", promo.percentageoff);
    offerDiscount.promoType !== "FLAT" &&
      formData.append("uptoAmountOff", promo.uptoamountoff);
    formData.append("expiry_date", promo.expiry_date);
    formData.append("max_redemptions", promo.max_redemptions);
    // formData.append("tname", tc.tname);
    // formData.append("tlink", tc.tlink);
    // formData.append("kname", more.kname);
    // formData.append("klink", more.klink);
    formData.append("more_description", moreAboutOffer.more_description);
    formData.append("more_title", moreAboutOffer.more_title);
    formData.append("more_link", moreAboutOffer.more_link);
    formData.append("promoType", offerDiscount.promoType);
    formData.append("get_title", getTheOffer.get_title);
    formData.append("get_description", getTheOffer.get_description);
    formData.append("get_link", getTheOffer.get_link);
    formData.append("terms_title", termsCondition.terms_title);
    formData.append("terms_description", termsCondition.terms_description);
    formData.append("terms_link", termsCondition.terms_link);
    try {
      const res = await addOfferData(formData);
      setPostDestApi(res);
      // navigate(`/offersDiscount`);
    } catch (e) {
      console.error(e);
    }
  };

  const [imageupdate, setImageupdate] = useState();
  // const onChangePicture = (e) => {
  //   console.log('asdasdasdasd',e.target.files[0]);
  //   setofferDiscount({
  //     ...offerDiscount,
  //     image: e.target.files[0],
  //   });
  //   setImageupdate(URL.createObjectURL(e.target.files[0]));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPromo({ ...promo, [name]: value });
    setofferDiscount({ ...offerDiscount, [name]: value });
    setTc({ ...tc, [name]: value });
    setMore({ ...more, [name]: value });
    setMoreAboutOffer({ ...moreAboutOffer, [name]: value });
    setGetTheOffer({ ...getTheOffer, [name]: value });
    setTermsCondition({ ...termsCondition, [name]: value });
  };
  const handleEditor = (operation,e) => {
    if(operation === 'description'){
      setofferDiscount({ ...offerDiscount, description: e });
    }else if(operation === 'get_description'){
    setGetTheOffer({ ...getTheOffer, get_description: e });
    }else if(operation === 'more_description'){
    setMoreAboutOffer({...moreAboutOffer,more_description:e})
    }else if(operation === 'terms_description'){
      setTermsCondition({...termsCondition,terms_description:e})
    }
  };

  useEffect(() => {
    offerDataAPI();
  }, [id]);

  
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(()=>{
    if(acceptedFiles !== undefined && acceptedFiles.length > 0){
      setofferDiscount({
        ...offerDiscount,
        image: acceptedFiles[0],
      });
      setImageupdate(URL.createObjectURL(acceptedFiles[0]));

      console.log('main hu here');
    }
  },[acceptedFiles])

  console.log('image from drag and drop',acceptedFiles.length,imageupdate);

  return (
    <Container className="my-5">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Promo Type</Form.Label>
              <Form.Select
                name="promoType"
                onChange={(e) => handleChange(e)}
                value={offerDiscount?.promoType}
                aria-label="Default select example"
              >
                <option value="FLAT">FLAT</option>
                <option value="PERCENTAGE">Percentage</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your code"
                autoComplete="off"
                name="code"
                // defaultValue={offerDiscount?.promocode?.code}
                value={promo?.code}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </Col>

          {offerDiscount.promoType === "FLAT" && (
            <>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Amount Off</Form.Label>
                  <Form.Control
                    disabled={offerDiscount.promoType === "PERCENTAGE"}
                    type="text"
                    placeholder="amount off"
                    autoComplete="off"
                    name="amountoff"
                    // defaultValue={promo?.amountoff}
                    value={promo?.amountoff}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
              </Col>
            </>
          )}
          {offerDiscount.promoType === "PERCENTAGE" && (
            <>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Percentage Off</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={offerDiscount.promoType === "FLAT"}
                    placeholder="percentage off"
                    autoComplete="off"
                    name="percentageoff"
                    // defaultValue={promo?.amountoff}
                    value={promo?.percentageoff}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Up To Amount</Form.Label>
                  <Form.Control
                    disabled={offerDiscount.promoType === "FLAT"}
                    type="text"
                    placeholder="upto amount"
                    autoComplete="off"
                    name="uptoamountoff"
                    // defaultValue={promo?.amountoff}
                    value={promo?.uptoamountoff}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col lg={6}>
          <Form.Group className="mb-3">
          <Form.Label className="fw-bold">Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Your title"
            autoComplete="off"
            name="title"
            // defaultValue={offerDiscount?.title}
            value={offerDiscount?.title}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
          </Col>
          <Col>
          <Form.Group className="mb-3">
          <Form.Label className="fw-bold">Expiry Date</Form.Label>
          <Form.Control
            type="date"
            placeholder="Expire Date"
            autoComplete="off"
            name="expiry_date"
            // defaultValue={promo?.expiry_date}
            value={promo?.expiry_date}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>

        
          </Col>
          <Col>
          <Form.Group className="mb-3">
          <Form.Label className="fw-bold">Max Redemption</Form.Label>
          <Form.Control
          maxLength={'5'}
          // max={'2'}
            type="text"
            placeholder="Max Redemption"
            autoComplete="off"
            name="max_redemptions"
            // defaultValue={promo?.max_redemptions}
            value={promo?.max_redemptions}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group> 
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label className="fw-bold">Description</Form.Label>
          <JoditEditor
            ref={editor}
            // defaultValue={offerDiscount?.description}
            value={offerDiscount?.description}
            onChange={(e) => handleEditor('description',e)}
          />
        </Form.Group>

    
       <div className="mainTab">
        <Tabs
      defaultActiveKey="get offer"
      id="uncontrolled-tab-example"
      className="mb-3 SectionTabMain"
      
    >
    <Tab eventKey="get offer" title="get offer" className="p-3 SectionTab1">
    <Form.Group className="mb-3">
          <Form.Label className="fw-bold">get the title</Form.Label>
          <Form.Control
            type="text"
            placeholder="get_title"
            autoComplete="off"
            name="get_title"
            // defaultValue={offerDiscount?.know_more?.klink}
            value={getTheOffer?.get_title}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="fw-bold">get the description</Form.Label>
          {/* <Form.Control
            type="text"
            placeholder="get_description"
            autoComplete="off"
            name="get_description"
            // defaultValue={offerDiscount?.know_more?.klink}
            value={getTheOffer?.get_description}
            onChange={(e) => handleChange(e)}
          /> */}
           <Form.Label className="fw-bold">Description</Form.Label>
          <JoditEditor
            ref={editor}
            // defaultValue={offerDiscount?.description}
            // value={offerDiscount?.description}
            value={getTheOffer?.get_description}
            onChange={(e) => handleEditor('get_description',e)}
          />
        </Form.Group>
       
      </Tab>
      <Tab eventKey="more about title" title="more about title"  className="p-3 SectionTab">
      <Form.Group className="mb-3">
          <Form.Label className="fw-bold">More more_title</Form.Label>
          <Form.Control
            type="text"
            placeholder="more_title"
            autoComplete="off"
            name="more_title"
            // defaultValue={offerDiscount?.know_more?.klink}
            value={moreAboutOffer?.more_title}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="fw-bold">More more_description</Form.Label>
          {/* <Form.Control
            type="text"
            placeholder="more_description"
            autoComplete="off"
            name="more_description"
            // defaultValue={offerDiscount?.know_more?.klink}
            value={moreAboutOffer?.more_description}
            onChange={(e) => handleChange(e)}
          /> */}
           <JoditEditor
            ref={editor}
            // defaultValue={offerDiscount?.description}
            // value={offerDiscount?.description}
            value={moreAboutOffer?.more_description}
            onChange={(e) => handleEditor('more_description',e)}
          />
        </Form.Group>
      </Tab>
      <Tab eventKey="Term and Contion" title="Term and Contion"  className="p-3 SectionTab">
      <Form.Group className="mb-3">
          <Form.Label className="fw-bold">get the terms_title</Form.Label>
          <Form.Control
            type="text"
            placeholder="terms_title"
            autoComplete="off"
            name="terms_title"
            // defaultValue={offerDiscount?.know_more?.klink}
            value={termsCondition?.terms_title}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="fw-bold">get the terms_description</Form.Label>
          {/* <Form.Control
            type="text"
            placeholder="terms_description"
            autoComplete="off"
            name="terms_description"
            // defaultValue={offerDiscount?.know_more?.klink}
            value={termsCondition?.terms_description}
            onChange={(e) => handleChange(e)}
          /> */}
           <JoditEditor
            ref={editor}
            // defaultValue={offerDiscount?.description}
            // value={offerDiscount?.description}
            value={termsCondition?.terms_description}
            onChange={(e) => handleEditor('terms_description',e)}
          />
        </Form.Group>
      </Tab>
      
    </Tabs>
    </div>
    

       
<div className="imageUploadSection">
        <Form.Group controlId="formFile" className="mb-">
          {/* <Form.Label className="fw-bold">Destination Image</Form.Label> */}
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <Image
                width={80}
                height={80}
                src={
                  imageupdate == undefined ? offerDiscount?.image : imageupdate
                }
              />
            </Col>
            <Col md={10}>
              {/* <Form.Control
                type="file"
                name="image"
                onChange={(e) => onChangePicture(e)}
              /> */}
              <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()}  />
        <label>Drag 'n' drop <MdOutlineCloudDownload /></label>
      </div>
            </Col>
          </Row>
        </Form.Group>
</div>
        <CustomButton buttonText={"Go Back"} onClick={goBack} />

        <CustomButton buttonText={"Submit"} type={"submit"} />
      </Form>
    </Container>
  );
};

export default OfferForm;
