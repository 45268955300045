import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { getPhonepeRefund, getRefundList } from "../../services/authServices";
import moment from "moment";
import Swal from "sweetalert2";
import { SuccessTost } from "../../components/tostpop/tostPop";
import MainTitle from "../../components/mainTitile/mainTitle";
import TablePagination from "../../components/pagination/tablePagination";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/commanFile";

const RefundComp = () => {
  const [refundList, setrefundList] = useState([]);
  const [refundListdata, setRefundListdata] = useState([]);
  const [singleDataAMendmentId, setSingleDataAMendmentId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isdisable, setIsdisable] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);
  const [refundDetails, setRefundDetails] = useState({
    remark: "",
    amount: "",
  });

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    getRefundData();
  };
  const handleFunds = (e) => {
    const { name, value } = e.target;
    console.warn("refun data form", name, value, e);
    setRefundDetails({ ...refundDetails, [name]: value });
  };
  const handleCancellation = () => {
    let params = {
      tmt_orderId: refundList?.tmt_order_id,
      amount: refundDetails.amount,
      refund_remark: refundDetails.remark,
      amendmentId: refundList?.amendmentId,
    };
    getPhonepeRefund(params)
      .then((res) => {
        SuccessTost(res.data.message);
        handleClose();
        console.warn("refund api response", res);
      })
      .catch((err) => {
        console.warn("refund api error", err);
      });
  };

  const handleShow = () => setShow(true);
  const handleSingleData = (data) => {
    let id = data.amendmentId;
    const params = {
      flow: "single",
      amendmentId: id,
    };
    setShow(true);
    getRefundListDetails(params);
  };
  const getRefundListDetails = (data) => {
    getRefundList(data)
      .then((res) => {
        console.log("single order data here", res);
        setrefundList(res?.data?.Data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getRefundData = () => {
    setLoading(true);
    const params = {
      flow: "all",
    };
   
    getRefundList(params)
      .then((res) => {
        setLoading(false);
        setRefundListdata(res?.data?.Data?.refundData);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleConfirm = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "Do you really want to approved the request for refund.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Approve!",
        cancelButtonText: "No, Cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons
            .fire({
              title: "Approved",
              text: "You have approved the request for refund of $Amount.",
              icon: "success",
            })
            .then((confirm) => {
              if (confirm) {
                handleCancellation();
              }
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Approval Cancelled.",
            text: "You have cancelled the request.",
            icon: "error",
          });
        }
      });
  };
  useEffect(() => {
    getRefundData();
  }, []);
  useEffect(() => {
    if (refundDetails?.remark?.length > 4 && refundDetails.amount.length > 1) {
      setIsdisable(false);
    }
  }, [refundDetails]);

  // datatable component here

  

  const columns = [
    {
      name: "RedundID",
      selector: (row) => row?.refundId,
    },
    {
      name: "AmendmentID",
      selector: (row) => row?.amendmentId,
    },
    {
      name: "OrderID",
      selector: (row) => row?.tmt_order_id,
    },
    {
      name: "UserID",
      selector: (row) => row?.tmt_userId,
    },
    {
      name: "TripjackID",
      selector: (row) => row?.tripBookingId,
    },
    {
      name: "Amendment Status",
      selector: (row) => row?.amendmentStatus,
    },
    {
      name: "Refund Status",
      selector: (row) => row?.refundStatus,
    },
    {
      name: "Refundable Amount",
      selector: (row) => row?.refundableAmount,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="px-0">
          <Button
          className="py-0"
            onClick={() => handleSingleData(row)}
            variant="success"
            disabled={
              row?.amendmentStatus === "REJECTED" ||
              row?.amendmentStatus === "REQUESTED" ||
              row?.amendmentStatus === "PENDING"
            }
          >
          Details
            {/* <b style={{ fontSize: "small" }}>Details</b> */}
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      <Row>
        <MainTitle heading={"Refund List"} textAlign={"center"} />
      </Row>
      <Row>
        <DataTable
        dense
          customStyles={customStyles}
          progressPending={isLoading}
          fixedHeader
          fixedHeaderScrollHeight="500px"
          columns={columns}
          data={refundListdata}
          pagination
        />
      </Row>

      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={show}
          onHide={handleShow}
          centered
          className="p-3"
          style={{ fontSize: "small" }}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Refund Info</Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Table striped>
                  <tbody>
                    <tr>
                      <th>Refund ID</th>
                      <td>:</td>
                      <td>
                        {refundList?.refundId === undefined
                          ? "NA"
                          : refundList?.refundId}
                      </td>
                    </tr>
                    <tr>
                      <th>Amend ID</th>
                      <td>:</td>
                      <td>
                        {refundList?.amendmentId === undefined
                          ? "NA"
                          : refundList?.amendmentId}
                      </td>
                    </tr>
                    <tr>
                      <th>Amendment Status</th>
                      <td>:</td>
                      <td>
                        {refundList?.amendmentStatus === undefined
                          ? "NA"
                          : refundList?.amendmentStatus}
                      </td>
                    </tr>
                    <tr>
                      <th>Refund Status</th>
                      <td>:</td>
                      <td>
                        {refundList?.refundStatus === undefined
                          ? "NA"
                          : refundList?.refundStatus}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col sm={6}>
                <Table striped>
                  <tbody>
                    <tr>
                      <th>Refundable Amount</th>
                      <td>:</td>
                      <td>
                        {refundList?.refundableAmount === undefined
                          ? "NA"
                          : refundList?.refundableAmount}
                      </td>
                    </tr>
                    <tr>
                      <th>TMT Order ID</th>
                      <td>:</td>
                      <td>
                        {refundList?.tmt_order_id === undefined
                          ? "NA"
                          : refundList?.tmt_order_id}
                      </td>
                    </tr>
                    <tr>
                      <th>TMT User ID</th>
                      <td>:</td>
                      <td>
                        {refundList?.tmt_userId === undefined
                          ? "NA"
                          : refundList?.tmt_userId}
                      </td>
                    </tr>
                    <tr>
                      <th>Tripjack BookingID</th>
                      <td>:</td>
                      <td>
                        {refundList?.tripBookingId === undefined
                          ? "NA"
                          : refundList?.tripBookingId}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <label className="mt-2">
              <h5>Trip Information</h5>
            </label>

            <Table striped>
              <thead>
                <tr>
                  <th>Source </th>
                  <th>Destination:</th>
                  <th>Depart Date</th>
                  <th>Airlines Name</th>
                  <th>Flight Number</th>
                </tr>
              </thead>
              <tbody>
                {refundList?.trips?.map((i, k) => {
                  return (
                    <tr key={k}>
                      <td>{i?.src}</td>
                      <td>{i?.dest}</td>
                      <td>{moment(i?.departureDate).format("DD-MM-YYYY")}</td>
                      <td>
                        {i?.airlines?.map((index, k) => {
                          return (
                            <Row className="mx-0" key={k}>
                              {k + 1}
                              {")"} {index}
                            </Row>
                          );
                        })}
                      </td>
                      <td>
                        {i?.flightNumbers?.map((index, k) => {
                          return (
                            <Row className="mx-0" key={k}>
                              {k + 1}
                              {")"} {index}
                            </Row>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <label className="mt-2">
              <h5>Travellers Information</h5>
            </label>
            <Table striped>
              <thead>
                <tr>
                  <th>Traveller Name</th>
                  <th>Total Fare</th>
                  <th>Refundable Amount</th>
                  <th>Amendment Charges</th>
                </tr>
              </thead>
              <tbody>
                {refundList?.trips?.map((i, k) => {
                  return (
                    <tr>
                      {i?.travellers?.map((ind, k) => {
                        return (
                          <>
                            <td>{ind?.fn + " " + ind?.ln}</td>
                            <td>{ind?.totalFare}</td>
                            <td>{ind?.refundableamount}</td>
                            <td>{ind?.amendmentCharges}</td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Form>
              <Table striped>
                <thead>
                  <tr>
                    <td>
                      <FloatingLabel label={"Deduct Amount"}>
                        <Form.Control
                          type="number"
                          name="amount"
                          value={refundDetails?.amount}
                          onChange={(e) => handleFunds(e)}
                        />
                      </FloatingLabel>
                    </td>
                    <td>
                      <FloatingLabel label={"Remarks"} className="mt-2">
                        <Form.Control
                          type="text"
                          name="remark"
                          value={refundDetails?.remark}
                          onChange={(e) => handleFunds(e)}
                        />
                      </FloatingLabel>
                    </td>
                  </tr>
                </thead>
              </Table>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isdisable}
              variant="primary"
              onClick={handleConfirm}
            >
              Approved
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default RefundComp;
