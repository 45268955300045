import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { user_wallet } from "../../services/authServices";

import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/commanFile";


const Wallet = () => {
  const [WalletData, setWalletData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // Wallet Data
  const getWalletData = async () => {
    setLoading(true);
    try {
      const res = await user_wallet();
      setWalletData(res?.data?.Data);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getWalletData();
  }, []);

  const columns1 = [
    {
      name: "User ID",
      selector: (row) => row?.tmt_userIdf,
      maxWidth: "20px",
    },
    {
      name: "Name",
      selector: (row) =>
        row?.userInfo[0]?.title +
        " " +
        row?.userInfo[0]?.firstname +
        " " +
        row?.userInfo[0]?.lastname,
    },
    {
      name: "Email",
      selector: (row) => row?.userInfo[0]?.email,
    },
    {
      name: "Mobile",
      selector: (row) => row?.userInfo[0]?.mobile,
    },
    {
      name: "Promo Balance",
      selector: (row) => row?.total_balance,
      minWidth: "20px",
    },
  ];

  const columns2 = [
    {
      name: "User ID",
      selector: (row) => row?.tmt_userIdf,
    },
    {
      name: "Name",
      selector: (row) =>
        row?.userInfo[0]?.title +
        " " +
        row?.userInfo[0]?.firstname +
        " " +
        row?.userInfo[0]?.lastname,
    },
    {
      name: "Email",
      selector: (row) => row?.userInfo[0]?.email,
    },
    {
      name: "Mobile",
      selector: (row) => row?.userInfo[0]?.mobile,
    },
    {
      name: "MyCash",
      selector: (row) => row?.my_cash,
    },
  ];
  return (
    <div>
      {isLoading}
      <Row>
        <Col>
          <h3>Promocash</h3>
          {/* <hr /> */}
          <DataTable
            columns={columns1}
            data={WalletData?.promocashAmount}
            pagination
            fixedHeader
            dense
            fixedHeaderScrollHeight="20rem"
            customStyles={customStyles}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>MyCash</h3>
          {/* <hr /> */}
          <DataTable
            columns={columns2}
            data={WalletData?.mycashAmount}
            customStyles={customStyles}
            pagination
            fixedHeader
            dense
            fixedHeaderScrollHeight="20rem"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Wallet;
