import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { get_passenger_single_id } from "../../../services/authServices";
import "../orderDetails.css";

const PassengerDetail = () => {
  const [list, setlist] = useState();
  const { id } = useParams();
  const validData = id.split("=");
  const getsectorData = async () => {
    const param = {
      tmt_passenger_id: validData[1],
    };
    try {
      const res = await get_passenger_single_id(param);
      setlist(res.data.Data);
    } catch (e) {
      console.error(e);
    }
  };
  const OnlyDateFormate = (data) => {
    const d = new Date(data);
    let Data =
      d.getDate() + "-" + `${d.getMonth() + 1}` + "-" + d.getFullYear();
    return Data;
  };

  useEffect(() => {
    getsectorData();
  }, [id]);

  return (
    <div className="userdetails">
      <Card className="Card">
        <h4>
          <u>Passenger Details:-</u>
        </h4>
        {list && (
          <>
            <Row>
              <Col lg={5} className="details">
                TMT Passenger ID
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list[0].tmt_passenger_id}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                TMT Order IDF
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list[0].tmt_orderIdf}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
             Passenger Title
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list[0].title}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
               Passenger First Name
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list[0].firstname}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
              Passenger Middle Name
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list[0].middlename}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
              Passenger Last Name
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list[0].lastname}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
              Passenger Date Of Birth
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{OnlyDateFormate(list[0].dob)}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
              Passenger Pax
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list[0].pax_type}</Col>
            </Row>
            </>
        )}

        {!list && <div>something went wrong</div>}
      </Card>
    </div>
  );
};

export default PassengerDetail;
