import { React, useState } from "react";
import { useFormik } from "formik";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/button/button";
import CustomLoader from "../../../components/loader/loader";
import MainTitle from "../../../components/mainTitile/mainTitle";
import { admin_password_change } from "../../../services/authServices";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const ChangePassword = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Initialize the variable for the parameters
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const data = useSelector((state) => state.authentication?.data);
  //On Submit handler
  const onSubmit = async () => {
    const params = { email: data, password: formik.values.password };
    setLoading(true);
    try {
      admin_password_change(params).then((res) => {
        navigate("/");
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Go Back Function
  const goBack = () => {
    navigate(-1);
  };

  // Validation for Formik
  const validationSchema = Yup.object({
    password: Yup.string()
      .required()
      .min(8, "password should be more than 8 digits")
      .required("Please enter Password")
      .matches(RegExp("^[a-zA-Z0-9]{3,30}$")),
    confirmPassword: Yup.string()
      .required()
      .min(8, "password should be more than 8 digits")
      .required("Please enter Password")
      .matches(RegExp("^[a-zA-Z0-9]{3,30}$"))
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  //Formik define
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <CustomLoader isLoading={isLoading} />
      <Container className="d-flex justify-content-center">
        <Form onSubmit={formik.handleSubmit} className="userForm">
          <MainTitle heading={"Change Password"} textAlign={"center"} />

          <Form.Group className="mb-2">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={formik.values.password}
              {...formik.getFieldProps("password")}
              onChange={formik.handleChange}
            />
            {formik.touched.password && formik.errors.password ? (
              <p className="error">{formik.errors.password}</p>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              {...formik.getFieldProps("confirmPassword")}
              // onChange={(e) => handleEdit(e)}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <p className="error">{formik.errors.confirmPassword}</p>
            ) : null}
          </Form.Group>
          <div className="text-center">
            <CustomButton buttonText={"Go Back"} onClick={goBack} />
            <CustomButton buttonText={"submit"} type={"submit"} />
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default ChangePassword;
