import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/button/button";
import {
  airportSearch,
  editDestination,
  popular_destination_post,
  popular_destination_single_data,
} from "../../../services/authServices";
import { debounce } from "lodash";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";


const PopularDestinationForm = () => {
  const { id } = useParams();
  const [popularDest, setPopularDest] = useState({
    order_id: "",
    from:"",
    fromCode:"",
    to:"",
    toCode:"",
    isDomestic:true
  });
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
console.log('popular destionation data',popularDest);
const handleRadioButton = () =>{
  setPopularDest({...popularDest , isDomestic : !popularDest.isDomestic})
}

  const filterBy = () => true;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id !== undefined && id !== null) {
      // const params = {
      //   title: popularDest.title,
      //   order_id: popularDest.order_id,
      //   image: popularDest.image
      // };
      // try {
      //   const res = await editDestination(id, params);
      //   setPopularDest(res.data.Data);
      //   navigate(`/popularDestination`);
      // } catch (e) {
      //   console.error(e);
      try {
        const res = await editDestination(id, popularDest);
        setPopularDest(res.data.Data);
        navigate(`/popularDestination`);
      } catch (e) {
        console.error(e);
      }
    
    } else {
      try {
        const res = await popular_destination_post(popularDest);
        setPopularDest(res.data.Data);
        navigate(`/popularDestination`);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const popularDataAPI = async () => {
    if (id !== undefined) {
      try {
        const res = await popular_destination_single_data(id);
        setPopularDest(res.data.Data);
        console.log('single api data',res.data.Data);
      } catch (e) {
        console.error(e);
      }
    }
  };
  const goBack = () => {
    navigate(-1);
  };

  // Form data format
  const onChangePicture = (e) => {
    setPopularDest({
      ...popularDest,
      image: e.target.files[0],
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPopularDest({ ...popularDest, [name]: value });
  };
  const handleCityWithCode = (operation,e) => {
    console.log('auto search data',e);
    if(e.length > 0){
      if(operation === "from"){
        setPopularDest({...popularDest, from : e[0].city,fromCode:e[0].code})
      }else{
        
        setPopularDest({...popularDest, to : e[0].city,toCode:e[0].code})
      }
    }
  };

  // airport auto seach api
  const airpotAutoSearch = (query) => {
    setIsLoading(true);
    const params = {
      query: query,
    };
    airportSearch(params).then((res) => {
      setOptions(res?.data.Data);
      setIsLoading(false);
    }).catch((err)=>{  
      setIsLoading(false);
    })
  };

  // useEffect(() => {
  //   airpotAutoSearch();
  // }, []);

  const preload = debounce((query) => {
    airpotAutoSearch(query);
  }, 500);


  useEffect(() => {
    popularDataAPI();
  }, [id]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        {/* <Form.Group className="mb-3">
          <Form.Label>Order Id</Form.Label>
          <Form.Control
            type="text"
            placeholder="Order Id"
            autoComplete="off"
            name="order_id"
            value={popularDest.order_id}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group> */}
        {/* <Form.Group className="mb-3">
          <Form.Label>Popular Destination Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Destination Name"
            autoComplete="off"
            name="title"
            value={popularDest.title}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group> */}
        <AsyncTypeahead
            filterBy={filterBy}
              size="2"
              type={"text"}
              minLength={3}
              multiple={false}
              options={options}
              onSearch={preload}
              isLoading={isLoading}
              id="fromCityOrAirport"
              placeholder="From"
              name="from"
              // selected={flightSource}
              // className={styles.form_control}
              onChange={(e) => handleCityWithCode('from',e)}
              // inputProps={{ className: styles.form_control }}
              labelKey={(option) =>
                ` ${option.code} - ${option.city}, `
              }
              renderMenuItemChildren={(option) => (
                <div >
                 <label>{`${option.city} (${option.code})`}</label> 
                </div>
              )}
            />
        <AsyncTypeahead
            filterBy={filterBy}
              size="2"
              type={"text"}
              minLength={3}
              multiple={false}
              options={options}
              onSearch={preload}
              isLoading={isLoading}
              id="To"
              placeholder="To"
              name="to"
              
              defaultInputValue={`${popularDest?.to}`}
              // selected={flightSource}
              // className={styles.form_control}
              onChange={(e) => handleCityWithCode('To',e)}
              // inputProps={{ className: styles.form_control }}
              labelKey={(option) =>
                ` ${option.code} - ${option.city}, `
              }
              renderMenuItemChildren={(option) => (
                <div >
                 <label>{`${option.city} (${option.code})`}</label> 
                </div>
              )}
            />

            <Form.Group className="d-flex">
              <Form.Check type="radio" onChange={handleRadioButton} checked={popularDest.isDomestic === true} label="Domestic" />
              <Form.Check type="radio" onChange={handleRadioButton} checked={popularDest.isDomestic === false} label="International" />
            </Form.Group>

        {/* <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Destination Image</Form.Label>
          <Form.Control
            type="file"
            name="image"
            onChange={(e) => onChangePicture(e)}
          />
          <Form.Text className="text-muted">
            Upload an Image of the destination.
          </Form.Text>
        </Form.Group> */}

        <div className="d-flex mx-2">
          <CustomButton buttonText={"Go Back"} onClick={goBack} />
          <CustomButton buttonText={"Submit"} type={"submit"} />
        </div>
      </Form>
    </>
  );
};

export default PopularDestinationForm;
