import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const SuccessTost = (Message) => {
  toast.success(Message, {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};

export const ErrorTost = (Message) => {
  toast.error(Message, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};
export const WarningTOst = () => {
  toast.warning("Warning Notification !", {
    position: toast.POSITION.TOP_RIGHT,
  });
};
export const InfoTost = () => {
  toast.info("Information Notification !", {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const DefaultTOst = () => {
  toast("Default Notification !", {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};
