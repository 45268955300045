import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const SectorList = ({ columns, data }) => {
  const navigate = useNavigate();

  const handleClick = (item) => {
    navigate(`/sectorDetails/id=${item.sector_Id}`);
  };
  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        onRowClicked={(data) => handleClick(data)}
      />
    </div>
  );
};

export default SectorList;
