import React from "react";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteDestination,
  popular_destination,
} from "../../services/authServices";
import MainTitle from "../../components/mainTitile/mainTitle";
import TablePagination from "../../components/pagination/tablePagination";
import CustomButton from "../../components/button/button";
import "./popularDesintation.css";
import CustomLoader from "../../components/loader/loader";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/commanFile";
import { FiPlusCircle } from "react-icons/fi";
import PopularFlightForm from "../../components/popularFlights/popularFlightForm";

const PopularDestinationComponent = () => {
  const [domesticFlights, setDomesticFlights] = useState([]);
  const [internationalFlights, setInternationalFlights] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [singleDataId, setSingleDataId] = useState(null);
  const [singleData, setSingleData] = useState();
  console.log('id console hai',singleDataId);

  const handleShow = () => setShowModal(true);
  const handleShowWithid = (operation,id) =>{
    setSingleDataId(id)
    // setShowModal(true);
    handleShow()
  }
  const handleClose = () => {
    setShowModal(false);
    getPopularData()
    setSingleDataId(null)
  }
  const [key, setKey] = useState('Doemstic');
  let navigate = useNavigate();
  //To take all the data from the backend
  const getPopularData = async () => {
    try {
      setLoading(true);
      const res = await popular_destination();
      let Domestic = res.data.Data.getData.filter((i) => i.isDomestic === true) 
      let International = res.data.Data.getData.filter((i) => i.isDomestic === false) 
      setDomesticFlights(Domestic);
      setInternationalFlights(International)
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getPopularData();
  }, []);

  const formpage = () => {
    let path = "/destinationForm";
    navigate(path);

  };

  const handleDelete = (userid, e) => {
    deleteDestination(userid).then((res)=>{
      getPopularData()
    }).catch((err)=>{
    })
  };

  const columns = [
    
    {
      name: "From",
      selector: (row) => row?.from,
    },
    {
      name: "From Code",
      selector: (row) => row?.fromCode,
    },
    {
      name: "To",
      selector: (row) => row?.to,
    },
    {
      name: "From Code",
      selector: (row) => row?.toCode,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex">
          <div className="mx-2" onClick={()=>handleShowWithid('edit',row)}>
          
          <Link>
                      <AiFillEdit color="green" size={20} /> 
                  </Link>
          </div>
          <div>
            <AiFillDelete
              color="red"
              onClick={() => handleDelete(row._id)}
              style={{ cursor: "pointer" }}
              size={20}
            />
          </div>
        </div>
      ),
    },
  ];

  console.log('activ key here',key);
 

  return (
    <div>
      <CustomLoader isLoading={isLoading} />
      {/* <Row>
        <Col sm={10} className="pt-3">
          <MainTitle
            heading={"Popular Destination Details"}
            textAlign={"center"}
          />
        </Col>

        <Col sm={2}>
          <CustomButton buttonText={"Add"} onClick={formpage} />
        </Col>
      </Row> */}

      <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="Doemstic" title="Doemstic">
      <Row className="py-4">
        <Col sm={10} className="d-flex align-items-center justify-content-center">
          {/* <MainTitle
            heading={ " Domestic Popular Destination Details"}
            textAlign={"center"}

          /> */}
          <h2 className="fw-700 mb-0 text-center" >Popular Domestic Flights</h2>
        </Col>

        <Col sm={2} className="justify-content-end">
          {/* <CustomButton buttonText={"Add"} onClick={formpage} /> */}
          <FiPlusCircle size={30}  onClick={()=>handleShowWithid('add',null)} style={{cursor:'pointer'}} />
        </Col>
      </Row>
      <DataTable
            dense
            customStyles={customStyles}
            progressPending={isLoading}
            columns={columns}
            data={domesticFlights}
            pagination
          />
      </Tab>
      <Tab eventKey="International" title="International">
      <Row className="py-4">
        <Col sm={10} className="d-flex align-items-center justify-content-center">

          <h2 className="fw-700 mb-0 text-center" >Popular International Flights</h2>
        </Col>

        <Col sm={2} className="justify-content-end">
          {/* <CustomButton buttonText={"Add"} onClick={formpage} /> */}
          <FiPlusCircle size={30}  onClick={()=>handleShowWithid('add',null)} style={{cursor:'pointer'}} />
        </Col>
      </Row>
      <DataTable
            dense
            customStyles={customStyles}
            progressPending={isLoading}
            columns={columns}
            data={internationalFlights}
            pagination
          />
      </Tab>
    </Tabs>
    {showModal === true &&
    <PopularFlightForm data={singleDataId} showModal={showModal} isDomestic={key}  handleClose={handleClose}/>
    }

    </div>
  );
};

export default PopularDestinationComponent;
