import { configureStore } from "@reduxjs/toolkit";
import { authenticationReducer } from './authSlice';

export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
    }
});

export const RootState = store.getState()
export const AppDispatch = store.dispatch