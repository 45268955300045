import { deleteApi, deleteMethod, get, getById, post, postById, put } from "./api";
import { getAccessToken, removeItem } from "./localStorage";
import {
  ADMIN_DATA_API,
  ADMIN_DATA_BY_ID_API,
  LOGIN_API,
  ADMIN_ADD_API,
  ADMIN_DELETE_API,
  ADMIN_EDIT_API,
  USER_ADD_API,
  ADMIN_IMG_API,
  USER_IMG_API,
  OFFER_DISCOUNT_API,
  POPULAR_DESTINATION_API,
  POPULAR_DELETE_API,
  ADD_FLIGHT_LOGO_API,
  GET_FLIGHT_LOGO_API,
  ADMIN_PASSWORD_CHANGE_API,
  ORDER_LIST_API,
  ORDER_LIST_API_BY_ID,
  SECTOR_LIST_API_BY_ORDERIDF,
  SECTOR_LIST_API_BY_ID_SINGLE,
  PASSENGER_LIST_API_BY_ORDERIDF,
  PASSENGER_LIST_API_BY_ID_SINGLE,
  OFFER_DISCOUNT_API_BY_ID,
  OFFER_DISCOUNT_SINGLE_DATA,
  POPULAR_DESTINATION_SINGLE_DATA,
  POPULAR_UPDATE_API,
  Term_Use,
  Term_Services,
  Privacy_Cookie,
  Payment_Security,
  Privacy_Cookie_UPDATE,
  Payment_Security_UPDATE,
  UPDATE_Term_Use,
  UPDATE_Term_Services,
  Blog_get,
  Blog_Single_data,
  Blog_post,
  Blog_update_data,
  FAQ_GET,
  FAQ_SINGLE_DATA,
  FAQ_UPDATE_DATA,
  FAQ_POST,
  CMS_TYPES,
  GET_POST_DATA_CMS,
  UPDATE_CMS,
  SINGLE_CMS,
  UPDATE_DELETE_CMS,
  EXPORT_CSV_DATA,
  TRANSACTION_HISTORY,
  USER_WALLET,
  SINGLE_USER_WALLET,
  ORDER_DATA,
  AMEND_ORDER,
  GET_AMEND_DETAILS_ONLY,
  ACCOUNT_DETAILS,
  SUBMIT_AMMENDMENT,
  AMMENDMENT_DETAILS,
  REFUND_LIST,
  PHONEOE_REFUAND,
  PHONEPE_REFUND,
  All_HOLIDAY_PACKAGES,
  REFUND_FAILED,
  DATA_EXPORTS,
  TICKES_DOWNLOAD,
  OFFER_DISCOUNT_POST,
  AIRPORT_DATA,
  SINGLEAIRPORT,
  SINGLE_AIRPORT,
  AIRPORT_SEARCH,
} from "../constant/api";

//Admin Login
export const loginUser = (params) => {
  return post(LOGIN_API, params);
};

//Admin Logout
export const logOutUser = () => {
  return removeItem("loginUser");
};

// Authentication Token
export const isAuth = () => {
  return getAccessToken() ? true : false;
};

// Get Data by ID
export const adminData = async () => {
  return get(ADMIN_DATA_API);
};

//For getting the data by ID
export const adminById = async (id) => {
  return getById(ADMIN_DATA_BY_ID_API + id);
};

//For updating the data by Id
export const adminEdit = async (id, params) => {
  return put(ADMIN_EDIT_API + id, params);
};

//For adding the data by taking all the required field
export const addAdmin = async (params) => {
  return post(ADMIN_ADD_API, params);
};

// Change password of admin
export const admin_password_change = async (params) => {
  return post(ADMIN_PASSWORD_CHANGE_API, params);
};

//For adding the data by taking all the required field
export const addUser = async (params) => {
  return post(USER_ADD_API, params);
};

//For deleting the data from the database
export const deleteUser = async (id) => {
  return deleteApi(ADMIN_DELETE_API + id);
};

//For uploading image to user
export const admin_profile = async (id) => {
  return put(ADMIN_IMG_API + id);
};

//For uploading image to user
export const user_profile = async (id) => {
  return put(USER_IMG_API + id);
};

// =================Offers============== //
//For uploading image to user
export const offers_discount = async () => {
  return get(OFFER_DISCOUNT_API);
};

//For uploading image to user
export const addOfferData = async (params) => {
  return post(OFFER_DISCOUNT_POST, params);
};

export const offers_discountEdit = async (id, params) => {
  return put(OFFER_DISCOUNT_API_BY_ID + id, params);
};
//For uploading image to user
export const valid_offers = async () => {
  return post(OFFER_DISCOUNT_API);
};

//For deleting the data from the database
export const deleteOffer = async (id) => {
  return deleteApi(OFFER_DISCOUNT_API_BY_ID + id);
};

export const singleOffer = async (id) => {
  return post(OFFER_DISCOUNT_SINGLE_DATA, id);
};

// =================Popular============== //
// For Popular destination
export const popular_destination = async () => {
  return get(POPULAR_DESTINATION_API);
};

// For adding data in popular destination
export const popular_destination_post = async (params) => {
  return post(POPULAR_DESTINATION_API, params);
};

//get the single data in pupular destination
export const popular_destination_single_data = async (id) => {
  return post(POPULAR_DESTINATION_SINGLE_DATA + id);
};

// for data edit
export const editDestination = async (id, params) => {
  return put(POPULAR_UPDATE_API + id, params);
};

//For deleting the data from the database
export const deleteDestination = async (id) => {
  return deleteApi(POPULAR_DELETE_API + id);
};

//============Flight Logo================================//
// add flight logo data
export const add_flight_logo = async (params) => {
  return post(ADD_FLIGHT_LOGO_API, params);
};

// get flight logo data
export const get_flight_logo = async (params) => {
  return post(GET_FLIGHT_LOGO_API, params);
};

export const get_Order_list = async (params,pagignation) => {
  return post(ORDER_LIST_API+`?page=${pagignation?.page}&limit=${pagignation?.limit}`,params);
};

// export const getRefundList = async (params,pagignation) => {
//   return post(REFUND_LIST+`?page=${pagignation?.page}&limit=${pagignation?.limit}`,params);
// };

//web management........................................//
//get
export const get_Term_Use = async () => {
  return get(Term_Use);
};
//post
export const post_Term_Use = async (params) => {
  return post(Term_Use, params);
};
//update
export const update_Term_Use = async (id, params) => {
  return put(UPDATE_Term_Use + id, params);
};
//delete
export const delete_Term_Use = async (id) => {
  return deleteApi(UPDATE_Term_Use + id);
};
//get
export const get_Term_Service = async () => {
  return get(Term_Services);
};
//post
export const post_Term_Service = async (params) => {
  return post(Term_Services, params);
};
//update
export const update_Term_services = async (id, params) => {
  return put(UPDATE_Term_Services + id, params);
};
//delete
export const delete_Term_Service = async (id) => {
  return deleteApi(UPDATE_Term_Services + id);
};
//get
export const get_privacy_cookie = async () => {
  return get(Privacy_Cookie);
};
//post
export const post_privacy_cookie = async (params) => {
  return post(Privacy_Cookie, params);
};

//update
export const update_privacy_cookie = async (id, params) => {
  return put(Privacy_Cookie_UPDATE + id, params);
};
//delete
export const delete_privacy_cookie = async (id) => {
  return deleteApi(Privacy_Cookie_UPDATE + id);
};
//get
export const get_Payment_Security = async () => {
  return get(Payment_Security);
};
export const post_Payment_Security = async (params) => {
  return post(Payment_Security, params);
};
//update
export const update_Payment_Security = async (id, params) => {
  return put(Payment_Security_UPDATE + id, params);
};
//delete
export const delete_Payment_Security = async (id) => {
  return deleteApi(Payment_Security_UPDATE + id);
};

//....................................................//

export const get_Order_list_By_Id = async (params) => {
  return postById(ORDER_LIST_API_BY_ID, params);
};
export const get_sector_list_by_orderid = async (params) => {
  return postById(SECTOR_LIST_API_BY_ORDERIDF, params);
};

export const get_sector_list_single = async (params) => {
  return post(SECTOR_LIST_API_BY_ID_SINGLE, params);
};

export const get_Passenger_list_by_orderid = async (params) => {
  return postById(PASSENGER_LIST_API_BY_ORDERIDF, params);
};

export const get_passenger_single_id = async (params) => {
  return post(PASSENGER_LIST_API_BY_ID_SINGLE, params);
};

//cms type
// export const get_Cms_type = async () => {
//   return get(CMS_TYPES);
// };

//cms
export const Cms_data_get = async (params) => {
  return get(GET_POST_DATA_CMS, params);
};
export const Cms_data_post = async (params) => {
  return post(GET_POST_DATA_CMS, params);
};
export const update_cms_data = async (id, params) => {
  return put(UPDATE_DELETE_CMS + id, params);
};
export const single_cms_data = async (params) => {
  return post(SINGLE_CMS, params);
};

//order flight data download
export const export_csv_data_download = async () => {
  return get(EXPORT_CSV_DATA);
};

//===========================FAQ==========================//
export const get_Faq_data = async () => {
  return get(FAQ_GET);
};
export const get_faq_single_data = async (params) => {
  return post(FAQ_SINGLE_DATA + params);
};
export const post_faq_data = async (params) => {
  return post(FAQ_POST, params);
};
export const update_faq_data = async (id, params) => {
  return put(FAQ_UPDATE_DATA + id, params);
};
export const delete_faq_data = async (id) => {
  return deleteApi(FAQ_UPDATE_DATA + id);
};

//===========================DASHBOARD==========================//
export const order_data = async () => {
  return get(ORDER_DATA);
};

export const transaction_history = async () => {
  return get(TRANSACTION_HISTORY);
};

// FOR ALL USERS WALLET AMOUNT
export const user_wallet = async () => {
  return get(USER_WALLET);
};

//================AMENDEMENT===================//
export const amend_order_data = async (params) => {
  return post(AMEND_ORDER, params);
};
export const get_amendment_data = async (params) => {
  return post(GET_AMEND_DETAILS_ONLY, params);
};
export const submit_amendment = async (params) => {
  return post(SUBMIT_AMMENDMENT, params);
};
export const amendment_details = async (params) => {
  return post(AMMENDMENT_DETAILS, params);
};

// ================ADMIN ACCOUNT DETAILS=======//
export const getAdminAccount = async () => {
  return get(ACCOUNT_DETAILS);
};

// ================ADMIN ACCOUNT DETAILS=======//
export const getRefundList = async (params,pagignation) => {
  return post(REFUND_LIST,params);
};
export const getPhonepeRefund = async (params) => {
  return post(PHONEPE_REFUND,params);
};

// =============Holiday========//

export const getAllHolidayPackages = async () => {
  return get(All_HOLIDAY_PACKAGES);
};
export const deleteHolidayPackages = async (params) => {
  return deleteApi(All_HOLIDAY_PACKAGES+"/"+params);
};
export const getSingleHolidayPackages = async (params) => {
  return post(All_HOLIDAY_PACKAGES,params);
};
export const HolidayPackagesUpdate = async (params) => {
  return put(All_HOLIDAY_PACKAGES,params);
};


// ========== refund failed ======//

export const refundFailed = async (params) => {
  return post(REFUND_FAILED,params);
};
export const dataExports = async (params) => {
  return post(DATA_EXPORTS,params);
};
export const downloadTickets = async (params) => {
  return post(TICKES_DOWNLOAD,params);
};

// Airports API.....................

export const getAirport = async () => {
  return get(AIRPORT_DATA);
};
export const addAirport = async (params) => {
  return post(AIRPORT_DATA,params);
};
export const updateAirport = async (id,params) => {
  return put(AIRPORT_DATA+`/${id}`,params);
};
export const getSingleAirport = async (id) => {
  return post(SINGLE_AIRPORT+id);
};
export const deleteAirport = async (id) => {
  return deleteApi(SINGLE_AIRPORT + id);
};

// auto search 
export const airportSearch = async (params) => {
  return post(AIRPORT_SEARCH, params);
}