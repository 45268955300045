import React from 'react'
import { Link } from 'react-router-dom'

const TabLink = ({ href, children, className, ...rest }) => {

    return (
        <Link href={href} {...rest} target="_blank" className={className}>
            {children}
        </Link>
    )
}

export default TabLink
