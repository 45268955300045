import JoditEditor from "jodit-react";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  get_Term_Use,
  get_faq_single_data,
  post_Term_Use,
  post_faq_data,
  update_Term_Use,
  update_faq_data,
} from "../../../services/authServices";
import CustomLoader from "../../../components/loader/loader";
import MainTitle from "../../../components/mainTitile/mainTitle";
import CustomButton from "../../../components/button/button";
import { useNavigate, useParams } from "react-router-dom";

const FaqForm = () => {
  const initialvalue = {
    title: "",
    content: "",
  };
  const { id } = useParams();
  const navigate = useNavigate();

  const [fAQ, setFAQ] = useState(initialvalue);
  const [isLoading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFAQ({ ...fAQ, [name]: value });
  };
  const getTerms = async () => {
    setLoading(false);
    try {
      const res = await get_faq_single_data(id);
      setFAQ(res?.data?.Data);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handlesubmit = async () => {
    if (id !== undefined && id !== null) {
      try {
        const params = {
          title: fAQ.title,
          content: fAQ.content,
        };
        const res = await update_faq_data(id, params);
        setFAQ(res?.data?.Data);
        navigate("/faq");
      } catch (e) {
        console.error(e);
      }
    } else {
      const res = await post_faq_data(fAQ);
      setFAQ(res?.data);
      navigate("/faq");
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <div>
      <CustomLoader isLoading={isLoading} />
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Question</Form.Label>
        <Form.Control
          type="text"
          placeholder="title"
          name="title"
          value={fAQ?.title}
          onChange={(e) => handleChange(e)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Answer</Form.Label>
        <Form.Control
          type="text"
          placeholder="content"
          name="content"
          value={fAQ?.content}
          onChange={(e) => handleChange(e)}
        />
      </Form.Group>
      <CustomButton buttonText={"Submit"} onClick={handlesubmit} />
    </div>
  );
};

export default FaqForm;
