import React from "react";
import { Card, Col, Container, NavDropdown, Row } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";

const DashboardWidget = ({ title, balance, src, handleDropdown }) => {
  return (
    <div>
      <Card
        className="my-2 mainColor"
        style={{
          width: "13rem",
          // minHeight: "12rem",
          borderRadius: "24px",
          margin: "0rem 1rem",
        }}
      >
        <Container>
          <Card.Body>
            {/* <Row>
              <Col className="d-flex justify-content-end">
                <HiDotsVertical color="#281B75" />
              </Col>
            </Row> */}
            <Row className="mb-2">
              <Col lg={2} md={2} sm={2} xs={2}>
                <img fluid width={20} height={20} src={src} style={{ filter:' brightness(10)'}} />
              </Col>
              <Col>
                <Card.Title
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "#ffff",
                    lineHeight:'20px'
                  }}
                >
                  {balance}
                </Card.Title>
              </Col>
            </Row>
            {/* <Row>
            </Row> */}
            <Row>
              <Col>
                <Card.Text
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#ffff",
                  }}
                >
                  {title}
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Container>
      </Card>
    </div>
  );
};

export default DashboardWidget;
