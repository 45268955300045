import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/loader/loader";
import {
  amend_order_data,
  dataExports,
  get_Order_list,
} from "../../services/authServices";
import moment from "moment/moment";
import { Col, Form, Row } from "react-bootstrap";
import TablePagination from "../../components/pagination/tablePagination";
import CustomButton from "../../components/button/button";
import { DATA_EXPORTS } from "../../constant/api";
import axios from "axios";
import { DatePicker } from "antd";
import { customStyles } from "../../utils/commanFile";
const { RangePicker } = DatePicker;
// import '../orderDetails/orderDetails.css'



const OrderList = () => {
  const [OrderData, setOrderData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [order_Details, setOrder_Details] = useState("");
  const [userId, setUserId] = useState("");
  const [filterDate, setFilterDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(0);
  const [pageData, setPageData] = useState(30);
  const [page, setPage] = useState(1);

  const toHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}H:${minutes}Min`;
  };
  const handleClick = (item) => {
    navigate(`/orderDetailsList/id=${item.tmt_order_id}`);
  };
  const OnlyDateFormate = (data) => {
    return moment(data).format("DD-MM-YYYY");
  };
  const DetailedDate = (data) => {
    const d = new Date(data);
    return moment(d).format("MMMM Do YYYY, H:MM A");
  };

  // Data
  const columns = [
    {
      name: "TMT Order ID",
      selector: (row) => row?.tmt_order_id,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "TMT User ID",
      selector: (row) => row?.tmt_userIdf,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "Booking Date",
      selector: (row) => OnlyDateFormate(row.booking_date),
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "Trip Type",
      selector: (row) => row?.trip_type,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "Total Amount",
      selector: (row) => row?.trip_info?.sales,
      style: {
        cursor: "pointer",
      },
    },

    {
      name: "Booking Status",
      style: {
        cursor: "pointer",
      },
      // selector: (row) => row?.booking_status,
      cell: (row) => (
        <div
          className={row?.booking_status === "ABORTED" && "abortedCSS"}
          style={
            row?.booking_status === "SUCCESS"
              ? { color: "green", fontWeight: "600", fontSize: "12px" }
              : { color: "red", fontWeight: "600", fontSize: "10px" }
          }
        >
          {row?.booking_status}
        </div>
      ),
    },
    {
      name: "Cabin Class",
      selector: (row) => row?.cabinclass,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "Merchant ID",
      selector: (row) => row?.tmt_merchant_id,
      style: {
        cursor: "pointer",
      },
    },

    // {
    //   name: "Merchant Order Invoice ID",
    //   selector: (row) => row?.tmt_merchant_invoice_id,
    // },
    {
      name: "Merchant Order ID",
      selector: (row) => row?.merchant_orderId,
      style: {
        cursor: "pointer",
      },
      minWidth: "140px",
    },

    {
      name: "Base Fare",
      selector: (row) =>
        "₹" + row?.trip_info?.totalPriceInfo?.totalFareDetail?.fC?.BF,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "Net Fare",
      selector: (row) =>
        "₹" + row?.trip_info?.totalPriceInfo?.totalFareDetail?.fC?.NF,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "Tax And Fare",
      selector: (row) =>
        "₹" + row?.trip_info?.totalPriceInfo?.totalFareDetail?.fC?.TAF,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "Total Fare",
      selector: (row) =>
        "₹" + row?.trip_info?.totalPriceInfo?.totalFareDetail?.fC?.TF,
      style: {
        cursor: "pointer",
      },
    },
  ];
  const getDetails = async (page, per_page) => {
    setLoading(true);
    // let pagination = { page, limit: pageData };
    let params = {
      request: "GETDATA",
    };
    let data = { page: page, limit: per_page };
    try {
      const res = await get_Order_list(params, data);
      setOrderData(res?.data?.Data.getOrder);
      setPageCount(res?.data?.Data.orderCount);
      setTotalRows(res?.data?.Data.orderCount);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  // pagination logic...

  useEffect(() => {
    getDetails(1, perPage);
  }, [perPage]);

  const handlePageChange = (page) => {
    getDetails(page, perPage);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  useEffect(() => {
    const pagedatacount = Math.ceil(pageCount / pageData);
    setPageCount(pagedatacount);
  }, [OrderData]);

  const handleRowSize = (e) => {
    const { innerHTML } = e.target;
    setPageData(innerHTML);
    console.log("row per page size", innerHTML);
  };

  const handleOrderData = (e) => {
    const { name, value } = e.target;
    console.log("selected value", value);
    setOrder_Details(value);
  };

  const handleDataExport = async () => {
    let params = {};

    if (order_Details === "All Date") {
      params = { FilterBy: order_Details };
    } else if (order_Details === "Client") {
      params = { FilterBy: order_Details, userid: userId };
    } else if (order_Details === "Booking Date") {
      params = {
        FilterBy: order_Details,
        to: filterDate.toDate,
        from: filterDate.fromDate,
      };
      console.log("filter date here for ", filterDate);
    } else {
    }
    const response = await axios.post(DATA_EXPORTS, params, {
      responseType: "blob", // Set responseType to 'blob' to handle binary data
    });

    console.log("Response pdf", response);
    const blob = new Blob([response.data], { type: "application/xlsx" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${order_Details}.xlsx`; // Set the desired file name
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };
  // const disabledDate = (current) => {
  //   return moment().add(-1, "days") >= current;
  // };

  const handeldate = (date) => {
    setFilterDate({
      ...filterDate,
      fromDate: moment(date[0]?.$d).format("YYYY-MM-DD"),
      toDate: moment(date[1]?.$d).format("YYYY-MM-DD"),
    });
  };
  console.log("order detail here", order_Details);
  return (
    <div>
      <Row className="my-3">
        <Col lg={3} className="d-flex align-items-center">
          <Form.Select
            onChange={handleOrderData}
            aria-label="Default select example"
          >
            <option>Filter Data</option>
            <option value="All Date">All Date</option>
            <option value="Client">Client</option>
            <option value="Booking Date">Booking Date</option>
          </Form.Select>
        </Col>
        <Col className="d-flex align-items-center">
          {order_Details === "Booking Date" && (
            <RangePicker
              allowClear={true}
              onChange={(val) => {
                handeldate(val);
              }}
              format={["DD-MM-YYYY", "DD-MM-YYYY"]}
              placeholder={["fromDate", "toDate"]}
              // disabledDate={disabledDate}
            />
          )}
          {order_Details === "Client" && (
            <Form>
              <Form.Control
                type="text"
                //  autoComplete="off"
                autoComplete="new-password"
                name="myInput"
                placeholder="Enter UserIdf"
                //  value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </Form>
          )}
        </Col>
        <Col>
          <CustomButton
            disabled={order_Details.length === 0}
            buttonText={"Export"}
            onClick={handleDataExport}
          />
        </Col>
      </Row>
      {/* <CustomLoader isLoading={isLoading} /> */}

      <DataTable
        columns={columns}
        data={OrderData}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        responsive
        progressPending={isLoading}
        dense
        customStyles={customStyles}
        onRowClicked={(data) => handleClick(data)}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
    </div>
  );
};

export default OrderList;
