import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  amendment_details,
  dataExports,
  downloadTickets,
  get_Order_list_By_Id,
  get_Passenger_list_by_orderid,
  get_amendment_data,
  get_sector_list_by_orderid,
  refundFailed,
  submit_amendment,
} from "../../services/authServices";
import { IoMdArrowDropdown, IoMdArrowDropright, IoMdArrowDropup } from "react-icons/io";
import "./orderDetails.css";
import SectorList from "./sectorList";
import PassengerList from "./passengerList/passengerList";
import CustomButton from "../../components/button/button";
import { ErrorTost, SuccessTost } from "../../components/tostpop/tostPop";
import moment from "moment/moment";
import TabLink from "../../components/link/tabLink";
import axios from "axios";
import { TICKES_DOWNLOAD } from "../../constant/api";
import { BusyButton } from "react-simple-widgets";

const OrderDetailsList = () => {
  const navigate = useNavigate();
  const [cancelPop, setCancelPop] = useState(false);
  const [cancelType, setCancelType] = useState({
    FullTripCencel: true,
    PassengerCencel: false,
    TripCencel: false,
  });

  let travellers = [
    {
      fn: "",
      ln: "",
    },
  ];

  const DetailedDate = (data) => {
    const d = new Date(data);
    return moment(data).format("YYYY-MM-DD");
  };
  const handleCancellationChange = (data) => {
    // PassengerCencel
    // TripCencel
    // FullTripCencel
    if (data === "PassengerCencel") {
      setCancelType({ PassengerCencel: !cancelType?.PassengerCencel });
    } else if (data === "TripCencel") {
      setCancelType({ TripCencel: !cancelType?.TripCencel });
      // setCancelType({...cancelType , TripCencel : cancelType?.TripCencel === true})
    } else {
      setCancelType({ FullTripCencel: cancelType.FullTripCencel !== false });
    }
  };

  const [amendmentId, setAmendmentId] = useState();
  const [PassengerInfo, setPassengerInfo] = useState([]);
  const [removePass, setRemovePass] = useState([]);
  const [list, setlist] = useState();
  const [PNR_Data, setPNR_Data] = useState([]);

  const givenDate = moment(list?.trip_info?.sector_details[0]?.sI[0]?.dt);
  const today = moment();
  console.log('single flight data here',removePass);
  const [OrderID, setOrderID] = useState([]);
  const [SingleData, setSingleData] = useState([]);
  const [sectoreInfo, setSectoreInfo] = useState([]);
  const [cancelFlight, setCancelFlight] = useState([]);
  const [cancelFlightData, setCancelFlightData] = useState([]);
  console.log('cancellation details herre',cancelFlightData?.amendmentInfo?.INFANT);
  const [cancelFlightReturnData, setCancelFlightReturnData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  const [SectorData, setSectorData] = useState([]);
  const [showTax, setShowTax] = useState(false);
  const [show, setShow] = useState(false);
  const [sectoreShow, setSectoreShow] = useState(false);
  const [passengerShow, setPassengerShow] = useState(false);
  const [RemarkText, setRemarkText] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSectoreClose = () => setSectoreShow(false);
  const handlePassengerClose = () => setPassengerShow(false);
  const [passenger, setPassenger] = useState([]);
  const [sectoreInfoReturn, setSectoreInfoReturn] = useState([]);
  const [totalAdult, setTotalAdult] = useState();
  const [totalChild, setTotalChild] = useState();
  const [totalInfant, setTotalInfant] = useState();
  const [fareShow, setFareShow] = useState(false);
  const [fareShowReturn, setFareShowReturn] = useState(false);
  const TMTFEE = SingleData?.trip_type === "DOMESTIC" ? 400 : 800;

  const hancleFare = () => {
    setFareShow((isv) => !isv);
  };
  const [isCancellation ,setIsCancellation] = useState(false)
  const { id } = useParams();
  const validData = id?.split("=");
console.log('PNR data from resposne',PNR_Data);
  const getOrderData = async () => {
    const param = {
      tmt_order_id: validData[1],
    };

    try {
      const res = await get_Order_list_By_Id(param);
      setlist(res?.data?.Data);
      let PNR_value = Object.values(res?.data?.Data?.pnr_details[0])
      setPNR_Data(PNR_value)
      // setSectoreInfoReturn(res.data.Data.trip_info.sector_details)
      setPassenger(res.data.Data.trip_info.travellerInfo);

      setPassengerInfo(res?.data?.Data.trip_info?.travellerInfo);
      setSingleData(res?.data?.Data);
    // console.log({ SingleData });
    setSectoreInfo(res?.data?.Data?.trip_info?.sector_details[0]?.sI);
    setSectoreInfoReturn(res?.data?.Data.trip_info?.sector_details[1]?.sI);
    } catch (e) {
      console.error(e);
    }
  };


  useEffect(()=>{

    if(list){
      if (givenDate.isBefore(today)) {
        setIsCancellation(true)
      } else {
        setIsCancellation(false)
        console.log('The given date is not in the past.');
      }            
    }
  
  },[list])

  const totalFareCalculation = (list) => {
    if (!list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC?.TAF?.MU) {
      return !list?.trip_info?.totalPriceInfo?.totalFareDetail?.fC?.TAF &&
        !list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC?.TAF?.MU
        ? "NA"
        : list?.trip_info?.totalPriceInfo?.totalFareDetail?.fC?.TAF - 0;
    } else {
      return !list?.trip_info?.totalPriceInfo?.totalFareDetail?.fC?.TAF &&
        !list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC?.TAF?.MU
        ? "NA"
        : list?.trip_info?.totalPriceInfo?.totalFareDetail?.fC?.TAF -
            list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC?.TAF?.MU;
    }
  };

  // ==========================================Sector List=========================//


  const OnlyDateFormate = (data) => {
    const d = new Date(data);
    return moment(data).format("DD-MM-YYYY");
  };

  const DateTimeFormat = (data) => {
    const d = new Date(data);
    return moment(data).format("MMMM Do YYYY, h:mm:ss a");
  };
  useEffect(() => {
    getOrderData();
  }, [id]);

  const hancleFareReturn = () => {
    setFareShowReturn((isv) => !isv);
  };

  const handleTaxes = () => {
    setShowTax((is) => !is);
  };

  // ==========  cancellation logic here ============//

 const  handleCancellation = () =>{
  setCancelPop(true)
}

const [selectedTrip, setSelectedTrip] = useState({
  oneway: false,
  roundtrip: false,
});
const [tripdata, setTripdata] = useState([]);
const [tripdataReturn, setTripdataReturn] = useState([]);
const handleSectoreDetails = (data, operation) => {
  // operation === "oneway" && setTripdata({ data });
  // operation === "return" && setTripdataReturn({ data });

  let newValue = [...tripdata];
  // operation === "oneway" && setTripdata({ data });
  // operation === "return" && setTripdataReturn({ data });
  if (operation === "oneway") {
    setSelectedTrip({ oneway: true, roundtrip: false });
    newValue = data;
  }
  if (operation === "return") {
    setSelectedTrip({ oneway: false, roundtrip: true });
    newValue = data;
  }
  setTripdata(newValue);
};
// console.log('selected flight data',data);

const submitAmendment = (e) => {
  setIsLoading(true)
  e.preventDefault()
  // for full trip paload
  const params = {
    request: {
      bookingId: SingleData.merchant_orderId,
      remarks: RemarkText,
      type: "CANCELLATION",
    },
    bodyRequest: {
      tmt_order_id: SingleData.tmt_order_id,
      tmt_fees: {
        onward: TMTFEE * PassengerInfo.length,
        return: TMTFEE * PassengerInfo.length,
      },
      airline_cancel_fees: {
        onward: handleCharges(cancelFlightData?.amendmentInfo, "onwardAirFee"),
        return: handleCharges(cancelFlightReturnData?.amendmentInfo, "returnAirFee"),
      },
    },
  };

  setCancelPop(false);
  submit_amendment(params)
    .then((res) => {
      if (res?.data?.amendmentId) {
        setIsLoading(false)
        SuccessTost("cancellation Confirm");
        setShow(false);
        setAmendmentId(res?.data?.amendmentId);
        fullCancel(res?.data?.amendmentId);
        sectoreShow(false);
      }
    })
    .catch((er) => {
      setIsLoading(false)
      console.warn("res for final ", er);
    });
};


// const handleData = (pData, ind) => {
//   let newpass = [...removePass];
//   let { fN, lN } = pData;
//   newpass.push({ fN, lN });
//   setRemovePass(newpass);
// };
const handleData = (pData, ind) => {
  let existingIndex = removePass.findIndex(passenger => passenger.fN === pData.fN && passenger.lN === pData.lN);
  if (existingIndex !== -1) {
    // Passenger already exists in the array, remove it
    let newPassengers = [...removePass];
    newPassengers.splice(existingIndex, 1);
    setRemovePass(newPassengers);
  } else {
    // Passenger doesn't exist, add it
    setRemovePass([...removePass, { fN: pData.fN, lN: pData.lN }]);
  }
};


const fullCancel = (data) => {
  const params = {
    amendmentId: data,
  };
  amendment_details(params)
    .then((res) => {
      SuccessTost("Cancellation " + res?.data.amendmentStatus);
    })
    .catch((er) => {
      console.error("amendment details error", er);
    });
};


const submitCancellation = () => {
  // for full trip cancellation
  let params = {
    bookingId: SingleData?.merchant_orderId,
    type: "CANCELLATION",
  };

  if (cancelType.PassengerCencel === true) {
    setCancelPop(false)
    setPassengerShow(true);
  } else if (cancelType.TripCencel === true) {
    setCancelPop(false)
    setSectoreShow(true);
  } else if (cancelType.FullTripCencel === true) {
    fulltripCancellation(params);
  } else {
  }
};


const fulltripCancellation = (data) => {
  setIsLoading(true);

  get_amendment_data(data)
    .then((res) => {
      if (res?.data?.status?.httpStatus === 400) {
        ErrorTost(res?.data?.errors[0]?.message);
        setIsLoading(false);
        setCancelPop(false);
        setPassengerShow(false);
        setRemovePass("");
        setSectoreShow(false);
      }
      if (res?.data?.trips) {
        setSectoreShow(false);
        setIsLoading(false);
        setCancelPop(false);
        setShow(true);
        setRemovePass("");
      }
      setCancelFlight(res?.data?.trips);
      setCancelFlightData(res?.data?.trips && res?.data?.trips[0]);
      setCancelFlightReturnData(res?.data?.trips && res?.data?.trips[1]);
    })
    .catch((er) => {
      console.error(er);
    });
};

const handleTripCancellation = () => {
  // let params = {
  //   bookingId: SingleData?.merchant_orderId,
  //   type: "CANCELLATION",
  //   trips: [
  //     {
  //       src: tripdata?.data?.length > 0 && tripdata?.data[0]?.da?.code,
  //       dest:
  //         tripdata?.data?.length > 0 &&
  //         tripdata?.data[tripdata?.data?.length - 1]?.aa?.code,
  //       departureDate:
  //         tripdata?.data?.length > 0 &&
  //         moment(tripdata?.data[0]?.at).format("YYYY-MM-DD"),
  //     },
  //   ],
  // };
  let params = {
    bookingId: SingleData?.merchant_orderId,
    type: "CANCELLATION",
    trips: [
      {
        src: tripdata?.[0]?.da?.code,
        dest:
          tripdata?.length > 0 && tripdata[tripdata?.length - 1]?.aa?.code,
        departureDate:
          tripdata?.length > 0 &&
          moment(tripdata[0]?.at).format("YYYY-MM-DD"),
      },
    ],
  };
  console.log({ Data: params.bookingId });
  fulltripCancellation(params);
};


const handleCharges = (pax, flight) => {
  if (flight === "onward") {
    let A = pax?.ADULT?.amendmentCharges * totalAdult?.length;
    let C = pax?.CHILD
      ? pax?.CHILD?.amendmentCharges
      : 0 * totalChild?.length;
    let I = pax?.INFANT
      ? pax?.INFANT?.amendmentCharges
      : 0 * totalInfant?.length;
    let TMTFEE = SingleData?.trip_type === "DOMESTIC" ? 400 : 800;
    let totalP = PassengerInfo?.length * TMTFEE;
    const total = A + C + I + totalP;
    return total;
  }
  if (flight === "return") {
    let A = pax?.ADULT.amendmentCharges * totalAdult?.length;
    let C = pax?.CHILD ? pax?.CHILD.amendmentCharges : 0 * totalChild?.length;
    let I = pax?.INFANT
      ? pax?.INFANT.amendmentCharges
      : 0 * totalInfant?.length;
    let TMTFEE = SingleData?.trip_type === "DOMESTIC" ? 400 : 800;
    let totalP = PassengerInfo?.length * TMTFEE;
    const total = A + C + I + totalP;
    return total;
  }
  if (flight === "onwardAirFee") {
    let A = pax?.ADULT.amendmentCharges * totalAdult?.length;
    let C = pax?.CHILD ? pax?.CHILD.amendmentCharges : 0 * totalChild?.length;
    let I = pax?.INFANT
      ? pax?.INFANT.amendmentCharges
      : 0 * totalInfant?.length;
   
    const total = A + C + I;
    return total;
  }
  if (flight === "returnAirFee") {
    let A = pax?.ADULT.amendmentCharges * totalAdult?.length;
    let C = pax?.CHILD ? pax?.CHILD.amendmentCharges : 0 * totalChild?.length;
    let I = pax?.INFANT
      ? pax?.INFANT.amendmentCharges
      : 0 * totalInfant?.length;
   
    const total = A + C + I;
    return total;
  }
};

const handlePassengerCancellation = () => {
  setIsLoading(true);
  let params = {};
  if (sectoreInfoReturn?.length > 0 && sectoreInfoReturn !== undefined) {
    params = {
      bookingId: SingleData?.merchant_orderId,
      type: "CANCELLATION",
      trips: [
        {
          src: sectoreInfo[0].da.code,
          dest: sectoreInfo[sectoreInfo.length - 1]?.aa?.code,
          departureDate: moment(sectoreInfo[0]?.dt).format("YYYY-MM-DD"),
          travellers: removePass,
        },
        {
          src: sectoreInfoReturn.length > 0 && sectoreInfoReturn[0]?.da?.code,
          dest: sectoreInfoReturn[sectoreInfoReturn.length - 1]?.aa?.code,
          departureDate: moment(sectoreInfoReturn[0]?.dt).format(
            "YYYY-MM-DD"
          ),
          travellers: removePass,
        },
      ],
    };
  } else {
    params = {
      bookingId: SingleData?.merchant_orderId,
      type: "CANCELLATION",
      trips: [
        {
          src: sectoreInfo[0].da.code,
          dest: sectoreInfo[sectoreInfo.length - 1]?.aa?.code,
          departureDate: moment(sectoreInfo[0]?.dt).format("YYYY-MM-DD"),
          travellers: removePass,
        },
      ],
    };
  }

  fulltripCancellation(params);
};

const paxCalculate = () => {
  let totalAdult = PassengerInfo.filter((i) => i.pt === "ADULT");
  let totalChild = PassengerInfo.filter((i) => i.pt === "CHILD");
  let totalInfant = PassengerInfo.filter((i) => i.pt === "INFANT");
  setTotalAdult(totalAdult);
  setTotalChild(totalChild);
  setTotalInfant(totalInfant);
};
useEffect(() => {
  paxCalculate();
  handleCharges();
}, [PassengerInfo]);


// ======== refund failed api here ========//

const handldeRefundfailed = () =>{
  let param = {
  tmt_order_id : list?.tmt_order_id
  }
  refundFailed(param).then((res)=>{
  console.log('refund failed response',res);
}).catch((err)=>{
  
  console.log('refund failed Error',err);
  })
}

const handleExportDetails = async () =>{
  let params = {
    orderId : list?.tmt_order_id
    }
  const response = await axios.post(TICKES_DOWNLOAD,params, {
    responseType: 'blob', // Set responseType to 'blob' to handle binary data
  });

  console.log('Response pdf', response);
  const blob = new Blob([response.data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${list?.tmt_order_id}.pdf`; // Set the desired file name
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}
  return (
    <div className="userdetails">
      <Card
        className="Card"
        style={{
          // fontSize: "small",
          border: "1px solid #e1e1e1",
        }}
      >
        <Card.Header className=" mainColor">
          <h3 className="fs-28 fw-900 text-white">
              Order Details:
          </h3>
        </Card.Header>
        <Card.Body
          style={{
            border: "1px solid #e1e1e1",
          }}
        >
          {list && (
            <>
              <Card.Body
                style={{
                  border: "1px solid #e1e1e1",
                }}
              >
                <Row>
                  <Col lg={5} className="details">
                    TMT Order ID
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={5}>
                    {!list?.tmt_order_id ? "NA" : list?.tmt_order_id}
                  </Col>
                </Row>

                <Row>
                  <Col lg={5} sm={5} className="details">
                    TMT Merchant ID
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.tmt_merchant_id}
                  </Col>
                </Row>

                <Row>
                  <Col lg={5} sm={5} className="details">
                    Merchant Order ID
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.merchant_orderId ? "NA" : list?.merchant_orderId}
                  </Col>
                </Row>

                <Row>
                  <Col lg={5} sm={5} className="details">
                    TMT User ID
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.tmt_userIdf ? "NA" : list?.tmt_userIdf}
                  </Col>
                </Row>

                <Row>
                  <Col lg={5} sm={5} className="details">
                    Booking Date
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={5}>
                    {list?.booking_date
                      ? DateTimeFormat(list?.booking_date)
                      : "NA"}
                  </Col>
                </Row>

                <Row>
                  <Col lg={5} sm={5} className="details">
                    Booking Status
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={5}>
                    {list?.booking_status ? list?.booking_status : "NA"}
                  </Col>
                </Row>
              </Card.Body>
              <hr />
              {setPassenger}
              {passenger?.map((i, ind) => {
                return (
                  <>
                    <Card.Header>
                      <h5>
                        Traveller:
                        {ind + 1}
                      </h5>
                    </Card.Header>
                    <Card.Body
                      style={{
                        border: "1px solid #e1e1e1",
                      }}
                    >
                      <Row>
                        <Col lg={5} sm={5} className="details">
                          Name
                        </Col>{" "}
                        <Col lg={1}>:</Col>
                        <Col lg={6} sm={4}>
                          {i?.ti} {i?.fN} {i?.lN}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={5} sm={5} className="details">
                          Passenger Type
                        </Col>{" "}
                        <Col lg={1}>:</Col>
                        <Col lg={6} sm={4}>
                          {i?.pt}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={5} sm={5} className="details">
                          Date of birth
                        </Col>{" "}
                        <Col lg={1}>:</Col>
                        <Col lg={6} sm={4}>
                          {OnlyDateFormate(i?.dob)}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={5} sm={5} className="details">
                          Passenger Nationality
                        </Col>{" "}
                        <Col lg={1}>:</Col>
                        <Col lg={6} sm={4}>
                          {!i?.pNat ? "NA" : i?.pNat}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={5} sm={5} className="details">
                          Passport Number
                        </Col>{" "}
                        <Col lg={1}>:</Col>
                        <Col lg={6} sm={4}>
                          {!i?.pNum ? "NA" : i?.pNum}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={5} sm={5} className="details">
                          Passport Issue Date
                        </Col>{" "}
                        <Col lg={1}>:</Col>
                        <Col lg={6} sm={4}>
                          {!i?.pid ? "NA" : OnlyDateFormate(i?.pid)}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={5} sm={5} className="details">
                          Passport Expiry Date
                        </Col>{" "}
                        <Col lg={1}>:</Col>
                        <Col lg={6} sm={4}>
                          {!i?.eD ? "NA" : OnlyDateFormate(i?.eD)}
                        </Col>
                      </Row>
                    </Card.Body>
                  </>
                );
              })}
              <hr />
              <Card.Body
                style={{
                  border: "1px solid #e1e1e1",
                }}
              >
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Fare Type
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.fare_type ? "NA" : list?.fare_type}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Order Type
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.order_type ? "NA" : list?.order_type}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Booking Type
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.booking_type ? "NA" : list?.booking_type}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Trip Type
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_type ? "NA" : list?.trip_type}
                  </Col>
                </Row>
              </Card.Body>
              {/* GST INFORMATION */}
              <hr />
              <Card.Header>
                <h5>
                  <b>GST Information</b>
                </h5>
              </Card.Header>
              <Card.Body
                style={{
                  border: "1px solid #e1e1e1",
                }}
              >
                <Row>
                  <Col lg={5} sm={5} className="details">
                    GST State
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.gstInfo?.address
                      ? "NA"
                      : list?.trip_info?.gstInfo?.address}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    GST Email
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={5}>
                    {list?.trip_info?.gstInfo?.email
                      ? list?.trip_info?.gstInfo?.email
                      : "NA"}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    GST Number
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.gstInfo?.gstNumber
                      ? "NA"
                      : list?.trip_info?.gstInfo?.gstNumber}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    GST Mobile
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.gstInfo?.mobile
                      ? "NA"
                      : list?.trip_info?.gstInfo?.mobile}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    GST Registered Name
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.gstInfo?.registeredName
                      ? "NA"
                      : list?.trip_info?.gstInfo?.registeredName}
                  </Col>
                </Row>
              </Card.Body>
              <hr />
              {list?.trip_info.sector_details?.map((i, index) => {
                return (
                  <>
                    <Card.Header>
                      <h5>
                        <b>Trip Information - {index + 1}</b>
                      </h5>
                    </Card.Header>
                    <Card.Body
                      style={{
                        border: "1px solid #e1e1e1",
                      }}
                    >
                      {i?.sI?.map((item,sg_index) => {
                        return (
                          <>
                            <Card.Header>
                              <h6>
                                <label> Segment : {(sg_index + 1)}</label>
                              </h6>
                            </Card.Header>
                            <Card.Body
                              style={{
                                border: "1px solid #e1e1e1",
                              }}
                            >
                              <Row>
                                <Col lg={5} sm={5} className="details">
                                  PNR
                                </Col>{" "}
                                <Col lg={1}>:</Col>
                                <Col lg={6} sm={5}>
                                  {list?.pnr_details.length <= 0
                                    ? "NA"
                                    : PNR_Data[index]}
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={5} sm={5} className="details">
                                  Airline Details
                                </Col>{" "}
                                <Col lg={1}>:</Col>
                                <Col lg={6} sm={4}>
                                  {item?.fD?.aI?.name} ({item?.fD?.aI?.code}-
                                  {item?.fD?.fN} → {item?.fD?.eT})
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={5} sm={5} className="details">
                                  Depart Airport & Code
                                </Col>
                                <Col lg={1}>:</Col>
                                <Col lg={6} sm={4}>
                                  {item?.da?.name} ({item?.da?.code})
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={5} sm={5} className="details">
                                  Depart City Code
                                </Col>{" "}
                                <Col lg={1}>:</Col>
                                <Col lg={6} sm={4}>
                                  {item?.da?.cityCode}
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={5} sm={5} className="details">
                                  Arrival Airport & Code
                                </Col>{" "}
                                <Col lg={1}>:</Col>
                                <Col lg={6} sm={4}>
                                  {item?.aa?.name} ({item?.aa?.code})
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={5} sm={5} className="details">
                                  Arrival City Code
                                </Col>{" "}
                                <Col lg={1}>:</Col>
                                <Col lg={6} sm={4}>
                                  {item?.aa?.cityCode}
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={5} sm={5} className="details">
                                  Departure Date & Time
                                </Col>{" "}
                                <Col lg={1}>:</Col>
                                <Col lg={6} sm={4}>
                                  {DateTimeFormat(item?.dt)}
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={5} sm={5} className="details">
                                  Arrival Date & Time
                                </Col>{" "}
                                <Col lg={1}>:</Col>
                                <Col lg={6} sm={4}>
                                  {DateTimeFormat(item?.at)}
                                </Col>
                              </Row>
                            </Card.Body>
                          </>
                        );
                      })}
                    </Card.Body>
                  </>
                );
              })}
              <hr />
              <Card.Body
                style={{
                  border: "1px solid #e1e1e1",
                }}
              >
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Customer Mobile Contact
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={5}>
                    {!list?.trip_info?.deliveryInfo?.contacts
                      ? "NA"
                      : list?.trip_info?.deliveryInfo?.contacts}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Customer Email
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.deliveryInfo?.emails
                      ? "NA"
                      : list?.trip_info?.deliveryInfo?.emails}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Booking Platform
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.platform
                      ? "NA"
                      : list?.trip_info?.platform}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Header>
                <h5>
                  <b>Fare Componenet</b>
                </h5>
              </Card.Header>
              <Card.Body
                style={{
                  border: "1px solid #e1e1e1",
                }}
              >
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Base Fare
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.totalPriceInfo?.totalFareDetail?.fC?.BF
                      ? "NA"
                      : list?.trip_info?.totalPriceInfo?.totalFareDetail?.fC
                          ?.BF}
                  </Col>
                </Row>
                <Row onClick={handleTaxes}>
                  <Col lg={5} sm={5} className="details">
                    {showTax === false ? (
                      <IoMdArrowDropright />
                    ) : (
                      <IoMdArrowDropdown />
                    )}{" "}
                    Taxes and Fees
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {totalFareCalculation(list)}
                  </Col>
                </Row>
                {showTax && (
                  <div className="mx-3">
                    <Row>
                      <Col lg={5} sm={5} className="details text-muted">
                        OT Tax
                      </Col>{" "}
                      <Col lg={1}>:</Col>
                      <Col lg={6} sm={4} className="text-muted">
                        {!list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC
                          ?.TAF?.OT
                          ? "NA"
                          : list?.trip_info?.totalPriceInfo?.totalFareDetail
                              ?.afC?.TAF?.OT}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5} sm={5} className="details text-muted">
                        Airline GST Component
                      </Col>{" "}
                      <Col lg={1}>:</Col>
                      <Col lg={6} sm={4} className="text-muted">
                        {!list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC
                          ?.TAF?.AGST
                          ? "NA"
                          : list?.trip_info?.totalPriceInfo?.totalFareDetail
                              ?.afC?.TAF?.AGST}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5} sm={5} className="details text-muted">
                        Fuel Surcharge
                      </Col>{" "}
                      <Col lg={1}>:</Col>
                      <Col lg={6} sm={4} className="text-muted">
                        {!list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC
                          ?.TAF?.YQ
                          ? "NA"
                          : list?.trip_info?.totalPriceInfo?.totalFareDetail
                              ?.afC?.TAF?.YQ}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5} sm={5} className="details text-muted">
                        Management Fee
                      </Col>{" "}
                      <Col lg={1}>:</Col>
                      <Col lg={6} sm={4} className="text-muted">
                        {!list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC
                          ?.TAF?.MF
                          ? "NA"
                          : list?.trip_info?.totalPriceInfo?.totalFareDetail
                              ?.afC?.TAF?.MF}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5} sm={5} className="details text-muted">
                        Management Fee Tax
                      </Col>{" "}
                      <Col lg={1}>:</Col>
                      <Col lg={6} sm={4} className="text-muted">
                        {!list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC
                          ?.TAF?.MFT
                          ? "NA"
                          : list?.trip_info?.totalPriceInfo?.totalFareDetail
                              ?.afC?.TAF?.MFT}
                      </Col>
                    </Row>
                  </div>
                )}
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Mark Up
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC?.TAF
                      ?.MU
                      ? "NA"
                      : list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC
                          ?.TAF?.MU}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Net Fare
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.totalPriceInfo?.totalFareDetail?.fC?.NF
                      ? "NA"
                      : list?.trip_info?.totalPriceInfo?.totalFareDetail?.fC
                          ?.NF}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Gross Commission
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.gross_commission === undefined
                      ? "NA"
                      : list?.trip_info?.gross_commission}
                  </Col>
                </Row>

                <Row>
                  <Col lg={5} sm={5} className="details">
                    Tax Deducted at Source(TDS)
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.tds === undefined
                      ? "NA"
                      : list?.trip_info?.tds}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Net Commission
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.net_commission === undefined
                      ? "NA"
                      : list?.trip_info?.net_commission}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Total Fare
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.totalPriceInfo?.totalFareDetail?.fC
                      ?.TF === undefined
                      ? "NA"
                      : list?.trip_info?.totalPriceInfo?.totalFareDetail?.fC
                          ?.TF}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Total Fare (After PromoCode / Promocash)
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.sales === undefined
                      ? "NA"
                      : list?.trip_info?.sales}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Convenience Fees
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.convenience_fee === undefined
                      ? "NA"
                      : list?.trip_info?.convenience_fee}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Net Margin
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.net_margin === undefined
                      ? "NA"
                      : list?.trip_info?.net_margin}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Taxable Amount
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.taxable_value === undefined
                      ? "NA"
                      : parseFloat(list?.trip_info?.taxable_value).toFixed(2)}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    IGST (Sales)
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.IGST_sales?.amount === undefined
                      ? "NA / Invoice not generated"
                      : parseFloat(list?.trip_info?.IGST_sales?.amount).toFixed(
                          2
                        )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    CGST (Sales)
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.CGST_sales?.amount === undefined
                      ? "NA / Invoice not generated"
                      : parseFloat(list?.trip_info?.CGST_sales?.amount).toFixed(
                          2
                        )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    SGST (Sales)
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.SGST_sales?.amount === undefined
                      ? "NA / Invoice not generated"
                      : parseFloat(list?.trip_info?.SGST_sales?.amount).toFixed(
                          2
                        )}
                  </Col>
                </Row>
                {/* <Row>
                  <Col lg={5} sm={5} className="details">
                    Carrier Misc Fee
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC?.TAF
                      ?.YR === undefined
                      ? "NA"
                      : list?.trip_info?.totalPriceInfo?.totalFareDetail?.afC
                          ?.TAF?.YR}
                  </Col>
                </Row>
                 */}
              </Card.Body>
              <Card.Header>
                <h5>
                  <b>Offers</b>
                </h5>
              </Card.Header>
              <Card.Body
                style={{
                  border: "1px solid #e1e1e1",
                }}
              >
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Offer Code
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.offer?.code
                      ? "NA"
                      : list?.trip_info?.offer?.code}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Offer Amount
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.offer?.amountoff
                      ? "NA"
                      : list?.trip_info?.offer?.amountoff}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Promocash Amount
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.offer?.promocash === undefined ||
                    list?.trip_info?.offer?.promocash === null
                      ? 0
                      : list?.trip_info?.offer?.promocash}
                  </Col>
                </Row>
              </Card.Body>

              <hr />
              <Card.Header>
                <h5>
                  <b>Payment Gateway Charges</b>
                </h5>
              </Card.Header>
              <Card.Body
                style={{
                  border: "1px solid #e1e1e1",
                }}
              >
                {/* <Row>
                  <Col lg={5} sm={5} className="details">
                    PG Order ID
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.trip_info?.pg_order_id
                      ? "NA"
                      : list?.trip_info?.pg_order_id}
                  </Col>
                </Row> */}
                <Row>
                  <Col lg={5} sm={5} className="details">
                    PG Name
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.pg_name === undefined
                      ? "NA"
                      : list?.trip_info?.pg_name}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    PG Payment ID
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.pg_payment_id === undefined
                      ? "NA"
                      : list?.trip_info?.pg_payment_id}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    PG Payment Method
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.pg_payment_method === undefined
                      ? "NA"
                      : list?.trip_info?.pg_payment_method}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    PG Percent Charge
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.pg_percent_charge === undefined
                      ? "NA"
                      : list?.trip_info?.pg_percent_charge}
                    %
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    PG Amount Charge
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.pg_amount_charge === undefined
                      ? "NA"
                      : list?.trip_info?.pg_amount_charge}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    PG GST
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.pg_gst === undefined
                      ? "NA"
                      : list?.trip_info?.pg_gst}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    PG Net Amount Received
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.pg_net_receive === undefined
                      ? "NA"
                      : list?.trip_info?.pg_net_receive}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    PG Payment Status
                  </Col>
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {list?.trip_info?.pg_payment_status === undefined
                      ? "NA"
                      : list?.trip_info?.pg_payment_status}
                  </Col>
                </Row>
              </Card.Body>
              <hr />
              <Card.Header>
                <h5>
                  <b>Further Details</b>
                </h5>
              </Card.Header>
              <Card.Body
                style={{
                  border: "1px solid #e1e1e1",
                }}
              >
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Further Action
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.further_action ? "NA" : list?.further_action}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    TMT Cancellation Fees
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    Onward:
                    {list?.tmt_fees === undefined
                      ? "NA"
                      : list?.tmt_fees.onward}
                    return:
                    {list?.tmt_fees === undefined
                      ? "NA"
                      : list?.tmt_fees.return}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Airline Cancellation Fees
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    Onward:
                    {list?.airline_cancel_fees === undefined
                      ? "NA"
                      : list?.airline_cancel_fees.onward}
                    Return:{" "}
                    {list?.airline_cancel_fees === undefined
                      ? "NA"
                      : list?.airline_cancel_fees.return}
                  </Col>
                </Row>

                <Row>
                  <Col lg={5} sm={5} className="details">
                    Amendment ID
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.amendmentId ? "NA" : list?.amendmentId}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Amendment Status
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.amendmentStatus ? "NA" : list?.amendmentStatus}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Refund ID
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.refundId ? "NA" : list?.refundId}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Refund Amount
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.refund_amount ? "NA" : list?.refund_amount}
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} sm={5} className="details">
                    Refund Status
                  </Col>{" "}
                  <Col lg={1}>:</Col>
                  <Col lg={6} sm={4}>
                    {!list?.refund_status ? "NA" : list?.refund_status}
                  </Col>
                </Row>
              </Card.Body>
      <Row>
        <Col><CustomButton disabled={isCancellation} onClick={handleCancellation} buttonText={'Cancellation'} /> </Col>
        <Col>{list?.booking_status === 'FAILED' && <CustomButton  disabled={list?.booking_status !== 'FAILED'} buttonText={'refund'} onClick={handldeRefundfailed} />}</Col>
        <Col><CustomButton buttonText={'Print Ticket'} onClick={handleExportDetails} /></Col>
      </Row>
            </>
          )}
          {!list && <div>something went wrong</div>}
        </Card.Body>
      </Card>
      <Modal show={cancelPop} onHide={() => setCancelPop(false)} centered>
        <div>

          <Modal.Header>Select Cancelation Type</Modal.Header>
        </div>
          <Modal.Body>
            <Container>
              <Row className="">
                <Col className="mb-3">
                  <p className="  d-inline-flex fw-600 fs-14">Type:</p>{" "}
                  Cancellation
                </Col>
                <Col className="mb-3">
                  <p className="d-inline fw-600 fs-14">TMT Order ID:</p>{" "}
                  { list?.tmt_order_id}
                </Col>
              </Row>
              <Row className="">
                <Col sclassName="mb-3">
                  <Form.Check
                    inline
                    label="Full Cancellation"
                    type={"radio"}
                    name="FullTripCencel"
                    value={cancelType?.FullTripCencel}
                    className="mx-2"
                    onChange={() => handleCancellationChange("FullTripCencel")}
                    checked={cancelType?.FullTripCencel === true}
                  />
                  {passenger.length > 1 && (
                    <>
                      <Form.Check
                        inline
                        value={cancelType?.PassengerCencel}
                        name="PassengerCencel"
                        label="Passenger Cancellation"
                        onChange={() => handleCancellationChange("PassengerCencel")}
                        className="mx-2"
                        checked={cancelType?.PassengerCencel === true}
                        type={"radio"}
                      />
                    </>
                  )}

                  {sectoreInfoReturn?.length > 0 && (
                    <>
                      <Form.Check
                        value={cancelType?.TripCencel}
                        name="TripCencel"
                        onChange={() => handleCancellationChange("TripCencel")}
                        inline
                        className="mx-2"
                        label="Trip Cancellation"
                        checked={cancelType?.TripCencel === true}
                        type={"radio"}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton buttonText={'Close'} size="sm" variant="secondary" onClick={() => setCancelPop(false)} />
              
            
          
            <CustomButton 
              disabled={isLoading}
              type="submit"
              onClick={submitCancellation}
            buttonText={isLoading ? "Loading..." : "Cancellation"}
            />
          </Modal.Footer>
        </Modal>
        
{/* admendment Charges Modal */}

        <Modal show={show} onHide={handleShow}  centered>
          <Form onSubmit={submitAmendment}>
            {/* <Modal.Header >
                    <Modal.Title></Modal.Title>
                  </Modal.Header> */}
                  <div className="mainColor py-3 text-white">

                  
            <Container  >
              <Row >
                <Col className="d-flex align-items-center">
                  <h6 className=" fs-18   mb-0 fw-700 fontpop">Cancelation Details</h6>
                </Col>
                <Col className="d-flex justify-content-center">
                  {/* {SingleData.merchant_orderId}  */}
                  {SingleData.tmt_order_id}
                </Col>
              </Row>
            </Container>
            </div>
            <Container className="">
              <hr className="my-0" />
              <Row className="my-2" onClick={hancleFare}>
                <Col sm={3}>
                  <label className="fs-14 fw-400">
                    Depart : {cancelFlightData?.src}
                  </label>
                </Col>
                <Col sm={3}>
                  <label className="fs-14 fw-400">
                    {" "}
                    Arrival : {cancelFlightData?.dest}
                  </label>
                </Col>
                <Col lg={5}>
                <label className="fs-14 fw-400">

                  Onward charges :{" "}
                  {handleCharges(cancelFlightData?.amendmentInfo, "onward")}
                </label>
                </Col>
                <Col>
                  {" "}
                  {!fareShow ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                </Col>
              </Row>
              {fareShow && (
              <>
              <hr />
                <Container className="modalBody">
                  <Row>
                    <Col lg={3}>
                      <h6 className="fs-14 fw-700 fontpop">ADULT * </h6>
                    </Col>
                    {/* </Row>
              <Row className="my-2"> */}
                    <Col>
                      <label className="fs-14 fw-400"> Airlines Fees : </label>
                      <label className="fs-14 fw-400">Treatmytravel fees :</label>
                    </Col>
                    <Col>
                      <h6 className="fs-14 fw-400">
                        {
                          cancelFlightData?.amendmentInfo?.ADULT
                            ?.amendmentCharges
                        }{" "}
                        × {totalAdult && totalAdult?.length}
                      </h6>
                      <h6 className="fs-14 fw-400">
                        400 × {totalAdult?.length}
                        {/* {(cancelFlightData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}
                      </h6>
                    </Col>
                  </Row>
                  {cancelFlightData?.amendmentInfo?.CHILD && (
                    <>
                      <Row>
                        <Col lg={3}>
                          <h6 className="fs-14 fw-700 fontpop">CHILD * </h6>
                        </Col>
                        {/* </Row>
                  <Row className="my-2"> */}
                        <Col>
                          <label className="fs-14 fw-400"> Airlines Fees : </label>
                          <label className="fs-14 fw-400">
                            Treatmytravel fees :
                          </label>
                        </Col>
                        <Col>
                          <h6 className="fs-14 fw-400">
                            {
                              cancelFlightData?.amendmentInfo?.CHILD
                                ?.amendmentCharges
                            }{" "}
                            × {totalChild && totalChild.length}
                          </h6>
                          <h6 className="fs-14 fw-400">
                            400 × {totalChild && totalChild.length}
                            {/* {(cancelFlightData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}
                          </h6>
                        </Col>
                      </Row>
                    </>
                  )}

                  {cancelFlightData?.amendmentInfo?.INFANT && (
                    <>
                      <Row>
                        <Col lg={3}>
                          <h6 className="fs-14 fw-700 fontpop">INFANT * </h6>
                        </Col>
                        {/* </Row>
                  <Row className="my-2"> */}
                        <Col>
                          <label className="fs-14 fw-400"> Airlines Fees : </label>
                          <label className="fs-14 fw-400">
                            Treatmytravel fees :
                          </label>
                        </Col>
                        <Col>
                          <h6 className="fs-14 fw-400">
                            {
                              cancelFlightData?.amendmentInfo?.INFANT
                                ?.amendmentCharges
                            }{" "}
                            × {totalInfant && totalInfant?.length}
                          </h6>
                          <h6 className="fs-14 fw-400">
                            400
                            {/* {(cancelFlightData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}{" "}
                            × {totalInfant?.length}
                          </h6>
                        </Col>
                      </Row>
                    </>
                  )}
                </Container>
                </>
              )}
              {cancelFlight?.length > 1 && (
                <>
                  <hr />
                  <Row className="my-2" onClick={hancleFareReturn}>
                    <Col sm={3}>
                      <label className="secoreHeading">
                        Depart : {cancelFlightReturnData?.src}
                      </label>
                    </Col>
                    <Col sm={3}>
                      <label className="secoreHeading">
                        {" "}
                        Arrival : {cancelFlightReturnData?.dest}
                      </label>
                    </Col>
                    <Col lg={5}>
                      Return charges :{" "}
                      {handleCharges(
                        cancelFlightReturnData?.amendmentInfo,
                        "return"
                      )}
                    </Col>
                    <Col>
                      {!fareShowReturn ? (
                        <IoMdArrowDropdown />
                      ) : (
                        <IoMdArrowDropup />
                      )}
                    </Col>
                  </Row>
                  <hr />
                  {fareShowReturn && (
                    <Container className="modalBody">
                      <Row>
                        <Col lg={3}>
                          <h6 className="mb-0">ADULT * </h6>
                        </Col>
                        {/* </Row>
                  <Row className="my-2"> */}
                        <Col>
                          <label className="fs-14 fw-400"> Airlines Fees : </label>
                          <label className="fs-14 fw-400">
                            Treatmytravel fees :
                          </label>
                        </Col>
                        <Col>
                          <h6 className="fs-14 fw-400">
                            {
                              cancelFlightReturnData?.amendmentInfo?.ADULT
                                ?.amendmentCharges
                            }{" "}
                            × {totalAdult && totalAdult?.length}
                          </h6>
                          <h6 className="fs-14 fw-400">
                            400 × 1
                            {/* {(cancelFlightReturnData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}
                          </h6>
                        </Col>
                      </Row>

                      {cancelFlightReturnData?.amendmentInfo?.CHILD && (
                        <>
                          <Row>
                            <Col lg={3}>
                              <h6>CHILD * </h6>
                            </Col>
                            {/* </Row>
                      <Row className="my-2"> */}
                            <Col>
                              <label className="fs-14 fw-400">
                                {" "}
                                Airlines Fees :{" "}
                              </label>
                              <label className="fs-14 fw-400">
                                Treatmytravel fees :
                              </label>
                            </Col>
                            <Col>
                              <h6 className="fs-14 fw-400">
                                {
                                  cancelFlightReturnData?.amendmentInfo?.CHILD
                                    ?.amendmentCharges
                                }{" "}
                                × {totalChild && totalChild.length}
                              </h6>
                              <h6 className="fs-14 fw-400">
                                400 × {totalChild.length}
                                {/* {(cancelFlightReturnData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}
                              </h6>
                            </Col>
                          </Row>
                        </>
                      )}

                      {cancelFlightReturnData?.amendmentInfo?.INFANT && (
                        <>
                          <Row>
                            <Col lg={3}>
                              <h6>INFANT * </h6>
                            </Col>
                            {/* </Row>
                      <Row className="my-2"> */}
                            <Col>
                              <label className="fs-14 fw-400">
                                {" "}
                                Airlines Fees :{" "}
                              </label>
                              <label className="fs-14 fw-400">
                                Treatmytravel fees :
                              </label>
                            </Col>
                            <Col>
                              <h6 className="fs-14 fw-400">
                                {
                                  cancelFlightReturnData?.amendmentInfo?.INFANT
                                    ?.amendmentCharges
                                }{" "}
                                × {totalInfant && totalInfant?.length}
                              </h6>
                              <h6 className="fs-14 fw-400">
                                400
                                {/* {(cancelFlightReturnData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}{" "}
                                × {totalInfant && totalInfant?.length}
                              </h6>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Container>
                  )}
                </>
              )}
              {/* <Row className="my-2">
                      <Col>
                        <label> total fare :{cancelFlightReturnData?.amendmentInfo?.ADULT?.totalFare}</label>
                      </Col>

                    </Row> */}
              <hr />

              <Row>
                <Col>
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Remark"
                    className="mb-3"
                  >
                    <Form.Control
                      as="textarea"
                      name="RemarkText"
                      value={RemarkText}
                      onChange={(e) => setRemarkText(e.target.value)}
                      placeholder="Leave a comment here"
                      required
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="my-1">
                <Col  className="d-flex ">
                  <Form.Check
                    size={"sm"}
                    className="fs-12 fw-400  mx-1"
                    required
                  />
                  <label className="fs-12 fw-400">
                    By clicking on ‘Submit’, you understand and agree to the
                    rules and restrictions of this fare, the
                    <TabLink href={"/termsofservice"}>
                      {" "}
                      Terms of Service
                    </TabLink>
                    , the <TabLink href={"/termsofuse"}>Terms of Use </TabLink>,
                    and the
                    <TabLink href={"/privacypolicy"}>
                      {" "}
                      Privacy and Cookie Policy
                    </TabLink>{" "}
                    of TreatMyTravel.
                  </label>
                </Col>
              </Row>
            </Container>
            <Modal.Footer>
              <Button size="lg" variant="secondary" onClick={handleClose}>
                Close
              </Button>
              {/* <Button
                variant="primary"
                type="submit"
                disabled={RemarkText?.length < 5}
                // onClick={submitAmendment}
              >
                Submit
              </Button> */}
              <CustomButton
              buttonText={isLoading ? "Loading..." : "Submit"}
              // buttonText={"Submit"} 
                disabled={RemarkText?.length < 5 || isLoading}
                // onClick={submitAmendment}
                type={"submit"}
              />
            </Modal.Footer>
          </Form>
        </Modal>

                

                
                <Modal show={sectoreShow} onHide={handleSectoreClose}>
                  <Modal.Header>Select Trip</Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Col>
                        <div className="d-flex">
                          <h6>
                            {sectoreInfo && sectoreInfo[0]?.da?.code} -{" "}
                            {sectoreInfo && sectoreInfo[sectoreInfo?.length - 1]?.aa?.code}
                          </h6>
                          <div className="mx-3 mb-2">
                            <Form.Check // prettier-ignore
                              type={"checkbox"}
                              checked={selectedTrip?.oneway === true}
                      value={selectedTrip?.oneway}
                              onChange={() =>
                                handleSectoreDetails(sectoreInfo, "oneway")
                              }
                            />
                          </div>
                        </div>
                        {SingleData?.trip_info?.sector_details.length > 1 && (
                          <>
                            <div className="d-flex">
                              <h6>
                                {sectoreInfoReturn[0]?.da?.code} -{" "}
                                {
                                  sectoreInfoReturn[
                                    sectoreInfoReturn.length - 1
                                  ]?.aa?.code
                                }
                              </h6>
                              <div className="mx-3 mb-2">
                                <Form.Check // prettier-ignore
                                  type={"checkbox"}
                                  checked={selectedTrip?.roundtrip === true}
                          value={selectedTrip?.roundtrip}
                                  onChange={() =>
                                    handleSectoreDetails(
                                      sectoreInfoReturn,
                                      "return"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </Col>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSectoreClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleTripCancellation}>
                      Process To Cancellation
                    </Button>
                  </Modal.Footer>
                </Modal>

{/* passenger cancellation modal */}
                <Modal show={passengerShow} onHide={handlePassengerClose}>
          <Modal.Header>Select Passenger</Modal.Header>
          <Modal.Body>
            <Container>
              {PassengerInfo?.map((items, k) => {
                return (
                  <>
                    <Row className="my-2">
                      <Col>
                        <h6 className="d-flex">
                          {items?.ti} {items?.fN} {items?.lN} ({items?.pt}){" "}
                        </h6>
                      </Col>
                      <Col sm={2}>
                        <Form.Check
                          aria-label="option 1"
                          onChange={() => handleData(items, k)}
                          value={removePass}
                        />
                      </Col>
                    </Row>
                  </>
                );
              })}
            </Container>
          </Modal.Body>
          <Modal.Footer>
          <Row lg={8}>
            <Col>

            <Button variant="secondary" onClick={handlePassengerClose}>
              Close
            </Button>
            </Col>
            <Col>

            {/* <CustomButton
            type={'submit'}
            buttonText={isLoading ? "Loading..." : "Process To cancel"}
            disabled={isLoading}
              onClick={handlePassengerCancellation}

             /> */}
            <Button
              variant="primary"
              disabled={removePass.length ===  0 ||  isLoading}
              onClick={handlePassengerCancellation}
            >
              {isLoading ? "Loading..." : "Process To cancel"}
            </Button>
            </Col>
          </Row>
          </Modal.Footer>
        </Modal>
    </div>
  );
};

export default OrderDetailsList;
