import React from "react";
import AddAirportForm from "./addAirportForm";
import DataTable from "react-data-table-component";
import {
  deleteAirport,
  getAirport,
  getSingleAirport,
} from "../../services/authServices";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../../components/button/button";
import { Link, useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import MainTitle from "../../components/mainTitile/mainTitle";
import { customStyles } from "../../utils/commanFile";

const Airport = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const getAirpostData = () => {
    setIsLoading(true);
    getAirport()
      .then((res) => {
        setData(res?.data?.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(true);
      });
  };
  useEffect(() => {
    getAirpostData();
  }, []);

  const deleteHolidataData = (id) => {
    deleteAirport(id)
      .then((res) => {
        if (res) {
          getAirpostData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("airport id here delete", id);
  };

  const columns = [
    {
      name: "code",
      selector: (row) => row?.code,
    },
    {
      name: "Airport name",
      selector: (row) => row?.name,
    },
    {
      name: "citycode",
      selector: (row) => row?.citycode,
    },
    {
      name: "city",
      selector: (row) => row?.city,
    },
    {
      name: "country",
      selector: (row) => row?.country,
    },
    {
      name: "countrycode",
      selector: (row) => row?.countrycode,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex">
          <div className="mx-2">
            <Link to={`/AddAirportForm/${row?._id}`}>
              {" "}
              <FiEdit color="green" size={20} />{" "}
            </Link>
          </div>
          <div>
            <AiFillDelete
              color="red"
              onClick={() => deleteHolidataData(row?._id)}
              style={{ cursor: "pointer" }}
              size={20}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col className="d-flex justify-content-end align-items-center">
          <MainTitle heading={"Airport List"} />
        </Col>
        <Col className="d-flex justify-content-end">
          <CustomButton
            buttonText={"Add"}
            onClick={() => {
              navigate("/AddAirportForm");
            }}
          />
        </Col>
      </Row>
      <DataTable
        progressPending={isLoading}
        columns={columns}
        customStyles={customStyles}
        dense
        data={data}
        pagination
      />
    </div>
  );
};

export default Airport;
