import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./cms.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiFillEdit, AiFillEye, AiFillFileAdd } from "react-icons/ai";
import { Cms_data_get, Cms_data_post } from "../../services/authServices";
import { useDispatch } from "react-redux";
import { CMSTypes } from "../../redux/authSlice";
import CustomButton from "../../components/button/button";

function CMS() {
  const [title, setTitle] = useState([]);
  // const [data, setData] = useState([]);
  const [heading, setHeading] = useState({
    title: "",
    cmsType: "",
    metaTitle: "",
    metaKeyword: "",
    metaDescription: "",
    content: "",
  });
  const [isLoading, setLoading] = useState(false);
  const { updatedAt } = useParams();
  const getTerms = async () => {
    setLoading(false);
    try {
      const res = await Cms_data_get();
      setTitle(res?.data?.Data);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const addData = () => {
    localStorage.setItem("CmsFlag", false);
    localStorage.removeItem("data");
    navigate("/cmsForm");
  };

  const handleClick = () => {
    navigate(`/cms`);
  };
  const OnlyDateFormate = (data) => {
    const d = new Date(data);
    let Data = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
    return Data;
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlepass = (data) => {
    localStorage.setItem("CmsFlag", true);
    const path = `/CmsForm`;
    dispatch(CMSTypes(data));
    navigate(path);

    // navigate('/careerForm')
  };
  const handleview = (view) => {
    // localStorage.setItem("CmsFlag",)
    const display = `/cmsView`;
    dispatch(CMSTypes(view));
    navigate(display);
  };
  useEffect(() => {
    getTerms();
  }, []);
  return (
    <>
      <div>
        <Container className="add">
          <CustomButton buttonText={"Add CMS"} onClick={addData} />
        </Container>
        <Card className="cms">
          <Row className="mx-3 line">
            <Col lg={3}>Title</Col>
            <Col lg={4}>Description</Col>
            <Col lg={2}>updated Date</Col>
            <Col lg={3} className="justify-content-center d-flex">
              Action
            </Col>
          </Row>
          {title?.map((i) => {
            return (
              <Row className="mx-3 brd" key={i.name}>
                <Col lg={3} className="fs-14">{i.title}</Col>
                <Col
                  lg={4}
                  className="d-flex fs-14"
                  dangerouslySetInnerHTML={{
                    __html: i.metaDescription.slice(0, 19) + "...",
                  }}
                />

                <Col lg={2} className="fs-14">{OnlyDateFormate(i.updatedAt)}</Col>
                <Col lg={3} className="fs-14 justify-content-center d-flex">
                  <div onClick={() => handlepass(i)}>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#281b75",
                        border: "1px solid #281b75",
                        padding: "8px",
                      }}
                    >
                      <AiFillEdit style={{ marginBottom: "7px" }} />
                      Edit
                    </Link>
                  </div>
                  <div className="mx-3" onClick={() => handleview(i)}>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#281b75",
                        border: "1px solid #281b75",
                        padding: "8px",
                      }}
                    >
                      <AiFillEye style={{ margintop: "9px" }} />
                      view
                    </Link>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Card>
      </div>
    </>
  );
}

export default CMS;
