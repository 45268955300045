import React from "react";
import { Container, Form } from "react-bootstrap";
import { useState } from "react";
import * as Yup from "yup";
import CustomButton from "../../../components/button/button";
import MainTitle from "../../../components/mainTitile/mainTitle";
import { addAdmin, adminById, adminEdit } from "../../../services/authServices";
import { useNavigate, useParams } from "react-router-dom";
import "./adminForm.css";
import CustomLoader from "../../../components/loader/loader";
import { useEffect } from "react";
import { ErrorTost } from "../../../components/tostpop/tostPop";

const AdminForm = () => {
  const { id } = useParams();

  console.log('admin id here',id);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    roleIdf: 1,
    image: "",
  });
  const [BodyData, setBodyData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e) => {

    e.preventDefault();
    if(id !== undefined){
      const res = await adminEdit(id, formData);
      navigate("/adminList");
    }else{
      getFormData(user);
      // update api here
    }
    // setLoading(true);
  };
  // Regular expression for email
  const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  
  //Form values
  const formData = new FormData();
  formData.append("firstname", user.firstname);
  formData.append("lastname", user.lastname);
  formData.append("email", user.email);
  formData.append("password", user.password);
  formData.append("roleIdf", user.roleIdf);
  formData.append("image", user.image);
  
  const getFormData = async (params) => {
    try {
      const res = await addAdmin(formData);
      setBodyData(res);
      if(res?.data?.status === 200){
        navigate("/adminList");
      }
    } catch (error) {
      console.log('asdasdasdasdasdas',error);
      console.error(error.Message);
      ErrorTost(error.error.Message)
    }
  };

  // For accepting the data from the form
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  // Image functionality
  const onChangePicture = (e) => {
    setUser({
      ...user,
      image: e.target.files[0],
    });
  };

  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const getAdminDetaisById = async () => {
    try {
      const res = await adminById(id);
      setUser(res.data.Data);
      console.log('admin details from reponse',res.data.Data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleEdit = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(()=>{
    if(id !== undefined){
      getAdminDetaisById()
    }
  },[id])

  return (
    <div>
      <CustomLoader isLoading={isLoading} />
      <Container className="d-flex justify-content-center">
        <Form onSubmit={handleSubmit} className="userForm">
          <MainTitle heading={"Admin Form"} textAlign={"center"} />

          <Form.Group className="mb-2">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              // pattern=""
              placeholder="john"
              autoComplete="off"
              name="firstname"
              value={user.firstname}
              onChange={(e) => handleChange(e)}
            />
            {user.firstname === " " ? <p></p> : null}
            {/* {formik.touched.firstname && formik.errors.firstname ? (
              <p className="error">{formik.errors.firstname}</p>
            ) : null} */}
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Dave"
              autoComplete="off"
              name="lastname"
              value={user.lastname}
              onChange={(e) => handleChange(e)}
              // {...formik.getFieldProps("lastname")}
              // onChange={(e) => handleEdit(e)}
            />
            {/* {formik.touched.lastname && formik.errors.lastname ? (
              <p className="error">{formik.errors.lastname}</p>
            ) : null} */}
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              pattern={emailRegExp}
              placeholder="name@example.com"
              autoComplete="off"
              name="email"
              value={user.email}
              onChange={(e) => handleChange(e)}
              // {...formik.getFieldProps("email")}
              // onChange={(e) => handleEdit(e)}
            />
            {/* {formik.touched.email && formik.errors.email ? (
              <p className="error">{formik.errors.email}</p>
            ) : null} */}
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              autoComplete="off"
              name="password"
              value={user.password}
              onChange={(e) => handleChange(e)}
              // {...formik.getFieldProps("password")}
              // onChange={(e) => handleEdit(e)}
            />
            {/* {formik.touched.password && formik.errors.password ? (
              <p className="error">{formik.errors.password}</p>
            ) : null} */}
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>Entity Role</Form.Label>
            <Form.Control
              type="number"
              placeholder="Admin(1)"
              disabled="true"
              autoComplete="off"
              name="roleIdf"
              value={user.roleIdf}
              onChange={(e) => handleChange(e)}
              // {...formik.getFieldProps("roleIdf")}
              // onChange={(e) => handleEdit(e)}
            />
            {/* {formik.touched.roleIdf && formik.errors.roleIdf ? (
              <p className="error">{formik.errors.roleIdf}</p>
            ) : null} */}
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>Profile</Form.Label>
            <Form.Control
              type="file"
              name="image"
              onChange={(e) => onChangePicture(e)}
            />
          </Form.Group>

          <div className="text-center">
            <CustomButton buttonText={"Go Back"} onClick={goBack} />
            <CustomButton buttonText={"submit"} type={"submit"} />
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default AdminForm;
