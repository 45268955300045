import React from "react";
import DashboardWidget from "../../../components/widget/widget";
import { getAdminAccount } from "../../../services/authServices";
import { useState } from "react";
import { useEffect } from "react";
import { BiUserCircle } from "react-icons/bi";
import { Card } from "react-bootstrap";

const AccountDetails = () => {
  const [AccountData, setAccountData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // User Data
  const getAccountDetails = async () => {
    setLoading(true);
    try {
      const res = await getAdminAccount();
      setAccountData(res?.data?.user);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getAccountDetails();
  }, []);

  return (
    <div
      style={{
        display: "grid",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Card
        style={{
          width: "20rem",
          textAlign: "center",
        }}
      >
        <Card.Header>
          <BiUserCircle size={"30px"} />
          <label>
            <b>UserId:</b>
          </label>
          {" " + AccountData?.userId}
        </Card.Header>
        {/* <br /> */}
        <Card.Text className="p-3 mt-3">
          <Card className="p-4">
            <b>Total Balance:</b> {AccountData?.bs?.totalBalance}
          </Card>
          <Card className="p-4 mt-3">
            <b> Wallet Balance:</b> {AccountData?.bs?.walletBalance}
          </Card>
        </Card.Text>
      </Card>
    </div>
  );
};

export default AccountDetails;
