import React, { useEffect } from "react";
import CustomLoader from "../../components/loader/loader";
import { Form } from "react-bootstrap";
import { useState } from "react";
import MainTitle from "../../components/mainTitile/mainTitle";
import "../user/form/userForm.css";
import { useNavigate } from "react-router-dom";
import {
  Cms_data_post,
  single_cms_data,
  update_cms_data,
} from "../../services/authServices";
import JoditEditor from "jodit-react";
import CustomButton from "../../components/button/button";
import { CmsData } from "../../redux/authSlice";
import { useAppSelector } from "../../redux/hooks";
import { useSelector } from "react-redux";
const CmsForm = () => {
  const CmsFlag = localStorage.getItem("CmsFlag");

  const { _id, cmsType } = useSelector(CmsData);
  const [heading, setHeading] = useState({
    title: "",
    content: "",
    cmsType: cmsType !== undefined ? cmsType : "",
  });
  // const [id1,setId1]=useState()
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handelsubmit = async () => {
    if (_id !== undefined && _id !== null) {
      try {
        const params = {
          title: heading?.title,
          content: heading?.content,
          cmsType: heading?.cmsType,
        };
        const res = await update_cms_data(_id, params);
        setHeading(res?.data?.Data);
        setLoading(false);
        navigate("/cms");
      } catch (e) {
        console.error(e);
      }
    } else {
      const res = await Cms_data_post(heading);
      setHeading(res?.data?.Data);
      setLoading(false);
      navigate("/cms");
    }
  };
  
  const handleEditor = (e) => {
    setHeading({ ...heading, metaDescription: e });
  };

  const handleEditorsnd = (e) => {
    setHeading({ ...heading, content: e });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setHeading({ ...heading, [name]: value });
  };

  const getTerms = async () => {
    setLoading(false);
    try {
      const params = {
        cmsType: cmsType,
      };
      const res = await single_cms_data(params);
      setHeading(res?.data?.Data);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (CmsFlag === "true") {
      getTerms();
    }
    // setType(name.split(",")[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CmsFlag]);

  return (
    <div className="body">
      <CustomLoader isLoading={isLoading} />
      {/* <Container className="d-flex justify-content-center" > */}
      {/* <Form className="userForm w-50"> */}

      <MainTitle
        heading={heading?.cmsType ? heading?.cmsType + " Form" : "Form"}
        textAlign={"center"}
      />
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Cms Type</Form.Label>
        <Form.Control
          disabled={CmsFlag === "true"}
          type="text"
          placeholder="Your title"
          autoComplete="off"
          name="cmsType"
          value={heading?.cmsType}
          onChange={(e) => handleChange(e)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Title</Form.Label>
        <Form.Control
          type="text"
          placeholder="Your title"
          autoComplete="off"
          name="title"
          value={heading?.title}
          onChange={(e) => handleChange(e)}
        />
      </Form.Group>
      {/* <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Meta Title</Form.Label>
        <Form.Control
          type="text"
          placeholder="meta title"
          autoComplete="off"
          name="metaTitle"
          value={heading?.metaTitle}
          onChange={(e) => handleChange(e)}
        />
      </Form.Group> */}
      {/* <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Meta keyword</Form.Label>
        <Form.Control
          type="text"
          placeholder="meta Keyword"
          autoComplete="off"
          name="metaKeyword"
          value={heading?.metaKeyword}
          onChange={(e) => handleChange(e)}
        />
      </Form.Group> */}
      {/* <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Meta description</Form.Label>
        <JoditEditor
          type="text"
          placeholder="meta Description"
          autoComplete="off"
          name="metaDescription"
          value={heading?.metaDescription}
          onChange={(e) => handleEditor(e)}
        />
      </Form.Group> */}
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold">Content</Form.Label>
        <JoditEditor
          type="text"
          
          placeholder="content"
          autoComplete="off"
          name="content"
          value={heading?.content}
          onChange={(e) => handleEditorsnd(e)}
        />
      </Form.Group>

      {/* // </Form> */}
      {/* // </Container> */}

      <CustomButton buttonText={"submit"} onClick={handelsubmit} />
    </div>
  );
};

export default CmsForm;
