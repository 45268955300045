import { loginUser } from "../services/authServices";
import { createSlice } from "@reduxjs/toolkit";
import { getAccessToken, getItem, removeItem } from "../services/localStorage";

const accessToken = getAccessToken();
const refreshToken = getItem("refreshToken");
const datat = getItem("email");
const cmsData = getItem("data");
const initialState = {
  isProcessingRequest: false,
  accessToken: accessToken,
  refreshToken: refreshToken,
  isLogin: !!accessToken,
  // userData : datat ? datat : ' ',
  data: datat ? datat : { _id: "", cmsType: "" },
  CmsData: cmsData ? JSON.parse(cmsData) : [],
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    start: (state) => {
      return {
        ...state,
        isProcessingRequest: true,
      };
    },
    userdetail: (i, state) => {
      return {
        data: state.payload,
      };
    },
    success: (i, state) => {
      localStorage.setItem("email", state.payload.Data.email);
      return {
        // ...i,
        data: state.payload.Data.email,
        isLogin: true,
        // isProcessingRequest: false,
      };
    },
    CMSTypes: (i, state) => {
      return {
        ...i,
        CmsData: state.payload,
        isLogin: true,
        isProcessingRequest: false,
      };
    },
    error: () => {
      return {
        isLogin: false,
        isProcessingRequest: false,
      };
    },

    logout: () => {
      removeItem("email");
      return {
        accessToken: null,
        refreshToken: null,
        isLogin: false,
        data: "",
      };
    },
  },
});

export const authenticateUser = (userData) => async (dispatch) => {
  loginUser(userData)
    .then((res) => {
      if (res.data.Status === 200) {
        dispatch(success(res.data));
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const { start, success, error, logout, userdetail, CMSTypes } =
  authenticationSlice.actions;
export const isLogin = (RootState) => RootState.authentication.isLogin;
export const CmsData = (RootState) => RootState.authentication.CmsData;
export const authenticationReducer = authenticationSlice.reducer;
