import React, { useState } from "react";
import { Col, Container, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import MainTitle from "../mainTitile/mainTitle";
import CustomButton from "../button/button";
import { HolidayPackagesUpdate, getSingleHolidayPackages } from "../../services/authServices";
import { useEffect } from "react";
import moment from "moment";

const HolidaysDataModal = ({ show, handleCloseModal, holidayId }) => {
const [singleData,setSingleData] = useState()
const [data,setData] = useState({
    remarks : '',
    status:''
})
// const [remarks,setRemark] = useState('')
// const [status,setStatus] = useState('')
  const singleApi = () =>{
    let params = { holidayId:holidayId}
    getSingleHolidayPackages(params).then((res)=>{
        setSingleData(res.data.Data)
    console.warn('response from sngle api',res.data.Data);
}).catch((err)=>{
    console.warn('Error from sngle api',err);
    
})
}  
  const handleChange = (e) => {
const {name ,value} = e.target
setData({...data,[name]:value})
};
useEffect(()=>{
    if(show && holidayId){
        singleApi()
    }
},[holidayId])

const handleSubmit = (e) =>{
    e.preventDefault()
    let params = {
    remarks :data.remarks,
    status:data.status,
    holidayId:holidayId
    }
    HolidayPackagesUpdate(params).then((res)=>{
    console.log(res);
    handleCloseModal()
}).catch((err)=>{
    console.log(err);
    
    })
console.log('update data',data);
}
  return (
    <>
      <Modal show={show} backdrop="static"  onHide={handleCloseModal}>
        <Modal.Header  className="justify-content-center mainColor">
          {/* <MainTitle  heading={"Holiday Details"} textAlign={"center"} /> */}
          <h4 className=" mb-0 fs-35 fw-700 text-white" >Holiday Details</h4>
        </Modal.Header>
        <Modal.Body>
        <Container className="my-2" >

        <Row className="text-capitalize fs-12 my-2">
            <Col lg={5}>Name: {` ${singleData?.firstname} ${singleData?.lastname}`}</Col>
            <Col>HP Order Id:{ singleData?.holidayId}</Col>
            {/* <Col>Mail:{singleData?.email}</Col> */}
        </Row>
        <Row  className="text-capitalize fs-12 my-2">
            <Col lg={5}>from: {singleData?.from}</Col>
            <Col>to: {singleData?.to}</Col>
        </Row>
        <Row  className="text-capitalize fs-12 my-2">
            <Col lg={5}>Start Date: {moment(singleData?.fromDate).format('DD MMM YYYY')}</Col>
            <Col>End Date: {moment(singleData?.toDate).format('DD MMM YYYY')}</Col>
        </Row>
        <Row  className="fs-12 my-2">
            {/* <Col lg={5}>hotelType:{singleData?.hotelType}</Col> */}
            <Col  lg={5}>Mobile: {singleData?.mobile}</Col>
            <Col>Mail: {singleData?.email}</Col>
        </Row>
        <Row  className="text-capitalize fs-12 my-2">
            {/* <Col lg={5}>hotelType:{singleData?.hotelType}</Col> */}
            <Col lg={5}>trip type: {singleData?.tripType}</Col>
            <Col>hotel type: {singleData?.hotelType}</Col>
        </Row>
        <Row  className="text-capitalize fs-12 my-2">
            <Col lg={5}>No. of  Nights: {singleData?.number_Nights}</Col>
            <Col >traveller: {singleData?.traveller}</Col>
            {/* <Col>status:{singleData?.status}</Col> */}
        </Row>
        <Row  className="text-capitalize fs-12 my-2">
            <Col lg={5}>Budget: ₹{singleData?.budget}</Col>
            {/* <Col>holidayId:{singleData?.holidayId}</Col> */}
            <Col>status: {singleData?.status}</Col>
        </Row>
        <Row  className="text-capitalize fs-12 my-2">
            {/* <Col lg={5}>traveller:{singleData?.traveller}</Col> */}
            <Col >special Requirement: {singleData?.specialRequirement}</Col>
        </Row>
        </Container>
          <Form method="POST" onSubmit={handleSubmit} className="mt-2">
            <Row>
              <Col>
                <FloatingLabel label="Remark">
                  <Form.Control
                    name="remarks"
                    placeholder="remarks"
                    type="text"
                    size="sm"
                    onChange={handleChange}
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label="Status">
                  <Form.Select
                    name="status"
                    placeholder="Status"
                    value={data?.status}
                    defaultValue={singleData?.status}
                    onChange={handleChange}
                  >
                    <option value={'Initiate'}>Initiate</option>
                    <option value={'Initiate'} >Initiate</option>
                    <option value={'InProgress'} >InProgress</option>
                    <option value={'Close'}>Close</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
            
                    <CustomButton onClick={handleCloseModal} type={'button'}  buttonText={'cancel'} />
                <Col  lg={4}>
                    <CustomButton type={'submit'} buttonText={'update'} />
                </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HolidaysDataModal;
