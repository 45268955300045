import React from 'react';
import './button.css';

const CustomButton = ({ buttonText, onClick, type,disabled }) => {
  return (
    <>
      <button className='button mainColor' disabled={disabled} onClick={onClick} type={type}>
        {buttonText}
      </button>
    </>
  );
}

export default CustomButton;
