export const customStyles = {
    title: {
      style: {
        fontColor: "red",
        fontWeight: "900",
      },
    },
    rows: {
      style: {
        // minHeight: '72px', // override the row height
        textCenter: "align",
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        fontWeight: "700",
        pendingLeft: "0px",
        border: "1px solid #DEE2E6",
        textCenter: "align",
        textTransform: "uppercase",
      },
    },
    cells: {
      style: {
        borderRight: "1px solid #DEE2E6",
        borderBottom: "1px solid #DEE2E6",
        fontSize: "12px",
      },
    },
  };