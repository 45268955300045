import React from 'react'
import { useSelector } from 'react-redux'
import { CmsData } from '../../redux/authSlice'
import { Card, Col, Row } from 'react-bootstrap'
import './cmsview.css'

const CmsView = () => {
    const { cmsType, title, metaTitle, metaKeyword, content, metaDescription, updatedAt } = useSelector(CmsData)
    const OnlyDateFormate = (data) => {
        const d = new Date(data);
        let Data = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
        return Data;
    };
    return (
        <div className='position'>
            <Card className='p-5 Card'>
                <Row className='d-flex mx-1'>
                    <Col lg={6}>

                        <h3>{cmsType}</h3>
                    </Col>

                    <Col lg={6} className='justify-content-end d-flex'>
                        <h5> last updated date: {OnlyDateFormate(updatedAt)} </h5>
                    </Col>
                    <hr />
                </Row>
                <div className='justify-content-center'>
                    <Row className='d-flex mx-1'><Col lg={4}><b>CMSTitle :</b> </Col><Col lg={8}>{title}</Col></Row>
                    <Row className='d-flex mx-1'><Col lg={4}><b>CMS Content :</b></Col><Col lg={8} dangerouslySetInnerHTML={{ __html: content }} /></Row>
                </div>
            </Card>
        </div>
    )
}

export default CmsView