import React, { useState } from "react";
import { Col, Container, ListGroup, Nav, Navbar, Row } from "react-bootstrap";
import CustomButton from "../button/button";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/authSlice";
import { PopupMenu } from "react-simple-widgets";
import { CgProfile } from "react-icons/cg";
import {
  MdOutlineArrowDropDown,
  MdHolidayVillage,
  MdFreeCancellation,
  MdFlight,
} from "react-icons/md";
import "./navbar.css";
import TMTicon from "../logo/logoIcon";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaUserTie,
  FaPlaceOfWorship,
  FaHotel,
  FaWeebly,
} from "react-icons/fa";
import { TbDiscount2 } from "react-icons/tb";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  RiAdminFill,
  RiFlightTakeoffLine,
  RiRefund2Line,
} from "react-icons/ri";
import { BsWallet2 } from "react-icons/bs";
import { GrDocumentConfig } from "react-icons/gr";
import Logo from "../logo/logo";
import "../sidebar/sidebar.css";
import { removeTokens } from "../../services/localStorage";
import { useDispatch } from "react-redux";
import Footer from "../footer/footer";

const NavbarB = ({ children }) => {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const toggle = () => setIsOpen(!isOpen);
  const [isOpen, setIsOpen] = useState(true);

  const Logout = () => {
    dispatch(logout(false));
    removeTokens();
    let path = `/`;
    navigate(path);
  };

  return (
    <>
      <Navbar
        bg="light"
        style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
      >
        <Container fluid>
          <div className="top_section">
            <Link
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            >
              <FaBars onClick={toggle} />
            </Link>

            <Navbar.Brand href="#">
              <TMTicon onclick={toggle} />
            </Navbar.Brand>
          </div>

          <Nav className="ml-auto">
            <Logo onClick={toggle} />
          </Nav>
          {/* <Navbar.Collapse className="justify-content-end"> */}
          <Nav>
            <PopupMenu>
              <button className="pbtn">
                <CgProfile size={35} className="profileIcon" />
                <br />
                <MdOutlineArrowDropDown />
              </button>

              <div className="card text-start" style={{ marginTop: "70px" }}>
                <div className="card-body popup">
                  <CgProfile
                    id="circle-avatar"
                    className="text-center mx-auto mb-4"
                    size={35}
                  />

                  <div className="list-group list-group-flush">
                    <Link
                      to="/passwordChange"
                      className="list-group-item text-center"
                    >
                      <small>Change Password</small>
                    </Link>

                    <a className="list-group-item text-center">
                      <small>UAT Update Profile</small>
                      <small className="text-muted"> (Coming Soon)</small>
                    </a>
                  </div>
                  <div className="justify-content-center d-flex">
                    <CustomButton buttonText={"Logout"} onClick={Logout} />
                  </div>
                </div>
              </div>
            </PopupMenu>
            {/* </Navbar.Collapse> */}
          </Nav>
        </Container>
      </Navbar>

      <Row className="navrow">
        <Col md={3} lg={2} sm={4} xs={12} className="px-0">
          <div style={{ width: isOpen ? "221px" : "50px" }} className="sidebar">
            <Sidebar
              width="231px"
              rootStyles={{
                minWidth: "50px",
                width: isOpen ? "221px" : "50px",
              }}
            >
              <Menu>
                {/*=================== Dashboard =================== */}
                <Link to="/" style={{ color: "black" }}>
                  <MenuItem icon={<FaTh />}>
                    {" "}
                    <label style={{ fontSize: "16px" }}>Dashboard</label>
                  </MenuItem>
                </Link>

                {/*=================== Orders ===================*/}
                <ListGroup>
                  <SubMenu
                    label={<label style={{ fontSize: "16px" }}>Orders</label>}
                    icon={<FaRegChartBar />}
                  >
                    <SubMenu
                    
                      label={<label style={{ fontSize: "16px" }}>Flight</label>}
                      icon={<MdFlight />}
                    >
                      <ListGroup.Item>
                        <Link
                          to="/flights"
                          style={{ color: "black", marginLeft: "15px"}}
                        >
                          <MenuItem
                            icon={<RiFlightTakeoffLine size={"14px"} />}
                          >
                            <label style={{ fontSize: "small" }}>
                              Flights order
                            </label>
                          </MenuItem>
                        </Link>
                      </ListGroup.Item>
                      {/* <ListGroup.Item>
                      <Link to="/amendment" style={{ color: "black" ,marginLeft:'15px'}}>
                        <MenuItem icon={<MdFreeCancellation size={"14px"} />}>
                          <label style={{ fontSize: "small" }}>
                            Cancellation
                          </label>
                        </MenuItem>
                      </Link>
                    </ListGroup.Item> */}
                      <ListGroup.Item>
                        <Link
                          to="/refund"
                          style={{ color: "black", marginLeft: "15px" }}
                        >
                          <MenuItem icon={<RiRefund2Line size={"14px"} />}>
                            <label style={{ fontSize: "small" }}>Refund</label>
                          </MenuItem>
                        </Link>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Link
                          to="/airport"
                          style={{ color: "black", marginLeft: "15px" }}
                        >
                          <MenuItem icon={<GrDocumentConfig size={"14px"} />}>
                            <label style={{ fontSize: "small" }}>Airport</label>
                          </MenuItem>
                        </Link>
                      </ListGroup.Item>
                    </SubMenu>

                    {/* CANCELLATION */}
                    {/* <ListGroup.Item> */}
                    {/* FLIGHT */}
                    {/* <Link to="/hotels" style={{ color: "black" }}>
                        <MenuItem icon={<FaHotel size={"16px"} />}>
                          <label style={{ fontSize: "16px" }}>Hotels</label>{" "}
                        </MenuItem>
                      </Link> */}
                    {/* </ListGroup.Item> */}
                    <ListGroup.Item>
                      <Link to="/holidays" style={{ color: "black" }}>
                        <MenuItem icon={<MdHolidayVillage size={"16px"} />}>
                          <label style={{ fontSize: "16px" }}>Holidays</label>
                        </MenuItem>
                      </Link>
                    </ListGroup.Item>
                  </SubMenu>
                </ListGroup>

                {/*========================== Wallets=================== */}
                <ListGroup>
                  <SubMenu
                    label={<label style={{ fontSize: "16px" }}>Wallet</label>}
                    icon={<BsWallet2 />}
                  >
                    {/*=================== User Wallet ===================*/}
                    <ListGroup.Item>
                      <Link to="/userwallet" style={{ color: "black" }}>
                        <MenuItem icon={<FaUserAlt size={"16px"} />}>
                          <label style={{ fontSize: "small" }}>
                            User Wallet
                          </label>
                        </MenuItem>
                      </Link>
                    </ListGroup.Item>

                    {/*=================== Admin Wallet ===================*/}
                    <ListGroup.Item>
                      <Link to="/adminwallet" style={{ color: "black" }}>
                        <MenuItem icon={<FaUserTie size={"16px"} />}>
                          <label style={{ fontSize: "small" }}>
                            Admin Wallet
                          </label>
                        </MenuItem>
                      </Link>{" "}
                    </ListGroup.Item>
                  </SubMenu>
                </ListGroup>

                {/*=================== User Management=================== */}
                <ListGroup>
                  <SubMenu
                    label={
                      <label style={{ fontSize: "16px" }}>Management</label>
                    }
                    icon={<FaUserAlt />}
                  >
                    {/*=================== Admin List ===================*/}
                    <ListGroup.Item>
                      <Link to="/adminList" style={{ color: "black" }}>
                        <MenuItem icon={<RiAdminFill size={"16px"} />}>
                          {" "}
                          <label style={{ fontSize: "small" }}>
                            Admin
                          </label>{" "}
                        </MenuItem>
                      </Link>
                    </ListGroup.Item>
                    {/*=================== User List ===================*/}
                    <ListGroup.Item>
                      <Link to="/userList" style={{ color: "black" }}>
                        <MenuItem icon={<FaUserTie size={"16px"} />}>
                          <label style={{ fontSize: "small" }}>User</label>{" "}
                        </MenuItem>
                      </Link>
                    </ListGroup.Item>
                    {/*=================== Offers and discount ===================*/}
                    <ListGroup.Item>
                      <Link to="/offersDiscount" style={{ color: "black" }}>
                        <MenuItem icon={<TbDiscount2 size={"16px"} />}>
                          <label style={{ fontSize: "small" }}>Offers</label>{" "}
                        </MenuItem>
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Link to="/popularDestination" style={{ color: "black" }}>
                        <MenuItem icon={<FaPlaceOfWorship size={"16px"} />}>
                          <label style={{ fontSize: "small" }}>
                            Destinations
                          </label>
                        </MenuItem>
                      </Link>
                    </ListGroup.Item>
                  </SubMenu>
                </ListGroup>

                <Link to="/cms" style={{ color: "black" }}>
                  <MenuItem icon={<RiAdminFill />}>
                    <label style={{ fontSize: "18px" }}>CMS</label>{" "}
                  </MenuItem>
                </Link>
                <Link to="/faq" style={{ color: "black" }}>
                  <MenuItem icon={<RiAdminFill />}>
                    <label style={{ fontSize: "18px" }}>FAQ</label>{" "}
                  </MenuItem>
                </Link>
              </Menu>
            </Sidebar>

            {/* <div
              style={{ display: isOpen ? "" : "none" }}
              className="bars pt-5 mt-5"
            >
              <Logo onClick={toggle} />
            </div> */}
          </div>
        </Col>
        <Col md={9} lg={10} sm={8} xs={6}>
          <main>{children}</main>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default NavbarB;
