import React from "react";
import { Container, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import * as yup from "yup";
import CustomButton from "../../../components/button/button";
import MainTitle from "../../../components/mainTitile/mainTitle";
import { addUser, adminById, adminEdit } from "../../../services/authServices";
import { useNavigate, useParams } from "react-router-dom";
import "./userForm.css";
const UserForm = () => {
  const initialValues = {
    title: "",
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    roleIdf: 2,
    image: "",
  };
  const [user, setUser] = useState();
  const [userDetail, setUserDetail] = useState(initialValues);
  const navigate = useNavigate();
  const onSubmit = async (values) => {
    if (!userDetail._id) {
      try {
        const res = await addUser(values);
        setUser(res.data.Data);
        console.log(user);
        navigate("/userList");
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        const formData = new FormData();
        formData.append("title", formik.values.title);
        formData.append("firstname", formik.values.firstname);
        formData.append("lastname", formik.values.lastname);
        formData.append("email", formik.values.email);
        formData.append("mobile", formik.values.mobile);
        formData.append("roleIdf", formik.values.roleIdf);
        formData.append("image", userDetail.image);

        // For Updating the data
        const res = await adminEdit(id, formData);
        setUserDetail(res.data.Data);
        navigate("/userList");
      } catch (e) {
        console.error(e);
      }
      // try {
      //   const res = await adminEdit(id,values);
      //   setUser(res.data.Data);
      //     navigate("/userList");
      // } catch (e){
      //   console.error(e);
      // }
    }
  };
  const { id } = useParams();
  //RegExp Expression
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  //Validation section by yup
  const validationSchema = yup.object({
    title: yup.string().required("Required"),
    firstname: yup.string().required("Please enter First Name"),
    lastname: yup.string().required("Please enter Last Name"),
    email: yup
      .string()
      .matches(emailRegExp, "Email address is not valid")
      .required("Please enter Email Address"),
    mobile: yup
      .string()
      .matches(phoneRegExp, "Phone Number is not valid")
      .required("Please enter Mobile Number"),
  });
  const getUserData = async () => {
    try {
      const res = await adminById(id);
      setUserDetail(res.data.Data);
    } catch (e) {
      console.error(e);
    }
  };
  const handleImage = (e) => {
    setUserDetail({ ...userDetail, image: e.target.files[0] });
  };

  useEffect(() => {
    if (id !== undefined) {
      getUserData();
    }
  }, [id]);
  const formik = useFormik({
    enableReinitialize: userDetail,
    initialValues: userDetail,
    validationSchema,
    onSubmit,
  });
  return (
    <div>
      <Container className="d-flex justify-content-center">
        <Form onSubmit={formik.handleSubmit} className="userForm">
          <MainTitle heading={"User Form"} textAlign={"center"} />
          <Form.Group className="mb-2">
            <Form.Select
              {...formik.getFieldProps("title")}
              value={formik.values.title}
            >
              <option value={""}>Select Title</option>
              <option value={"Mr."}>Mr.</option>
              <option value={"Mrs."}>Mrs.</option>
              <option value={"Ms."}>Ms.</option>
            </Form.Select>
            {formik.touched.title && formik.errors.title ? (
              <p className="error">{formik.errors.title}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              value={formik.values.firstname}
              placeholder="john"
              {...formik.getFieldProps("firstname")}
              // onChange={(e) => handleEdit(e)}
            />
            {formik.touched.firstname && formik.errors.firstname ? (
              <p className="error">{formik.errors.firstname}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type="text"
              value={formik.values.lastname}
              placeholder="Dave"
              {...formik.getFieldProps("lastname")}
              // onChange={(e) => handleEdit(e)}
            />
            {formik.touched.lastname && formik.errors.lastname ? (
              <p className="error">{formik.errors.lastname}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={formik.values.email}
              placeholder="name@example.com"
              {...formik.getFieldProps("email")}
              // onChange={(e) => handleEdit(e)}
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="error">{formik.errors.email}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="text"
              value={formik.values.mobile}
              {...formik.getFieldProps("mobile")}
              // onChange={(e) => handleEdit(e)}
            />
            {formik.touched.password && formik.errors.password ? (
              <p className="error">{formik.errors.password}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Entity Role</Form.Label>
            <Form.Control
              type="number"
              value={formik.values.roleIdf}
              disabled="true"
              placeholder="User(2)"
            />
            {formik.touched.roleIdf && formik.errors.roleIdf ? (
              <p className="error">{formik.errors.roleIdf}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Profile</Form.Label>
            <Form.Control
              type="file"
              name="image"
              onChange={(e) => handleImage(e)}
            />
          </Form.Group>
          {userDetail._id ? (
            <div className="text-center">
              <CustomButton buttonText={"Update"} type={"submit"} />
            </div>
          ) : (
            <div className="text-center">
              <CustomButton buttonText={"submit"} type={"submit"} />
            </div>
          )}
        </Form>
      </Container>
    </div>
  );
};
export default UserForm;
