import React from "react";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { BiEdit} from "react-icons/bi";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteOffer,
  deleteUser,
  offers_discount,
} from "../../services/authServices";
import MainTitle from "../../components/mainTitile/mainTitle";
import TablePagination from "../../components/pagination/tablePagination";
import CustomButton from "../../components/button/button";
import "./offers.css";
import BackButtonComp from "../../components/button/backButton";
import CustomLoader from "../../components/loader/loader";

const OffersDiscount = () => {
  const [offersData, setOffersData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  let navigate = useNavigate();
  //To take all the data from the backend
  const getofferdata = async () => {
    setLoading(true);
    try {
      const res = await offers_discount();
      setOffersData(res.data.Data.getData);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getofferdata();
  }, []);

  const formpage = () => {
    let path = "/offersForm";
    navigate(path);
  };

  const handleDelete = (userid, e) => {
    deleteOffer(userid).then((res)=>{
      getofferdata()
    }).catch((err)=>{
    console.log(err);
    });
    // window.location.reload();
  };

  return (
    <div>
      <CustomLoader isLoading={isLoading} />
      <Row>
        <Col sm={10} className="pt-3">
          <MainTitle heading={"Offer Details"} textAlign={"center"} />
        </Col>
        <Col sm={2}>
          <CustomButton buttonText={"Add"} onClick={formpage} />
        </Col>
      </Row>
      <div className="grid_container">
        {offersData?.map((aItem) => (
          <Card className="p-3">
            <Row>
              <Col sm={4}>
                <img src={aItem?.image} className="size" alt="offers" />
              </Col>
              <Col sm={8}>
                <p className="fs-16 fw-700">{aItem?.title}</p>
                <p className="fs-12"  dangerouslySetInnerHTML={{__html:   aItem?.description }} />
              </Col>
            </Row>
            <div className="">
              <Row className="list-group my-2 fs-12">
                {" "}
                {/* <h6 className="fw-700 my-1">
                  <u>Promo Code Details:</u>
                </h6> */}
                <Col className="list-group-item bold">
                  <b>Code:</b> {aItem?.promocode?.code}
                </Col>
                {aItem?.promoType === 'PERCENTAGE' ?
                <Col className="list-group-item bold">
                  <b>Percentage OFF:</b> {aItem?.promocode?.percentOff}
                </Col>
                :
                <Col className="list-group-item bold">
                  <b>Amount OFF:</b> {aItem?.promocode?.amountoff}
                </Col>
                
                }
                <Col className="list-group-item">
                  <b>Expires on: </b>
                  {aItem?.promocode?.expiry_date && aItem?.promocode?.expiry_date.split("T")[0]}
                </Col>
                <Col className="list-group-item">
                  <b>Total Redeemed:</b> {aItem?.promocode?.total_redeemed}
                </Col>
               
              </Row>
              {/* <Row className="my-1">
                <hr className="" />
                <div>
                  <b>Term and Conditions:- </b>
                  <Link target={"_blank"}>{aItem?.terms_condition?.terms_link}</Link>
                </div>
              </Row>
              <hr />
              <Row className="my-1">
                <div>
                  <b>Know More:- </b>
                  <Link target={"_blank"}>{aItem?.more_about_offer?.more_link}</Link>
                </div>
              </Row> */}
              <Row>
                <Col sm={6}>
                  <Link>
                    <Button
                      variant="outline-danger"
                      className="mt-1 container"
                      size="sm"
                      onClick={() => handleDelete(aItem._id)}
                    >
                      <AiFillDelete size={20} /> 
                    </Button>
                  </Link>
                </Col>
                <Col sm={6}>
                  <Link style={{ textDecoration: "none" }}
                        to={`/offersForm/${aItem._id}`}>
                    <Button variant="outline-success" size="sm" className="mt-1 container">
                      <BiEdit  size={20}/>
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </Card>
        ))}
      </div>

      <TablePagination />
    </div>
  );
};

export default OffersDiscount;
