import React from "react";
import logo from "../../assets/logo.png";

const Logo = ({ onclick, to }) => {
  return (
    <>
      <img fluid
        src={logo}
        onClick={onclick}
        height={"40px"}
        width={"200px"}
        alt="TMT Logo"
        to={to}
        style={{ transition: "all 0.5s" }}
      />
    </>
  );
};

export default Logo;
