import { Puff } from 'react-loader-spinner'
import './loader.css'


const CustomLoader = ({ isLoading }) => (
    <div className={isLoading ? 'loader' : 'doneLoader'}>
        <div>

            <Puff
                height="100"
                width="100"
                radius={1}
                color="#281b75"
                ariaLabel="puff-loading"
                visible={true}
            />
            <h4 style={{ color: "#281b75" }}>Loading....</h4>
        </div>
    </div>
);


export default CustomLoader