import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Logo from '../logo/logo'
import Copyright from '../../assets/Copyright.png'
import instagramIcon from "../../assets/Instagram Circle.png";
import facebookIcon from "../../assets/Facebook.png";
import twitterIcon from "../../assets/Twitter Circled.png";
import { Link, Router } from 'react-router-dom';

const Footer = () => {
    return (
        <div style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 25px" }}>
            <Container className='pt-5 pb-5'>
                <Row className="p-2">
                    <Col sm={4} >
                    <Link  to={"/"}>

                        <Logo to={"/"} />
                    </Link>
                    </Col>
                    <Col sm={5} className="pt-3">
                        <small>
                            <img src={Copyright} alt="logo" width={20}
                                height={20} />
                            2023 TreatMyTravel All Rights Reserved.</small>
                    </Col>
                    <Col md={3} className="pt-2">
                        <div className="d-flex">
                            <Col sm={2}>
                                <Link target={"_blank"}
                                    to={"https://www.facebook.com/treatmytravel"}
                                    className="pr-3"
                                >
                                    <img
                                        width={30}
                                        height={30}
                                        src={facebookIcon}
                                        alt="facebook icon"
                                    />
                                </Link>
                            </Col>
                            <Col sm={4} className="mx-1">
                                <Link target={"_blank"} 
                                    to={"https://www.instagram.com/treatmytravel"}
                                    className="px-3"
                                >
                                    <img
                                        width={30}
                                        height={30}
                                        src={instagramIcon}
                                        alt="instagram icon"
                                    />
                                </Link>
                            </Col>
                            <Col>

                                <Link
                                    target={"_blank"}
                                    to={"https://twitter.com/TreatMyTravel"}>
                                    <img
                                        width={30}
                                        height={30}
                                        src={twitterIcon}
                                        alt="twitter icon"
                                    />
                                </Link>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer