import React from 'react';
import PieChartGraph from '../../components/chart/pieChart';
import MainTitle from '../../components/mainTitile/mainTitle';

const Product = () => {
  return (
    <div>
      <MainTitle heading={"Hello Product"}
        textAlign={"center"}
      />
      <PieChartGraph />
    </div>
  );
}

export default Product;
