import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardGroup,
  Col,
  NavDropdown,
  Row,
} from "react-bootstrap";
import MainTitle from "../../components/mainTitile/mainTitle";
import DashboardCard from "../../components/card/dashboardCard";
import {
  adminData,
  get_Order_list,
  order_data,
  transaction_history,
} from "../../services/authServices";
import { useNavigate } from "react-router-dom";
import DashboardWidget from "../../components/widget/widget";
import wallet from "../../assets/wallet.png";
import totalUser from "../../assets/totalUser.png";
import totalSales from "../../assets/totalSales.png";
import totalPurchase from "../../assets/totalPurchase.png";

const DashboardMain = () => {
  // For getting the email address of admin.
  const GetEmail = localStorage.getItem("email");

  // Hooks for storing the data
  const [OrderData, setOrderData] = useState([]);
  const [UserData, setUserData] = useState(0);
  const [TotalFc, setTotalFc] = useState([]);
  const [TotalSales, setTotalSales] = useState([]);
  const [PromoCashCredit, setPromoCashCredit] = useState([]);
  const [PromoCashDebit, setPromoCashDebit] = useState([]);
  const [MyCashCredit, setMyCashCredit] = useState([]);
  const [MyCashDebit, setMyCashDebit] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Order Data
  const getOrderDetails = async () => {
    // setLoading(true);
    try {
      const res = await order_data();
      setOrderData(res?.data?.Data[0]?.count);
      setTotalFc(res?.data?.Data[0]?.totalPurchase);
      setTotalSales(res?.data?.Data[0]?.totalSales);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  //  TRANSACTION DATA
  const getTransaction = async () => {
    // setLoading(true);
    try {
      const res = await transaction_history();
      // Promocash
      setPromoCashCredit(res?.data?.Data?.promocash?.credit[0]);
      setPromoCashDebit(res?.data?.Data?.promocash?.debit[0]);
      // MyCash
      setMyCashCredit(res?.data?.Data?.myCash?.credit[0]);
      setMyCashDebit(res?.data?.Data?.myCash?.debit[0]);
    } catch (e) {
      console.error(e);
    }
  };

  // User Data
  const getUserData = async () => {
    setLoading(true);
    try {
      const res = await adminData();
      console.log('total user here',res?.data?.Data?.userDocs);
      setUserData(res?.data?.Data?.userDocs);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getOrderDetails();
    getUserData();
    getTransaction();
  }, []);

  //  Function for showing amount properly.
  function numDifferentiation(val) {
    if (val >= 10000000) val = (val / 10000000).toFixed(2) + " Cr";
    else if (val >= 100000) val = (val / 100000).toFixed(2) + " Lac";
    else if (val >= 1000) val = (val / 1000).toFixed(2) + " K";
    return val;
  }
  return (
    <>
      <MainTitle heading={"Dashboard"} textAlign={"center"} />

      {/* <hr className="m-0"/> */}
      <div className="text-center my-2" style={{backgroundColor:'#281B75',color:'white',borderRadius:'10px'}}>
      {/* <Button variant="dark" disabled>
        Order
      </Button> */}
      <label>Order</label>
      </div>
      <CardGroup>
        <DashboardWidget
          balance={OrderData > 0 ? OrderData : 0}
          title={"No. of order "}
          src={totalSales}
        />
        <DashboardWidget
          balance={`₹${numDifferentiation(
            parseInt(TotalFc > 0 ? TotalFc : 0)
          )}`}
          title={"Total purchase"}
          src={totalSales}
        />
        <DashboardWidget
          balance={`₹${numDifferentiation(
            parseInt(TotalSales > 0 ? TotalSales : 0)
          )}`}
          title={"Total sales"}
          src={totalSales}
        />
        <DashboardWidget
          balance={UserData}
          title={"Total user"}
          src={totalUser}
        />
      </CardGroup>
      {/* <hr /> */}
      {/* PROMOCASH TRANSACTION */}
      <div className="text-center my-2" style={{backgroundColor:'#281B75',color:'white',borderRadius:'10px'}}>

      {/* <Button variant="dark" disabled>
        Promocash
      </Button> */}
      <label>Promocash</label>
      </div>
      <CardGroup>
        <DashboardWidget
          balance={
            PromoCashCredit?.count === undefined ? 0 : PromoCashCredit?.count
          }
          title={"Credit Promocash Transaction"}
          src={wallet}
        />
        <DashboardWidget
          balance={`₹${numDifferentiation(
            PromoCashCredit?.totalCredit === undefined
              ? 0
              : PromoCashCredit?.totalCredit
          )}`}
          title={"Total Promocash Credited Amount"}
          src={wallet}
        />
        <DashboardWidget
          balance={
            PromoCashDebit?.count === undefined ? 0 : PromoCashDebit?.count
          }
          title={"Debit Promocash Transaction"}
          src={wallet}
        />
        <DashboardWidget
          balance={`₹${numDifferentiation(
            PromoCashDebit?.totalDebit === undefined
              ? 0
              : PromoCashDebit?.totalDebit
          )}`}
          title={"Total Promocash Debit Amount"}
          src={wallet}
        />
      </CardGroup>
      {/* <hr /> */}
      {/* MYCASH TRANSACTION */}
      <div className="text-center my-2" style={{backgroundColor:'#281B75',color:'white',borderRadius:'10px'}}>

      {/* <Button variant="dark" disabled classname="disable">
        MyCash
      </Button> */}
      <label>MyCash</label>
      </div>
      <CardGroup>
        <DashboardWidget
          balance={MyCashCredit?.count === undefined ? 0 : MyCashCredit?.count}
          title={"No. MyCash Credit Transaction"}
          src={wallet}
        />
        <DashboardWidget
          balance={`₹${numDifferentiation(
            MyCashCredit?.totalCredit === undefined
              ? 0
              : MyCashCredit?.totalCredit
          )}`}
          title={"Total MyCash Credited"}
          src={wallet}
        />
        <DashboardWidget
          balance={MyCashDebit?.count === undefined ? 0 : MyCashDebit?.count}
          title={"Total MyCash Debitted"}
          src={wallet}
        />
        <DashboardWidget
          balance={`₹${numDifferentiation(
            MyCashDebit?.totalDebit === undefined ? 0 : MyCashDebit?.totalDebit
          )}`}
          title={"Total MyCash Debitted"}
          src={wallet}
        />
      </CardGroup>
    </>
  );
};

export default DashboardMain;
