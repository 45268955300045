import "./App.css";
import Login from "./pages/login/login";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Product from "./pages/product/product";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "./redux/hooks";
import { isLogin } from "./redux/authSlice";
import "../node_modules/react-linechart/dist/styles.css";
import NavbarB from "./components/navbar/Navbar";
import Wallet from "./pages/wallet/wallet";
import DashboardMain from "./pages/dashboad/dashboardMain";
import AdminListComponent from "./pages/admin/adminList";
import UserListComponent from "./pages/user/userList";
import UserForm from "./pages/user/form/userForm";
import AdminForm from "./pages/admin/form/adminForm";
import OfferPage from "./pages/offersDiscount/offersDiscount";
import PopularDestinationComponent from "./pages/popularDestination/popular_destination";
import PopularDestinationForm from "./pages/popularDestination/form/popularDestinationForm";
import OfferForm from "./pages/offersDiscount/form/offersForm";
import Footer from "./components/footer/footer";
import ChangePassword from "./pages/admin/changePassword/changePassword";
import OrderList from "./pages/orderDetails/orderList";
import OrderDetailsList from "./pages/orderDetails/orderDetailsList";
import SectorDetails from "./pages/orderDetails/sectorDetails";
import PassengerDetail from "./pages/orderDetails/passengerList/passengerDetail";
import FaqForm from "./pages/faq/faqForm/faqForm";
import FAQ from "./pages/faq/faq";
import BookingDetails from "./pages/orderDetails/bookingDetails";
import CmsForm from "./pages/cms/cmsForm";
import CMS from "./pages/cms/cms";
import CmsView from "./pages/cms/cmsView";
import AmendmentComponent from "./pages/amendment/amendment";
import AccountDetails from "./pages/admin/accountDetails/accountDetails";
import RefundComp from "./pages/refund/refund";
import Holidays from "./pages/holidays/holidays";
import Airport from "./pages/airport/airport";
import AddAirportForm from "./pages/airport/addAirportForm";

function App() {
  const isLog = useAppSelector(isLogin);
  return (
    <div>
      <ToastContainer />
      {isLog ? (
        <div>
          {/* <MainSideBar/> */}
          <NavbarB>
            <Routes>
              {/* <Route path="/dashboardMain" element={<DashboardMain />} /> */}
              <Route path="/" element={<DashboardMain />} />
              <Route path="/userList" element={<UserListComponent />} />

              {/* <Route path="/userList/:id" element={<UserList />} /> */}
              <Route path="/adminForm" element={<AdminForm />} />
              <Route path="/adminForm/:id" element={<AdminForm />} />
              <Route path="/userForm" element={<UserForm />} />
              <Route path="/userForm/:id" element={<UserForm />} />
              <Route path="/adminList" element={<AdminListComponent />} />
              <Route path="//userwallet" element={<Wallet />} />
              <Route path="/adminwallet" element={<AccountDetails />} />
              <Route path="/flights" element={<OrderList />} />
              <Route path="/amendment" element={<AmendmentComponent />} />
              <Route path="/refund" element={<RefundComp />} />
              <Route path="/holidays" element={<Holidays />} />
              <Route path="/orderDetailsList" element={<OrderDetailsList />} />
              <Route
                path="/orderDetailsList/:id"
                element={<OrderDetailsList />}
              />
              <Route path="/bookingDetails/:id" element={<BookingDetails />} />
              <Route
                path="/orderDetailsList/:id/bookingDetails/:booking_id"
                element={<BookingDetails />}
              />
              <Route path="/sectorDetails" element={<SectorDetails />} />
              <Route path="/sectorDetails/:id" element={<SectorDetails />} />
              <Route
                path="/passengerDetails/:id"
                element={<PassengerDetail />}
              />
              <Route path="/product" element={<Product />} />
              <Route path="/productList" element={<Product />} />
              <Route path="/offersDiscount" element={<OfferPage />} />
              <Route path="/offersForm" element={<OfferForm />} />
              <Route path="/offersForm/:id" element={<OfferForm />} />
              <Route path="/passwordChange" element={<ChangePassword />} />
              <Route
                path="/popularDestination"
                element={<PopularDestinationComponent />}
              />
              <Route
                path="/destinationForm"
                element={<PopularDestinationForm />}
              />
              <Route
                path="/destinationForm/:id"
                element={<PopularDestinationForm />}
              />
              <Route path="/cms" element={<CMS />} />
              <Route path="/CmsForm" element={<CmsForm />} />
              <Route path="/CmsView" element={<CmsView />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/airport" element={<Airport />} />
              <Route path="/addAirportForm" element={<AddAirportForm />} />
              <Route path="/addAirportForm/:id" element={<AddAirportForm />} />
              <Route path="/faqForm/faqForm" element={<FaqForm />} />
              <Route path="/faqForm/faqForm/:id" element={<FaqForm />} />
            </Routes>
          </NavbarB>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      )}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
