import React from "react";
import "./login.css";
import { Container, Form } from "react-bootstrap";
import MainTitle from "../../components/mainTitile/mainTitle";
import { useFormik } from "formik";
import CustomButton from "../../components/button/button";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { authenticateUser } from "../../redux/authSlice";
import Logo from "../../components/logo/logo";

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid Email Address")
      .required("Please enter Email Address"),

    password: yup
      .string()
      .min(8, "Password should be more than 8 digits")
      .required("Please enter Password"),
  });
  const onSubmit = (values) => {
    dispatch(authenticateUser(values));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <Container className="form-con">
        <Form onSubmit={formik.handleSubmit} className="formDiv">
          <div className="justify-content-center d-flex mb-3">
            <Logo />
          </div>

          <MainTitle heading={"Login"} textAlign={"center"} />

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              {...formik.getFieldProps("email")}
              name={"email"}
              placeholder="Enter email"
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="error">{formik.errors.email}</p>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              {...formik.getFieldProps("password")}
              name="password"
              placeholder="Password"
            />
            {formik.touched.password && formik.errors.password ? (
              <p className="error">{formik.errors.password}</p>
            ) : null}
          </Form.Group>

          <div className="text-center">
            <CustomButton buttonText={"submit"} type={"submit"} />
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default Login;
