import React from "react";
import { useParams } from "react-router-dom";

const BookingDetails = () => {
  const { id } = useParams();
  console.log("booking id", id);
  return <div>{id}</div>;
};

export default BookingDetails;
