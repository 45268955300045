import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Col, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { adminData, deleteUser } from "../../services/authServices";
import MainTitle from "../../components/mainTitile/mainTitle";
import TablePagination from "../../components/pagination/tablePagination";
import CustomButton from "../../components/button/button";
import CustomLoader from "../../components/loader/loader";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/commanFile";

const UserListComponent = () => {
  const [adminD, setAdminD] = useState([]);
  const [isLoading, setLoading] = useState(false);
  let navigate = useNavigate();

  const getuserdata = async () => {
    setLoading(true);
    try {
      const res = await adminData();
      const filteredData = res?.data?.Data?.getAllData?.filter(
        (item) => item.roleIdf === 2
      );
      setAdminD(filteredData);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const formpage = () => {
    let path = "/userForm";
    navigate(path);
  };

  const handleDelete = (userid, e) => {
    deleteUser(userid)
      .then((res) => {
        getuserdata();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getuserdata();
  }, []);

  // user Data table
  const columns = [
    {
      name: "TMT User ID",
      selector: (row) => row?.tmt_userId,
    },
    {
      name: "Image",
      selector: (row) => (
        <div className="d-flex">
          <img src={row.image} alt="default" width="20px" style={{borderRadius:'10px'}} />
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => (
        <div className="d-flex">
         <label style={{textTransform:'capitalize'}}>{`${row.title ? row.title : ""} ${row?.firstname ? row?.firstname : ""} ${row.lastname ? row.lastname : ""}`}</label>
        </div>
      ),
    },
    {
      name: "Email",  
      selector: (row) => row?.email,
    },
    {
      name: "mobile",
      selector: (row) => row?.mobile,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="d-flex">
          <div className="mx-3">
            <Link
              style={{ textDecoration: "none" }}
              to={`/userForm/${row._id}`}
            >
              <BiEdit color="green" size={20} />
            </Link>
          </div>
          <div>
            <Link
              style={{ textDecoration: "none" }}
              onClick={() => handleDelete(row._id)}
            >
              <AiFillDelete color="red" size={20} />
            </Link>
          </div>
        </div>
      ),
    },
  ];

  

  return (
    <>
      {/* <CustomLoader isLoading={isLoading} /> */}
      <Row>
        <Col>
          <Row>
            <Col sm={10} className="pt-3">
              <MainTitle heading={"User List"} textAlign={"center"} />
            </Col>

            <Col sm={2}>
              <CustomButton buttonText={"Add"} onClick={formpage} />
            </Col>
          </Row>
          <DataTable
            dense
            customStyles={customStyles}
            progressPending={isLoading}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            columns={columns}
            data={adminD}
            pagination
          />
        </Col>
      </Row>
    </>
  );
};

export default UserListComponent;
