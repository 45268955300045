import axios from "axios";
import { BASE_URL } from "../constant/api";
import { createBrowserHistory } from "history";
import { ErrorTost, SuccessTost } from "../components/tostpop/tostPop";
import {
  removeTokens,
  getAccessToken,
  setAccessToken,
  setRefreshToken,
} from "./localStorage";

axios.defaults.baseURL = BASE_URL;
export const History = createBrowserHistory();

const getHeaderInfo = async function () {
  let token = getAccessToken();
  if (token) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }
};

const handleResponse = (response) => {
  // console.log('admin details',response);
  if (response?.data?.Status === 401 || response?.data?.Status === 403) {
    removeTokens();
    window.location.href = "/";
    // History.push("/");
  }

  if (response.data.Status === 200 || response.data.Status === 202 ||  response.data.Status === 201) {
    if (response.data.Data.access !== undefined) {
      setAccessToken(response.data.Data.access);
      setRefreshToken(response?.data?.Data?.refresh);
    }
    SuccessTost(response.data.Message);
  } else {
    ErrorTost(response.data.Message);
    History.push("/");
  }

  return response;
};

export const post = async function (url, body) {
  let header = await getHeaderInfo();

  try {
    let resp = await axios.post(url, body);
    return handleResponse(resp);
  } catch (err) {
    return handleResponse(err.response);
  }
};

// FOR PUT METHOD
export const put = async function (url, params) {
  try {
    let resp = await axios.patch(url, params);

    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

// FOR DELETE METHOD
export const deleteApi = async function (url, _id) {
  try {
    let resp = await axios.delete(url, _id);
    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};
export const deleteMethod = async function (url, params) {
  try {
    let resp = await axios.delete(url, params);
    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

//  FOR GETTING COOKIES FROM BACKEND
export const get = async function (url) {
  // const cookie = getAccessToken();
  // let header = await getHeaderInfo();

  try {
    let resp = await axios.get(url);
    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

export const getById = async function (url, params) {
  // const cookie = getAccessToken();
  // let header = await getHeaderInfo();

  try {
    let resp = await axios.get(url,params);
    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

export const postById = async function (url, params) {
  // const cookie = getAccessToken();
  // let header = await getHeaderInfo();

  try {
    let resp = await axios.post(url,params);
    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

axios.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    Promise.reject(error);
  }
);
