import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import MainTitle from "../../components/mainTitile/mainTitle";
import { useFormik } from "formik";
import * as yup from "yup";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { addAirport, getSingleAirport, updateAirport } from "../../services/authServices";

const AddAirportForm = () => {
  let initialValues = {
    code: "",
    name: "",
    city: "",
    citycode: "",
    country: "",
    countrycode: "",
  };
  const [airportData, setAirportData] = useState(initialValues);
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  let navigate = useNavigate();

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setAirportData({
  //     ...airportData,
  //     [name]: value,
  //   });
  // };

  const getSingleData = () => {
    getSingleAirport(id)
      .then((res) => {
        setAirportData(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    let params = {
      code: formik.values.code,
      name: formik.values.name,
      city: formik.values.city,
      citycode: formik.values.citycode,
      country: formik.values.country,
      countrycode: formik.values.countrycode,
    };
    if (id === undefined) {
      // post api..
      addAirport(params)
        .then((res) => {
          if (res) {
            navigate("/airport");
          }
        })
        .catch((err) => {
          console.log("post data response", err);
        });
      console.log("add airport data", formik.values);
    } else {
      updateAirport(id,params).then((res)=>{
      if(res){
        navigate("/airport");
      }
        console.log("update airport data",res);
      }).catch((err)=>{
        console.log("err airport data",err);
      
      })
    }
  };
  const validationSchema = yup.object({
    code: yup.string().required("Required"),
    name: yup.string().required("Please enter Name"),
    city: yup.string().required("Please enter city"),
    citycode: yup.string().required("Please enter city code"),
    country: yup.string().required("Please enter country"),
    countrycode: yup.string().required("Please enter countrycode"),
  });

  const formik = useFormik({
    enableReinitialize: airportData,
    initialValues: airportData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    id !== undefined && getSingleData();
  }, [id]);

  return (
    <Container className="d-flex justify-content-center">
      <Form onSubmit={formik.handleSubmit} method="POST">
        <MainTitle heading={"Airport Form"} textAlign={"center"} />
        <Row className="my-3">
          <Col lg={6}>
            <Form.Group controlId="code">
              <Form.Control
                type="text"
                name="code"
                placeholder="Code"
                value={formik.values.code}
                onChange={formik.handleChange}
                isInvalid={formik.touched.code && !!formik.errors.code}
                {...formik.getFieldProps("code")}
              />

              {formik.errors.code  && formik.touched.code ? (
                <p className="error">{formik.errors.code}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group controlId="citycode">
              <Form.Control
                type="text"
                name="citycode"
                placeholder="City Code"
                value={formik.values.citycode}
                onChange={formik.handleChange}
                isInvalid={formik.touched.citycode && !!formik.errors.citycode}
                {...formik.getFieldProps("citycode")}
              />
              {formik.errors.citycode && formik.touched.citycode ? (
                <p className="error">{formik.errors.citycode}</p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Form.Group controlId="name">
              <Form.Control
                type="text"
                name="name"
                placeholder="Airport name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.touched.name && !!formik.errors.name}
                {...formik.getFieldProps("name")}
              />

              {formik.errors.name && formik.touched.name ? (
                <p className="error">{formik.errors.name}</p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row className="my-3">
          <Col>
            <Form.Group controlId="city">
              <Form.Control
                type="text"
                name="city"
                placeholder="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                {...formik.getFieldProps("city")}
                isInvalid={formik.touched.city && !!formik.errors.city}
              />
              {formik.errors.city && formik.touched.city ? (
                <p className="error">{formik.errors.city}</p>
              ) : null}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="country">
              <Form.Control
                type="text"
                name="country"
                placeholder="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                isInvalid={formik.touched.country && !!formik.errors.country}
                {...formik.getFieldProps("country")}
              />
              {formik.errors.country && formik.touched.country ? (
                <p className="error">{formik.errors.country}</p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Form.Group controlId="countrycode">
              <Form.Control
                type="text"
                name="countrycode"
                placeholder="countrycode"
                value={formik.values.countrycode}
                onChange={formik.handleChange}
                isInvalid={formik.touched.countrycode && !!formik.errors.countrycode}
                {...formik.getFieldProps("countrycode")}
              />
              {formik.errors.countrycode && formik.touched.countrycode ? (
                <p className="error">{formik.errors.countrycode}</p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-3 ">
          <Col className="d-flex justify-content-end">
            <Button type="submit">Submit</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddAirportForm;
