import React, { useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Pagination,
  Row,
} from "react-bootstrap";
import "./tablePagination.css";



const TablePagination = ({
  page,
  pageData,
  handleRowSize,
  setPage,
  pageCount,
}) => {
  // pagination logic here

  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
  };

  // handle previous
  const handlePrevios = () => {
    if (page === 1) return page;
    setPage(page - 1);
  };
  return (
    
      <Row className="my-2">
        <Col>
          <DropdownButton
            id="dropdown-basic-button"
            variant="transparent"
            title={`Row per page : ${pageData}`}
          >
            <Dropdown.Item onClick={handleRowSize} href="#">
              10
            </Dropdown.Item>
            <Dropdown.Item onClick={handleRowSize} href="#">
              15
            </Dropdown.Item>
            <Dropdown.Item onClick={handleRowSize} href="#">
              25
            </Dropdown.Item>
            <Dropdown.Item onClick={handleRowSize} href="#">
              35
            </Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col className="d-flex justify-content-end">
          <Pagination>
            <Pagination.Prev onClick={handlePrevios} disabled={page === 1} />

            {Array(pageCount)
              .fill(null)
              ?.map((ele, index) => {
                return (
                  <>
                    <Pagination.Item
                      active={page === index + 1 ? true : false}
                      onClick={() => setPage(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  </>
                );
              })}
            <Pagination.Next
              onClick={handleNext}
              disabled={page === pageCount}
            />
          </Pagination>
        </Col>
      </Row>
    
  );
};

export default TablePagination;
