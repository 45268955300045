import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import OrderList from "../orderDetails/orderList";
import { useState, useRef, useEffect } from "react";
import {
  amend_order_data,
  amendment_details,
  get_amendment_data,
  submit_amendment,
} from "../../services/authServices";
import moment from "moment/moment";
import "./amend.css";
import { HiArrowLongRight } from "react-icons/hi2";
import logo from "../../../src/assets/SpiceJetLogo.png";
import { ErrorTost, SuccessTost } from "../../components/tostpop/tostPop";

const AmendmentComponent = () => {
  const [OrderID, setOrderID] = useState([]);
  const [SingleData, setSingleData] = useState([]);
  const [sectoreInfo, setSectoreInfo] = useState([]);
  const [sectoreInfoReturn, setSectoreInfoReturn] = useState([]);
  const [PassengerInfo, setPassengerInfo] = useState([]);
  const [passengerSelected, setPassengerSelected] = useState(false);
  const [GetTripJackID, setGetTripJackID] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [adultData, setAdultData] = useState([]);
  //   const inputRef = useRef(null);

  let travellers = [
    {
      fn: "",
      ln: "",
    },
  ];
  const [removePass, setRemovePass] = useState(travellers);

  useEffect(() => {
    if (
      PassengerInfo &&
      PassengerInfo?.length > 0 &&
      passengerSelected === false
    ) {
      setPassengerSelected(new Array(PassengerInfo?.length).fill(false));
    }
  }, [PassengerInfo]);
  const handleData = (pData, ind) => {
    let newpass = [...removePass];
    let { fN, lN } = pData;
    newpass.push({ fN, lN });
    setRemovePass(newpass);
  };
  const DetailedDate = (data) => {
    const d = new Date(data);
    return moment(data).format("YYYY-MM-DD");
  };
  const FullDate = (data) => {
    const d = new Date(data);
    return moment(data).format("MMMM Do YYYY, h:mm:ss a");
  };
  //  For Taking Input
  const handlechange = (e) => {
    setOrderID(e.target.value);
  };
  const handleResetClick = () => {
    setOrderID("");
    setSingleData("");
  };

  const handlecancllation = async (e) => {
    e.preventDefault();
    const params = {
      request: "ONE",
      orderId: OrderID,
    };
    console.log({ params });
    const res = await amend_order_data(params);
    setSingleData(res?.data?.Data[0]);
    console.log({ SingleData });
    setSectoreInfo(res?.data?.Data[0]?.trip_info?.sector_details[0]?.sI);
    setSectoreInfoReturn(res?.data?.Data[0].trip_info?.sector_details[1]?.sI);
    setPassengerInfo(res?.data.Data[0].trip_info?.travellerInfo);
  };

  const [cancelFlight, setCancelFlight] = useState([]);
  const [cancelFlightData, setCancelFlightData] = useState([]);
  const [cancelFlightReturnData, setCancelFlightReturnData] = useState([]);
  // FOR MODAL

  const [show, setShow] = useState(false);
  const [sectoreShow, setSectoreShow] = useState(false);
  const [passengerShow, setPassengerShow] = useState(false);
  const [RemarkText, setRemarkText] = useState();
  const [amendmentId, setAmendmentId] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePassengerClose = () => setPassengerShow(false);
  const handleSectoreClose = () => setSectoreShow(false);

  const [isLoading, setIsLoading] = useState(false);
  const [cancelType, setCancelType] = useState({
    FullTripCencel: true,
    PassengerCencel: false,
    TripCencel: false,
  });

  // full trip cancellation
  const fulltripCancellation = (data) => {
    setIsLoading(true);

    get_amendment_data(data)
      .then((res) => {
        if (res?.data?.status?.httpStatus === 400) {
          ErrorTost(res?.data?.errors[0]?.message);
          setIsLoading(false);
          setPassengerShow(false);
          setRemovePass("");
          setSectoreShow(false);
        }
        if (res?.data?.trips) {
          setSectoreShow(false);
          setIsLoading(false);

          setShow(true);
          setRemovePass("");
        }
        setCancelFlight(res?.data?.trips);
        setCancelFlightData(res?.data?.trips && res?.data?.trips[0]);
        setCancelFlightReturnData(res?.data?.trips && res?.data?.trips[1]);
      })
      .catch((er) => {
        console.error(er);
      });
  };

  // final submit the cancelation
  const submitAmendment = () => {
    // for full trip paload
    // const fetchAmmendCharges = {
    //   bookingId: SingleData.merchant_orderID,
    //   remarks: RemarkText,
    //   type: "CANCELLATION",
    // };

    console.log("BookingID", SingleData);
    const params = {
      bookingId: SingleData?.merchant_orderId,
      remarks: RemarkText,
      type: "CANCELLATION",
      trips: [
        {
          src: sectoreInfo[0].da.code,
          dest: sectoreInfo[sectoreInfo.length - 1].aa.code,
          departureDate: DetailedDate(sectoreInfo[0].dt),
          // travellers: removePass,
        },
      ],
    };

    submit_amendment(params)
      .then((res) => {
        if (res?.data?.amendmentId) {
          SuccessTost("cancellation Confirm");
          setShow(false);
          setAmendmentId(res?.data?.amendmentId);
          fullCancel(res?.data?.amendmentId);
          sectoreShow(false);
        }
      })
      .catch((er) => {
        console.error(er);
      });
  };

  const fullCancel = (data) => {
    const params = {
      amendmentId: data,
    };
    amendment_details(params)
      .then((res) => {
        SuccessTost("Cancellation " + res?.data.amendmentStatus);
      })
      .catch((er) => {
        console.error("amendment details error", er);
      });
  };

  const handleCancellation = (data) => {
    // PassengerCencel
    // TripCencel
    // FullTripCencel
    if (data === "PassengerCencel") {
      setCancelType({ PassengerCencel: !cancelType?.PassengerCencel });
    } else if (data === "TripCencel") {
      setCancelType({ TripCencel: !cancelType?.TripCencel });
    } else {
      setCancelType({ FullTripCencel: cancelType.FullTripCencel !== false });
    }
  };

  const submitCancellation = () => {
    // for full trip cancellation
    let params = {
      bookingId: SingleData?.merchant_orderId,
      type: "CANCELLATION",
    };

    if (cancelType.PassengerCencel === true) {
      setPassengerShow(true);
    } else if (cancelType.TripCencel === true) {
      setSectoreShow(true);
    } else if (cancelType.FullTripCencel === true) {
      fulltripCancellation(params);
    } else {
    }
  };

  const handleTripCancellation = () => {
    let params = {
      bookingId: SingleData?.merchant_orderId,
      type: "CANCELLATION",
      trips: [
        {
          src: tripdata.data?.length > 0 && tripdata?.data[0]?.da?.code,
          dest:
            tripdata.data?.length > 0 &&
            tripdata?.data[tripdata?.data?.length - 1]?.aa?.code,
          departureDate:
            tripdata.data?.length > 0 &&
            moment(tripdata?.data[0]?.at).format("YYYY-MM-DD"),
        },
      ],
    };
    console.log({ Data: params.bookingId });
    fulltripCancellation(params);
  };

  const [tripdata, setTripdata] = useState([]);
  const [tripdataReturn, setTripdataReturn] = useState([]);
  const handleSectoreDetails = (data, operation) => {
    operation === "oneway" && setTripdata({ data });
    operation === "return" && setTripdataReturn({ data });
  };

  const handlePassengerCancellation = () => {
    let params = {};
    if (sectoreInfoReturn?.length > 0 && sectoreInfoReturn !== undefined) {
      params = {
        bookingId: SingleData?.merchant_orderId,
        type: "CANCELLATION",
        trips: [
          {
            src: sectoreInfo[0].da.code,
            dest: sectoreInfo[sectoreInfo.length - 1]?.aa?.code,
            departureDate: moment(sectoreInfo[0]?.dt).format("YYYY-MM-DD"),
            travellers: removePass,
          },
          {
            src: sectoreInfoReturn.length > 0 && sectoreInfoReturn[0]?.da?.code,
            dest: sectoreInfoReturn[sectoreInfoReturn.length - 1]?.aa?.code,
            departureDate: moment(sectoreInfoReturn[0]?.dt).format(
              "YYYY-MM-DD"
            ),
            travellers: removePass,
          },
        ],
      };
    } else {
      params = {
        bookingId: SingleData?.merchant_orderId,
        type: "CANCELLATION",
        trips: [
          {
            src: sectoreInfo[0].da.code,
            dest: sectoreInfo[sectoreInfo.length - 1]?.aa?.code,
            departureDate: moment(sectoreInfo[0]?.dt).format("YYYY-MM-DD"),
            travellers: removePass,
          },
        ],
      };
    }

    fulltripCancellation(params);
  };
  return (
    <Card className="p-3">
      <Container>
        <Form style={{ maxWidth: "25rem" }}>
          <FormGroup>
            <FormLabel>
              <b>Booking ID</b>
            </FormLabel>
            <FormControl
              name="id"
              type="text"
              placeholder="Search by OrderID"
              value={OrderID}
              onChange={(e) => handlechange(e)}
            />
            <Button
              variant="light"
              type="submit"
              className="mt-1"
              onClick={handlecancllation}
            >
              <b>Search</b>
            </Button>
            <Button
              variant="light"
              className="mt-1 mx-3"
              onClick={handleResetClick}
              type="reset"
            >
              <b> Reset</b>
            </Button>
          </FormGroup>
        </Form>
      </Container>
      <hr />
      <Card className="bg-light">
        <Card.Header>
          <h3>
            <b>Ordder List</b>
          </h3>
        </Card.Header>

        <Card.Body>
          {SingleData?.length === 0 ? (
            <OrderList />
          ) : (
            <div>
              <Container>
                {/* Type and Booking ID */}
                <Row className="bg-white p-3 mb-4 boxcss">
                  <Col sm={3} className="mb-3">
                    <p className="text-muted d-inline-flex fw-bold">Type:</p>{" "}
                    Cancellation
                  </Col>
                  <Col sm={5} className="mb-3">
                    <p className="text-muted d-inline fw-bold">TMT Order ID:</p>{" "}
                    {SingleData.tmt_order_id}
                  </Col>
                </Row>
                <Row className="bg-white p-3 mb-4 boxcss">
                  <Col sclassName="mb-3">
                    <Form.Check
                      inline
                      label="Full Cancellation"
                      type={"radio"}
                      name="FullTripCencel"
                      value={cancelType?.FullTripCencel}
                      className="mx-2"
                      onChange={() => handleCancellation("FullTripCencel")}
                      checked={cancelType?.FullTripCencel === true}
                    />
                    {PassengerInfo.length > 1 && (
                      <>
                        <Form.Check
                          inline
                          value={cancelType?.PassengerCencel}
                          name="PassengerCencel"
                          label="Passenger Cancellation"
                          onChange={() => handleCancellation("PassengerCencel")}
                          className="mx-2"
                          checked={cancelType?.PassengerCencel === true}
                          type={"radio"}
                        />
                      </>
                    )}

                    {sectoreInfoReturn?.length > 0 && (
                      <>
                        <Form.Check
                          value={cancelType?.TripCencel}
                          name="TripCencel"
                          onChange={() => handleCancellation("TripCencel")}
                          inline
                          className="mx-2"
                          label="Trip Cancellation"
                          checked={cancelType?.TripCencel === true}
                          type={"radio"}
                        />
                      </>
                    )}
                  </Col>
                  <Col sm={3}>
                    <Button
                      type="submit"
                      onClick={submitCancellation}
                      disabled={isLoading}
                    >
                      {" "}
                      {isLoading ? "Loading..." : "Cancellation"}
                    </Button>
                  </Col>
                </Row>

                <Row className="bg-white p-2 mb-3 boxcss">
                  {/* Flight Details */}
                  <Col lg={12} className="fw-bold mb-2">
                    {sectoreInfo?.length > 0 && sectoreInfo[0].da?.city}{" "}
                    <HiArrowLongRight className="mx-2" />
                    {sectoreInfo?.length > 0 &&
                      sectoreInfo[sectoreInfo?.length - 1]?.aa.city +
                        " On"}{" "}
                    {sectoreInfo !== undefined
                      ? moment(sectoreInfo[0]?.dt).format("MMMM Do YYYY")
                      : "something went wrong"}
                    {/* On {moment(sectoreInfo[0]?.dt).format("MMMM Do YYYY")} */}
                  </Col>
                  {sectoreInfo?.length > 0 &&
                    sectoreInfo?.map((i, ind) => {
                      return (
                        <>
                          <Col lg={12}>
                            <Row className="flightcomp">
                              <Col sm={2}>
                                <div className="d-flex align-items-center">
                                  {/* Flight Logo */}
                                  <img
                                    src={logo}
                                    alt="flight"
                                    width={"40px"}
                                    height={"40px"}
                                  />
                                  {/* Flight Name and model number */}
                                  <div className={"d-grid"}>
                                    <label>{i?.fD?.aI?.name}</label>
                                    <label>
                                      {i?.fD?.aI?.code}-{i?.fD?.fN} {i.id}
                                    </label>
                                  </div>
                                </div>
                              </Col>
                              <Col sm={4} className="d-grid">
                                {i?.da?.city} {i?.da?.country} ({i?.da?.name}) -{" "}
                                {i?.da?.code}
                                <br />{" "}
                                {moment(i.dt).format("MMMM Do YYYY, h:mm:ss a")}
                              </Col>
                              <Col
                                sm={1}
                                className="d-flex justify-content-center align-items-center"
                              >
                                <HiArrowLongRight />
                              </Col>
                              <Col sm={5} className="d-grid">
                                {i?.aa?.city} {i?.aa?.country} ({i?.aa?.name}) -{" "}
                                {i?.aa?.code}
                                <br />
                                {moment(i.at).format("MMMM Do YYYY, h:mm:ss a")}
                              </Col>
                            </Row>
                          </Col>
                        </>
                      );
                    })}
                </Row>
                <Row className="bg-white p-2 mb-3 boxcss">
                  {/* Flight Details */}
                  <Col lg={12} className="fw-bold mb-2">
                    {sectoreInfoReturn?.length > 0 &&
                      sectoreInfoReturn[0].da?.city}{" "}
                    <HiArrowLongRight className="mx-2" />
                    {sectoreInfoReturn?.length > 0 &&
                      sectoreInfoReturn[sectoreInfoReturn?.length - 1]?.aa
                        .city + " On"}{" "}
                    {sectoreInfoReturn !== undefined
                      ? moment(sectoreInfoReturn[0]?.dt).format("MMMM Do YYYY")
                      : "something went wrong"}
                    {/* On {moment(sectoreInfo[0]?.dt).format("MMMM Do YYYY")} */}
                  </Col>
                  {sectoreInfoReturn?.length > 0 &&
                    sectoreInfoReturn?.map((i, ind) => {
                      return (
                        <>
                          <Col lg={12}>
                            <Row className="flightcomp">
                              <Col sm={2}>
                                <div className="d-flex align-items-center">
                                  {/* Flight Logo */}
                                  <img
                                    src={logo}
                                    alt="flight"
                                    width={"40px"}
                                    height={"40px"}
                                  />
                                  {/* Flight Name and model number */}
                                  <div className={"d-grid"}>
                                    <label>{i?.fD?.aI?.name}</label>
                                    <label>
                                      {i?.fD?.aI?.code}-{i?.fD?.fN} {i.id}
                                    </label>
                                  </div>
                                </div>
                              </Col>
                              <Col sm={4} className="d-grid">
                                {i?.da?.city} {i?.da?.country} ({i?.da?.name}) -{" "}
                                {i?.da?.code}
                                <br />{" "}
                                {moment(i.dt).format("MMMM Do YYYY, h:mm:ss a")}
                              </Col>
                              <Col
                                sm={1}
                                className="d-flex justify-content-center align-items-center"
                              >
                                <HiArrowLongRight />
                              </Col>
                              <Col sm={5} className="d-grid">
                                {i?.aa?.city} {i?.aa?.country} ({i?.aa?.name}) -{" "}
                                {i?.aa?.code}
                                <br />
                                {moment(i.at).format("MMMM Do YYYY, h:mm:ss a")}
                              </Col>
                            </Row>
                          </Col>
                        </>
                      );
                    })}
                </Row>

                <Modal show={show} onHide={handleShow}>
                  <Container className="mt-3">
                    <Row>
                      <Col className="d-flex align-items-center">
                        <h4 className="mb-0">Cancelation Details</h4>
                      </Col>
                      <Col className="d-flex justify-content-center">
                        {SingleData.merchant_orderId
                          ? SingleData.merchant_orderId
                          : "NA"}
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <hr className="" />
                    <Row className="">
                      <Col sm={4}>
                        <label className="secoreHeading">
                          Depart : {cancelFlightData?.src}
                        </label>
                      </Col>
                      <Col sm={3}>
                        <label className="secoreHeading">
                          {" "}
                          arrival : {cancelFlightData?.dest}
                        </label>
                      </Col>
                      <Col>
                        <label style={{ fontSize: "12px" }}>
                          {" "}
                          Flight Number :{" "}
                          {cancelFlightData?.flightNumbers &&
                            cancelFlightData?.flightNumbers[0]}{" "}
                          {cancelFlightData?.airlines &&
                            cancelFlightData?.airlines[0]}{" "}
                        </label>
                      </Col>
                    </Row>
                    <hr />
                    <Container className="modalBody">
                      <Row>
                        <Col>
                          <h6 className="feesStyle">ADULT * </h6>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col>
                          <label className="feesStyle"> Airlines Fees : </label>
                          <label className="feesStyle">
                            Treatmytravel fees :
                          </label>
                        </Col>
                        <Col>
                          <h6 className="feesStyle">
                            {cancelFlightData?.amendmentInfo?.ADULT
                              ?.amendmentCharges + 400}{" "}
                            * 1
                          </h6>
                          <h6 className="feesStyle">
                            400 * 1
                            {/* {(cancelFlightData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}
                          </h6>
                        </Col>
                      </Row>
                      {cancelFlightData?.amendmentInfo?.CHILD && (
                        <>
                          <Row>
                            <Col>
                              <h6>CHILD * </h6>
                            </Col>
                          </Row>
                          <Row className="my-2">
                            <Col>
                              <label className="feesStyle">
                                {" "}
                                Airlines Fees :{" "}
                              </label>
                              <label className="feesStyle">
                                Treatmytravel fees :
                              </label>
                            </Col>
                            <Col>
                              <h6 className="feesStyle">
                                {cancelFlightData?.amendmentInfo?.CHILD
                                  ?.amendmentCharges + 400}{" "}
                                * 1
                              </h6>
                              <h6 className="feesStyle">
                                400 * 1
                                {/* {(cancelFlightData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}
                              </h6>
                            </Col>
                          </Row>
                        </>
                      )}

                      {cancelFlightData?.amendmentInfo?.CHILD && (
                        <>
                          <Row>
                            <Col>
                              <h6>INFANT * </h6>
                            </Col>
                          </Row>
                          <Row className="my-2">
                            <Col>
                              <label className="feesStyle">
                                {" "}
                                Airlines Fees :{" "}
                              </label>
                              <label className="feesStyle">
                                Treatmytravel fees :
                              </label>
                            </Col>
                            <Col>
                              <h6 className="feesStyle">
                                {cancelFlightData?.amendmentInfo?.INFANT
                                  ?.amendmentCharges + 400}{" "}
                                * 1
                              </h6>
                              <h6 className="feesStyle">
                                400
                                {/* {(cancelFlightData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}{" "}
                                * 1
                              </h6>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Container>
                    {cancelFlight?.length > 1 && (
                      <>
                        <hr />
                        <Row className="my-2">
                          <Col sm={4}>
                            <label className="secoreHeading">
                              Depart : {cancelFlightReturnData?.src}
                            </label>
                          </Col>
                          <Col sm={3}>
                            <label className="secoreHeading">
                              {" "}
                              arrival : {cancelFlightReturnData?.dest}
                            </label>
                          </Col>
                          <Col>
                            <label style={{ fontSize: "12px" }}>
                              {" "}
                              Flight Number :{" "}
                              {cancelFlightReturnData?.flightNumbers &&
                                cancelFlightReturnData?.flightNumbers[0]}{" "}
                              {cancelFlightReturnData?.airlines &&
                                cancelFlightReturnData?.airlines[0]}{" "}
                            </label>
                          </Col>
                        </Row>
                        <hr />
                        <Container className="modalBody">
                          <Row>
                            <Col>
                              <h6 className="mb-0">ADULT * </h6>
                            </Col>
                          </Row>
                          <Row className="my-2">
                            <Col>
                              <label className="feesStyle">
                                {" "}
                                Airlines Fees :{" "}
                              </label>
                              <label className="feesStyle">
                                Treatmytravel fees :
                              </label>
                            </Col>
                            <Col>
                              <h6 className="feesStyle">
                                {cancelFlightReturnData?.amendmentInfo?.ADULT
                                  ?.amendmentCharges + 400}{" "}
                                * 1
                              </h6>
                              <h6 className="feesStyle">
                                400 * 1
                                {/* {(cancelFlightReturnData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}
                              </h6>
                            </Col>
                          </Row>

                          {cancelFlightReturnData?.amendmentInfo?.CHILD && (
                            <>
                              <Row>
                                <Col>
                                  <h6>CHILD * </h6>
                                </Col>
                              </Row>
                              <Row className="my-2">
                                <Col>
                                  <label className="feesStyle">
                                    {" "}
                                    Airlines Fees :{" "}
                                  </label>
                                  <label className="feesStyle">
                                    Treatmytravel fees :
                                  </label>
                                </Col>
                                <Col>
                                  <h6 className="feesStyle">
                                    {cancelFlightReturnData?.amendmentInfo
                                      ?.CHILD?.amendmentCharges + 400}{" "}
                                    * 1
                                  </h6>
                                  <h6 className="feesStyle">
                                    400 * 1
                                    {/* {(cancelFlightReturnData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}
                                  </h6>
                                </Col>
                              </Row>
                            </>
                          )}

                          {cancelFlightReturnData?.amendmentInfo?.INFANT && (
                            <>
                              <Row>
                                <Col>
                                  <h6>INFANT * </h6>
                                </Col>
                              </Row>
                              <Row className="my-2">
                                <Col>
                                  <label className="feesStyle">
                                    {" "}
                                    Airlines Fees :{" "}
                                  </label>
                                  <label className="feesStyle">
                                    Treatmytravel fees :
                                  </label>
                                </Col>
                                <Col>
                                  <h6 className="feesStyle">
                                    {cancelFlightReturnData?.amendmentInfo
                                      ?.INFANT?.amendmentCharges + 400}{" "}
                                    * 1
                                  </h6>
                                  <h6 className="feesStyle">
                                    400
                                    {/* {(cancelFlightReturnData?.amendmentInfo?.ADULT?.refundAmount - 400)} */}{" "}
                                    * 1
                                  </h6>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Container>
                      </>
                    )}
                    {/* <Row className="my-2">
                      <Col>
                        <label> total fare :{cancelFlightReturnData?.amendmentInfo?.ADULT?.totalFare}</label>
                      </Col>

                    </Row> */}
                    <hr />
                    <Row>
                      <Col>
                        <Form>
                          <FloatingLabel
                            controlId="floatingTextarea"
                            label="Remark"
                            className="mb-3"
                          >
                            <Form.Control
                              as="textarea"
                              name="RemarkText"
                              value={RemarkText}
                              onChange={(e) => setRemarkText(e.target.value)}
                              placeholder="Leave a comment here"
                            />
                          </FloatingLabel>
                        </Form>
                      </Col>
                    </Row>
                    {/* REFUND PARAMTERS */}
                    <Row>
                      <Col sm={6}>PaymentId</Col>
                      <Col sm={6}>Data</Col>
                      <Col sm={6}>Speed</Col>
                      <Col sm={6}>Normal/Optimum</Col>
                      <Col sm={6}>Notes</Col>
                      <Col sm={6}>Note1</Col>
                    </Row>
                  </Container>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      disabled={RemarkText?.length < 5}
                      onClick={submitAmendment}
                    >
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal show={sectoreShow} onHide={handleSectoreClose}>
                  <Modal.Header>Select Trip</Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Col>
                        <div className="d-flex">
                          <h6>
                            {sectoreInfo[0]?.da?.code} -{" "}
                            {sectoreInfo[sectoreInfo?.length - 1]?.aa?.code}
                          </h6>
                          <div className="mx-3 mb-2">
                            <Form.Check // prettier-ignore
                              type={"checkbox"}
                              onChange={() =>
                                handleSectoreDetails(sectoreInfo, "oneway")
                              }
                            />
                          </div>
                        </div>
                        {SingleData?.trip_info?.sector_details.length > 1 && (
                          <>
                            <div className="d-flex">
                              <h6>
                                {sectoreInfoReturn[0]?.da?.code} -{" "}
                                {
                                  sectoreInfoReturn[
                                    sectoreInfoReturn.length - 1
                                  ]?.aa?.code
                                }
                              </h6>
                              <div className="mx-3 mb-2">
                                <Form.Check // prettier-ignore
                                  type={"checkbox"}
                                  onChange={() =>
                                    handleSectoreDetails(
                                      sectoreInfoReturn,
                                      "return"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </Col>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSectoreClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleTripCancellation}>
                      Process To Cancellation
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* } */}
                <Modal show={passengerShow} onHide={handlePassengerClose}>
                  <Modal.Header>Select Passenger</Modal.Header>
                  <Modal.Body>
                    <Container>
                      {PassengerInfo?.map((items, k) => {
                        return (
                          <>
                            <Row className="my-2">
                              <Col>
                                <h6 className="d-flex">
                                  {items?.ti} {items?.fN} {items?.lN} (
                                  {items?.pt}){" "}
                                </h6>
                              </Col>
                              <Col sm={2}>
                                <Form.Check
                                  aria-label="option 1"
                                  onChange={() => handleData(items, k)}
                                  value={removePass}
                                />
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handlePassengerClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handlePassengerCancellation}
                    >
                      Process To Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Container>
            </div>
          )}
        </Card.Body>
      </Card>
    </Card>
  );
};

export default AmendmentComponent;
