import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Container, Row } from "react-bootstrap";
import MainTitle from "../../components/mainTitile/mainTitle";
import CustomButton from "../../components/button/button";
import { Link, useNavigate } from "react-router-dom";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { delete_faq_data, get_Faq_data } from "../../services/authServices";
import "./faq.css";
import CustomLoader from "../../components/loader/loader";
const FAQ = () => {
  const [fAQ, setFAQ] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate(`/faqForm/faqForm/`);
  };
  const getTerms = async () => {
    setLoading(false);
    try {
      // const params ={
      //     title:fAQ.title,
      //     description:fAQ.content
      // }
      const res = await get_Faq_data();
      setFAQ(res?.data?.Data.getData);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  const OnlyDateFormate = (data) => {
    const d = new Date(data);
    let Data = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
    return Data;
  };
  const handleDelete = (i) => {
    delete_faq_data(i._id);
    window.location.reload();
  };

  useEffect(() => {
    getTerms();
  }, []);
  return (
    <div>
      <Container className="my-4">
        <CustomLoader isLoading={isLoading} />
        <Row className="justify-content-end d-flex">
          <CustomButton buttonText={"Add"} onClick={handleClick} />
        </Row>
        {fAQ?.map((aItem, ind) => (
          <Row key={ind}>
            <Col>
              <Accordion className="m-2">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{aItem?.title} </Accordion.Header>
                  <Accordion.Body>
                    {/* <Row className="text-muted">
                      <Col lg={12} className="d-flex justify-content-end">
                        Updated Date :{OnlyDateFormate(aItem?.updatedAt)}
                      </Col>
                    </Row> */}
                    <p> {aItem?.content}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col sm={2} className="mt-3 d-flex">
              <Link to={`/faqForm/faqForm/${aItem?._id}`}>
                <Button className="mx-2" size="sm" variant="outline-primary">
                  <AiFillEdit className="mt-1" /> edit
                </Button>
              </Link>

              <Link>
                <Button
                  className="mx-2"
                  size="sm"
                  variant="outline-danger"
                  onClick={() => handleDelete(aItem)}
                >
                  <AiFillDelete className="mt-1" /> Delete
                </Button>
              </Link>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};
export default FAQ;
