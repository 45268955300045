import React from "react";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { Col, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { adminData, deleteUser } from "../../services/authServices";
import MainTitle from "../../components/mainTitile/mainTitle";
import TablePagination from "../../components/pagination/tablePagination";
import CustomButton from "../../components/button/button";
import CustomLoader from "../../components/loader/loader";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/commanFile";

const AdminListComponent = () => {
  const [adminD, setAdminD] = useState([]);
  const [isLoading, setLoading] = useState(false);

  //   const [pageCount, setPageCount] = useState(0);
  //   const [pageData, setPageData] = useState(10);
  // const [page, setPage] = useState(1);

  let navigate = useNavigate();
  //To take all the data from the backend

  const getadmindata = async () => {
    setLoading(true);
    try {
      const res = await adminData();
      const filteredData = res?.data?.Data?.getAllData?.filter(
        (item) => item.roleIdf === 1
      );
      setAdminD(filteredData);
      // setPageCount(res.data.Data.adminDocs);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  // useEffect(() => {
  //   getadmindata();
  // }, []);

  const formpage = () => {
    let path = "/adminForm";
    navigate(path);
  };

  const handleDelete = (userid) => {
    deleteUser(userid)
      .then((res) => {
        if (res.data.Status === 200) {
          getadmindata();
        }
      })
      .catch((err) => {
        console.log("deleted admin Error", err);
      });
  };

  useEffect(() => {
    getadmindata();
  }, []);
  // pagination logic...

  // useEffect(() => {
  //   getadmindata();
  // }, [page,pageData]);

  // useEffect(() => {
  // const pagedatacount = Math.ceil(pageCount / pageData);
  // setPageCount(pagedatacount);
  // }, [adminD])

  // const handleRowSize = (e) =>{
  // const { innerHTML } = e.target
  // setPageData(innerHTML)
  // console.log('row per page size',innerHTML);
  // }

  // admin data table here

  const columns = [
    {
      name: "Admin Id",
      selector: (row) => row?.tmt_userId,
    },

    {
      name: "First Name",
      selector: (row) => row?.firstname,
    },
    {
      name: "Last Name",
      selector: (row) => row?.email,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "password",
      selector: (row) => row?.password?.slice(0, 7) + "...",
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="d-flex">
          <div className="mx-3">
            <Link
              style={{ textDecoration: "none" }}
              to={`/adminForm/${row._id}`}
            >
              <BiEdit color="green" size={20} />
            </Link>
          </div>
          <div>
            <Link
              style={{ textDecoration: "none" }}
              onClick={() => handleDelete(row._id)}
            >
              <AiFillDelete color="red" size={20} />
            </Link>
          </div>
        </div>
      ),
    },
  ];
 
  return (
    <>
      <CustomLoader isLoading={isLoading} />
      <Row>
        <Col
        // style={{ overflow: "scroll" }}
        >
          <Row>
            <Col sm={10} className="pt-3">
              <MainTitle heading={"admin List"} textAlign={"center"} />
            </Col>

            <Col sm={2}>
              <CustomButton buttonText={"add"} onClick={formpage} />
            </Col>
          </Row>
          <DataTable
            columns={columns}
            dense
            customStyles={customStyles}
            pagination
            data={adminD}
          />
         
        </Col>
      </Row>
    </>
  );
};

export default AdminListComponent;
