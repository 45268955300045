import React, { useState, useEffect } from "react";
import {
  deleteHolidayPackages,
  getAllHolidayPackages,
} from "../../services/authServices";
import { Container, Row, Table } from "react-bootstrap";
import moment from "moment";
import MainTitle from "../../components/mainTitile/mainTitle";
import HolidaysDataModal from "../../components/holidaysData/holidaysDataModal";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import TablePagination from "../../components/pagination/tablePagination";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/commanFile";

const Holidays = () => {
  const [holidayData, setHolidayData] = useState([]);
  const [holidayModalShow, setHolidayModalShow] = useState(false);
  const [holidayId, setHolidayId] = useState();
  const [isLoading, setLoading] = useState(false);

  const getAllHolidayData = () => {
    setLoading(true);

    getAllHolidayPackages()
      .then((res) => {
        setLoading(false);
        setHolidayData(res.data.Data.getData);
        console.warn(
          "holiday data from api response",
          res.data.Data.totalCount
        );
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleEdit = (data) => {
    setHolidayId(data?.holidayId);
    setHolidayModalShow(true);
  };

  const deleteHolidataData = (id) => {
    deleteHolidayPackages(id)
      .then((res) => {
        getAllHolidayData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllHolidayData();
  }, [!holidayModalShow, HolidaysDataModal]);

  const columns = [
    {
      name: "HP Order Id",
      selector: (row) => row?.holidayId,
    },
    {
      name: "first name",
      selector: (row) => row?.firstname,
    },
    {
      name: "From",
      selector: (row) => row?.from,
    },
    {
      name: "To",
      selector: (row) => row?.to,
    },
    {
      name: "mobile",
      selector: (row) => row?.mobile,
    },
    {
      name: "start Date",
      selector: (row) => moment(row.fromDate).format("DD MMM  YYYY"),
    },
    {
      name: "End date",
      selector: (row) => moment(row.toDate).format("DD MMM YYYY"),
    },
    {
      name: "Nights",
      selector: (row) => row?.number_Nights,
    },
    {
      name: "status",
      selector: (row) => row?.status,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex">
          <div className="mx-2">
            <a style={{ cursor: "pointer" }} onClick={() => handleEdit(row)}>
              {" "}
              <FiEdit color="green" size={20} />{" "}
            </a>
          </div>
          <div>
            <AiFillDelete
              color="red"
              onClick={() => deleteHolidataData(row?.holidayId)}
              style={{ cursor: "pointer" }}
              size={20}
            />
          </div>
        </div>
      ),
    },
  ];
 
  return (
    <>
      <Container>
        <Row>
          <MainTitle heading={"Holiday pakages"} textAlign={"center"} />
        </Row>
        <Row>
          <DataTable
            dense
            customStyles={customStyles}
            progressPending={isLoading}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            columns={columns}
            data={holidayData}
            pagination
          />
        </Row>
      </Container>
      <HolidaysDataModal
        handleCloseModal={() => setHolidayModalShow(false)}
        show={holidayModalShow}
        holidayId={holidayId}
      />
    </>
  );
};

export default Holidays;
