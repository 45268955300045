import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {  get_sector_list_single } from "../../services/authServices";
import "./orderDetails.css";

const SectorDeatils = () => {
  const [list, setlist] = useState();
  const { id } = useParams();
  const validData = id.split("=");
  const getsectorData = async () => {
    const param = {
      sector_Id: validData[1],
    };
    try {
      const res = await get_sector_list_single(param);
      setlist(res.data.Data);
    } catch (e) {
      console.error(e);
    }
  };
  const OnlyDateFormate = (data) => {
    const d = new Date(data);
    let Data =
      d.getDate() + "-" + `${d.getMonth() + 1}` + "-" + d.getFullYear();
    return Data;
  };

  useEffect(() => {
    getsectorData();
  }, [id]);
  return (
    <div className="userdetails">
      <Card className="Card">
        <h4>
          <u>Sector Details:-</u>
        </h4>
        {list && (
          <>
            <Row>
              <Col lg={5} className="details">
                TMT Sector ID
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.sector_Id}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                TMT Order IDF
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.tmt_orderIdf}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Depart City
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.depart_city}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Arrival City
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.arrival_city}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Depart Date
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{OnlyDateFormate(list.depart_date)}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Arrival Date
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{OnlyDateFormate(list.arrival_date)}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Depart Time
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.depart_time}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Arrival Time
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.arrival_time}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Booking Class
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.booking_class}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Booking Type
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.booking_type}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Fare Type
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.fare_type}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Trip Type
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.trip_type}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Airline Name
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.airline_name}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Airline Code
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.airline_code}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Airline Model
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.airline_model}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Base Fare
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.base_fare}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                OT Tax
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.OT_tax}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                K3 GST
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.K3_gst}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                YQ Tax
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.YQ_tax}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Baggages
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.baggages}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Meal
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.meal}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Baggages
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.seat}</Col>
            </Row>
            <Row>
              <Col lg={5} className="details">
                Management Fees
              </Col>
              <Col lg={1}>:</Col>
              <Col lg={6}>{list.management_fee}</Col>
            </Row>
          </>
        )}

        {!list && <div>something went wrong</div>}
      </Card>
    </div>
  );
};

export default SectorDeatils;
