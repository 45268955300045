export const removeTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
};

export const saveItem = (key, value) => {
    localStorage.setItem(key, value);
}

    export const getItem = (key) => {
        return localStorage.getItem(key);
    }

export const removeItem = (key) => {
    return localStorage.removeItem(key);
}

export const clearAllItems = () => {
    localStorage.clear();
}

export const getAccessToken = () => getItem('accessToken');
export const getRefreshToken = () => getItem('refreshToken');
export const setAccessToken = (adminToken) => saveItem('accessToken', adminToken);
export const setRefreshToken = (adminToken) => saveItem('refreshToken', adminToken);