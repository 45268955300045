import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import MainTitle from '../mainTitile/mainTitle';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { airportSearch, editDestination, popular_destination_post, popular_destination_single_data } from '../../services/authServices';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

const PopularFlightForm = ({showModal, handleClose,isDomestic,data}) => {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const filterBy = () => true;
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [popularDest, setPopularDest] = useState({
        from:"",
        fromCode:"",
        to:"",
        toCode:"",
      });
      const navigate = useNavigate();
      console.log('data from component',data);

    const handleCityWithCode = (operation,e) => {
        console.log('auto search data',e);
        if(e.length > 0){
          if(operation === "from"){
            setPopularDest({...popularDest, from : e[0].city,fromCode:e[0].code})
          }else{
            
            setPopularDest({...popularDest, to : e[0].city,toCode:e[0].code})
          }
        }
      };
    
  // airport auto seach api
  const airpotAutoSearch = (query) => {
    console.log('asdasdasdasdasda',query);
    setIsLoading(true);
    const params = {
      query: query,
    };
    airportSearch(params).then((res) => {
      setOptions(res?.data.Data);
      setIsLoading(false);
    }).catch((err)=>{  
      setIsLoading(false);
    })
  };


  const preload = debounce((query) => {
    airpotAutoSearch(query);
  }, 500);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let params = {
      from:popularDest.from,
      fromCode:popularDest.fromCode,
      to:popularDest.to,
      toCode:popularDest.toCode,
      isDomestic: isDomestic === "Doemstic" ? true : false
    }
    if (data._id === undefined || data.id === null) {
     
      try {
        const res = await popular_destination_post(params);
        // setPopularDest(res?.data?.Data);
        handleClose()
      } catch (e) {
        console.error(e);
      }
      console.log('popular data from function',params);
      
    } else {
      try {
        const res = await editDestination(data._id, params);
        setPopularDest(res?.data?.Data);
        handleClose()
      } catch (e) {
        console.error(e);
      }
      console.log('popular data from function 222',params);
    }
  };
  
  //   single data api 
  const popularDataAPI = async () => {
      console.log('popular data from function 333',data?._id);
      try {
        const res = await popular_destination_single_data(data?._id);
        setPopularDest(res.data.Data);
      } catch (e) {
        console.error(e);
      }
  };
  useEffect(() => {
    if (data?._id !== undefined || data?._id !== null) {
      console.log('single api data',data);
      popularDataAPI();
    }
  }, [data]);
  useEffect(() => {
    airpotAutoSearch();
  }, []);
  return (
    <div>
   <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <MainTitle heading={'Popular Flights'} marginBottom={'0px'} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='my-1' controlId="fromField">
            <Form.Label>From</Form.Label>
            <AsyncTypeahead
            filterBy={filterBy}
              size="2"
              type={"text"}
              minLength={3}
              maxHeight='150px'
              multiple={false}
              options={options}
              onSearch={preload}
              isLoading={isLoading}
              id="fromCityOrAirport"
              placeholder="From"
              defaultInputValue={`${data?.from ? data?.from : ''}`}
              name="from"
              // selected={flightSource}
              // className={styles.form_control}
              onChange={(e) => handleCityWithCode('from',e)}
              // inputProps={{ className: styles.form_control }}
              labelKey={(option) =>
                ` ${option.city} - ${option.code}, `
              }
              renderMenuItemChildren={(option) => (
                <div >
                 <label>{`${option.city} (${option.code})`}</label> 
                </div>
              )}
            />
       
          </Form.Group>

          <Form.Group className='mt-2' controlId="toField">
            <Form.Label>To</Form.Label>
            <AsyncTypeahead
            filterBy={filterBy}
              size="2"
              type={"text"}
              minLength={3}
              maxHeight='85px'
              multiple={false}
              options={options}
              onSearch={preload}
              isLoading={isLoading}
              id="To"
              placeholder="To"
              name="to"
              
              defaultInputValue={`${data?.to ? data?.to : ''}`}
              // selected={flightSource}
              // className={styles.form_control}
              onChange={(e) => handleCityWithCode('To',e)}
              // inputProps={{ className: styles.form_control }}
              labelKey={(option) =>
                ` ${option.city} - ${option.code}, `
              }
              renderMenuItemChildren={(option) => (
                <div >
                 <label>{`${option.city} (${option.code})`}</label> 
                </div>
              )}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} >
          {data === undefined || data === null ? 'Submit' : 'Update'}
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  )
}

export default PopularFlightForm
