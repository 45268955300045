import React from 'react';
import './mainTitle.css';

const MainTitle = ({ heading, textAlign,marginBottom }) => {
  return (
    <>
      <h1 className="mainTitle" style={{ textAlign: textAlign,marginBottom:marginBottom }}>
        {heading}
      </h1>
    </>
  );
}

export default MainTitle;
