import icon from '../../assets/tmtIcon.png';

const TMTicon = ({ onclick }) => {
  return (
    <>
      <img
        src={icon}
        height={"30px"}
        width={"40px"}
        alt="TMT icon"
        onClick={onclick}
        style={{ transition: "all 0.5" }}
      />
    </>
  );
}

export default TMTicon;